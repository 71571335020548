import { defaultTableHeaderClasses } from "@/constants"

export const USERS_WITH_ISSUE_ACCESS = {
  headers: [{
    text : "669",
    align: "left",
    value: "name",
    width: 400,
    class: defaultTableHeaderClasses
  }, {
    text : "670",
    align: "left",
    value: "roles",
    class: defaultTableHeaderClasses
  }, {
    align   : "left",
    value   : "edit",
    class   : defaultTableHeaderClasses,
    sortable: false
  }]
}
/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import {
  HTTP_STATUS_CODE,
  FILE_NAME,
  DATA_EXPORT_TYPE,
  ISSUES_DATA_EXPORT_POLL_INTERVAL,
  DATA_EXPORT_STATUS
} from "@/constants"
import { downloadFile } from "@/utils"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/exports`

export default {

  /**
   * This action will load exports.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load exports.
   */
  async loadExports(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getExportsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getExportsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setExports", getExportsResponse.data)
    } else {
      // something went wrong while getting Exports
    }
  },

  /**
   * This action will load a particular export.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the export to be loaded
   */
  async loadExport(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getExportResponse = await httpClient.get(`/${payload}`)

    if (getExportResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateExports", [getExportResponse.data])
    } else {
      // something went wrong while getting an export
    }
  },

  /**
   * This action will create an export.
   * @param {*} context is the store
   * @param {*} payload contains exports details
   */
  async addExport(context, payload) {
    context.commit("setAddingExport", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postExportResponse = await httpClient.post(undefined, payload)

    if (postExportResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateExports", [postExportResponse.data])
      if ([
        DATA_EXPORT_TYPE.ISSUES_WITH_ALL_FIELDS,
        DATA_EXPORT_TYPE.ISSUES_WITH_SELECTED_FIELDS
      ].includes(postExportResponse.data.type)) {
        pollingForIssuesExport(context, postExportResponse)
      }
    } else if(postExportResponse.status === HTTP_STATUS_CODE.BAD_REQUEST){
      await context.dispatch("loadExports")
    } else {
      // something went wrong while creating an export
    }
    context.commit("setAddingExport", false)
  },

  /**
   * This action downloads the latest data export
   * @param {*} context is the store.
   */
  async downloadDataExport(context, payload) {
    const httpClient             = GET_HTTP_CLIENT({
      baseURL
    }, context)
    const getDownloadURLResponse = await httpClient.get(`/${payload.id}/download-url`)
    if (getDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      let fileName
      switch(payload.type) {
        case DATA_EXPORT_TYPE.ALL: {
          fileName = FILE_NAME.ALL_DATA_EXPORT_ZIP
          break
        }
        case DATA_EXPORT_TYPE.ANALYTICS: {
          fileName = FILE_NAME.GET_ANALYTICS_EXPORT(payload.createdAt.slice(0, 10))
          break
        }
        case DATA_EXPORT_TYPE.ISSUES_WITH_ALL_FIELDS:
        case DATA_EXPORT_TYPE.ISSUES_WITH_SELECTED_FIELDS: {
          fileName = FILE_NAME.ISSUES_EXPORT
          break
        }
      }
      await downloadFile(getDownloadURLResponse.data, payload.md5, fileName)
    }
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setExports", [])
    context.commit("setAddingExport", false)
  }
}

const pollingForIssuesExport = (context, postExportResponse) => {
  const exportStatusPolling = setInterval(async () => {
    const isLoggedIn = context.rootGetters["auth/isLoggedIn"]
    if (isLoggedIn) {
      await context.dispatch("loadExport", postExportResponse.data.id)
      const latestIssuesExport = context.getters.latestIssuesExport
      if (latestIssuesExport.status === DATA_EXPORT_STATUS.COMPLETED) {
        context.dispatch("downloadDataExport", latestIssuesExport)
        clearInterval(exportStatusPolling)
      }
    } else {
      clearInterval(exportStatusPolling)
    }
  },
  ISSUES_DATA_EXPORT_POLL_INTERVAL)
}
export default {
  isLoadingFormTemplates     : state => state.loadingFormTemplates,
  formTemplates              : state => state.formTemplates,
  isAddingFormTemplate       : state => state.addingFormTemplate,
  isFormTemplateAdded        : state => state.formTemplateAdded,
  formTemplateAddError       : state => state.formTemplateAddError,
  isUpdatingFormTemplateName : state => state.updatingFormTemplate.name,
  isFormTemplateNameUpdated  : state => state.formTemplateUpdated.name,
  formTemplateNameUpdateError: state => state.formTemplateUpdateError.name,
  issueFormTemplates         : state => state.formTemplates.filter(formTemplate => !formTemplate.reportForm)
}
import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { DATA_EXPORT_TYPE } from "@/constants"

/**
* This method will be called before navigating to Analytics page.
*/
export const beforeAnalytics = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ANALYTICS))
  store.commit("shared/setBreadcrumbs", undefined)
  await store.dispatch("exports/loadExports", { type: DATA_EXPORT_TYPE.ANALYTICS })
}
export default {
  setLoadingIssueLinks: (state, loadingIssueLinks) => {
    state.loadingIssueLinks = loadingIssueLinks
  },
  setIssueLinks: (state, issueLinks) => {
    state.issueLinks = issueLinks
  },
  setAddingIssueLinks: (state, addingIssueLinks) => {
    state.addingIssueLinks = addingIssueLinks
  },
  setIssueLinksAdded: (state, issueLinksAdded) => {
    state.issueLinksAdded = issueLinksAdded
  },
  updateIssueLinks: (state, issueLinks) => {
    for (const issueLink of issueLinks) {
      const index = state.issueLinks.findIndex(stateIssueLink => stateIssueLink?.id === issueLink.id)
      if (index >= 0) {
        state.issueLinks.splice(index, 1, { ...state.issueLinks[index], ...issueLink })
      } else {
        state.issueLinks.push(issueLink)
      }
    }
  },
  setRemovingIssueLink: (state, removingIssueLink) => {
    state.removingIssueLink = removingIssueLink
  },
  setIssueLinkRemoved: (state, issueLinkRemoved) => {
    state.issueLinkRemoved = issueLinkRemoved
    if (issueLinkRemoved) {
      setTimeout(() => {
        state.issueLinkRemoved = false
      }, 5000)
    }
  },
  removeIssueLink: (state, issueLinkToBeRemoved) => {
    const index = state.issueLinks.findIndex(issueLink => issueLink.id === issueLinkToBeRemoved.id)
    state.issueLinks.splice(index, 1)
  }
}
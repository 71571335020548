import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"

export const AUTOMATIONS_LEADING_TO_DOMAIN = {
  headers: [{
    text : "1106",
    align: "left",
    value: "domainName",
    class: defaultTableHeaderClasses
  },
  {
    text : "1107",
    align: "left",
    value: "channel",
    class: defaultTableHeaderClasses
  },
  {
    text : "1108",
    align: "left",
    value: "formNames",
    class: defaultTableHeaderClasses
  },
  {
    text : "1109",
    align: "left",
    value: "fieldSystemNames",
    class: defaultTableHeaderClasses
  }],
  itemsPerPage: 10,
  footer      : defaultTableFooterOptions
}
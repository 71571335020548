/**
 * @file It contains vuex issueResolutions module mutations to mutate the state variables.
 */
export default {
  setLoadingIssueResolutions: (state, loadingIssueResolutions) => {
    state.loadingIssueResolutions = loadingIssueResolutions
  },
  setIssueResolutions: (state, issueResolutions) => {
    state.issueResolutions = issueResolutions
  }
}
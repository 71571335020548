/**
 * @file It contains vuex languages module mutations to mutate the state variables.
 */
export default {
  setLoadingLanguages: (state, loadingLanguages) => {
    state.loadingLanguages = loadingLanguages
  },
  setLanguages: (state, languages) => {
    state.languages = languages
  }
}
import store from "@/plugins/vuex"

/**
 * This method returns true if the user has access to the module/route .
 * @param {*} roleTypes list of role types to which the route or module has access.
 */
export const hasAccessToRoute = typesOfRoleWhichHaveAccess => {
  const loggedInUserRoleTypes = store.getters["auth/loggedInUserRoleTypes"]
  return !!typesOfRoleWhichHaveAccess.find(typeOfRoleWhichHasAccess =>
    !!loggedInUserRoleTypes.find(loggedInUserRoleType =>
      typeOfRoleWhichHasAccess === loggedInUserRoleType
    )
  )
}
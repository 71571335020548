import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to channels add page
 */
export const beforeChannelAdd = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CHANNEL_ADD))
  store.commit("shared/setBreadcrumbs", undefined)
  store.commit("channels/setChannelAdded", undefined)
  store.commit("channels/setAddingChannel", undefined)
  store.commit("channels/setChannelAddError", undefined)
  store.dispatch("domains/loadDomains")
  store.dispatch("organisationCodes/loadAvailableOrganisationCodes")
  store.dispatch("configurations/loadConfigurations")
  store.dispatch("translationPreferences/loadTranslationPreferences")
}
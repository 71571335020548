/**
 * @file It contains vuex labels module mutations to mutate the state variables.
 */
export default {
  setLoadingLabels: (state, loadingLabels) => {
    state.loadingLabels = loadingLabels
  },
  setLabels: (state, labels) => {
    state.labels = labels
  }
}
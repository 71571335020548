export default {
  loadingFormTemplateConfigurations: false,
  formTemplateConfigurations       : new Array(),
  addingFormTemplateConfigurations : false,
  formTemplateConfigurationsAdded  : false,
  updatingFormTemplateConfiguration: {
    mandatory   : false,
    sortingOrder: false
  },
  formTemplateConfigurationUpdated: {
    mandatory   : false,
    sortingOrder: false
  },
  removingFormTemplateConfiguration: false,
  formTemplateConfigurationRemoved : false
}
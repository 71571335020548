import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { ISSUE_ADD } from "@/constants/bread-crumbs/issue-add"

/**
 * This method will be called before navigating to issue add page.
 */
export const beforeIssueAdd = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUE_ADD))
  store.commit("shared/setBreadcrumbs", ISSUE_ADD)
  const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]
  if(!loggedInUserPolicies["Issue add"]) {
    return "dashboard"
  }
  store.commit("issues/setAddingIssue", undefined)
  store.commit("issues/setIssueAdded", undefined)
  store.dispatch("domains/loadDomains")
  store.dispatch("accesses/loadAccesses")
}
/**
 * @file It contains all the vuex domains module state variables.
 */
export default {
  loadingDomains: undefined,
  domains       : new Array(),
  addingDomain  : undefined,
  domainAdded   : undefined,
  domainAddError: undefined,
  updatingDomain: {
    name    : false,
    archived: false
  },
  domainUpdated: {
    name    : false,
    archived: false
  },
  domainUpdateError: {
    name    : false,
    archived: false
  }
}
/**
 * @file It contains vuex policies module mutations to mutate the state variables.
 */
export default {
  setLoadingPolicies: (state, loadingPolicies) => {
    state.loadingPolicies = loadingPolicies
  },
  setPolicies: (state, policies) => {
    state.policies = policies
  }
}
/**
 * @file It contains actions that needs to displayed under user actions.
 */
export default [
  {
    title : "688",
    action: process.env.VUE_APP_HELP_CENTER_URL
  },
  {
    title : "456",
    action: process.env.VUE_APP_SUPPORT_URL
  }
]
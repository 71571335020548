/**
 * @file It contains vuex reports module mutations to mutate the state variables.
 */
export default {
  setLoadingReports: (state, reports) => {
    state.loadingReports = reports
  },
  setReports: (state, reports) => {
    state.reports = reports
  },
  updateReports: (state, reports) => {
    for (const report of reports) {
      const index = state.reports.findIndex(stateReport => stateReport?.id === report.id)
      if (index >= 0) {
        state.reports.splice(index, 1, { ...state.reports[index], ...report })
      } else {
        state.reports.push(report)
      }
    }
  },
  setUpdatingReport: (state, properties) => {
    for (const property of properties) {
      state.updatingReport[property] = true
    }
  },
  resetUpdatingReport: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingReport[property] = false
      }
    } else {
      Object.keys(state.updatingReport).forEach(property => state.updatingReport[property] = false)
    }
  },
  setReportUpdated: (state, properties) => {
    for (const property of properties) {
      state.reportUpdated[property] = true
      setTimeout(() => {
        state.reportUpdated[property] = false
      }, 5000)
    }
  },
  resetReportUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.reportUpdated[property] = false
      }
    } else {
      Object.keys(state.reportUpdated).forEach(property => state.reportUpdated[property] = false)
    }
  },
  setLoadingReportFormValues: (state, reportFormValues) => {
    state.loadingReportFormValues = reportFormValues
  },
  setReportFormValues: (state, reportFormValues) => {
    state.reportFormValues = reportFormValues
  },
  updateReportFormValues: (state, reportFormValues) => {
    for (const reportFormValue of reportFormValues) {
      const index = state.reportFormValues.findIndex(stateReportFormValue =>
        stateReportFormValue?.id === reportFormValue.id
      )
      if (index >= 0) {
        state.reportFormValues.splice(index, 1, { ...state.reportFormValues[index], ...reportFormValue })
      } else {
        state.reportFormValues.push(reportFormValue)
      }
    }
  },
  setAccessInformation: (state, { policy, policyAccessInformation }) => {
    const policyAccessInformationResourceMap = new Object()
    for (const resource of policyAccessInformation) {
      policyAccessInformationResourceMap[resource.id] = resource
    }

    if (state.accessInformation[policy]) {
      state.accessInformation[policy] = Object.assign(
        {},
        state.accessInformation[policy],
        policyAccessInformationResourceMap
      )
    } else {
      state.accessInformation = Object.assign(
        {},
        state.accessInformation, {
          [policy]: policyAccessInformationResourceMap
        }
      )
    }
  },
  resetAccessInformation: state => {
    state.accessInformation = new Object()
  }
}
/**
 * @file It contains vuex integrations module getter methods to access store variables.
 */
export default {
  isAddingIntegration  : state => state.addingIntegration,
  isIntegrationAdded   : state => state.integrationAdded,
  integrationAddError  : state => state.integrationAddError,
  integrations         : state => state.integrations,
  isRemovingIntegration: state => state.removingIntegration,
  isIntegrationRemoved : state => state.integrationRemoved
}
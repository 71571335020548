/**
 * @file It contains all the vuex users module state variables.
 */
export default {
  loadingUsers : undefined,
  users        : new Array(),
  groupsOfUsers: new Object(),
  addingUser   : undefined,
  userAdded    : undefined,
  userAddError : undefined,
  addingGroups : undefined,
  updatingUser : {
    name                   : false,
    email                  : false,
    jobTitle               : false,
    phone                  : false,
    enabled                : false,
    disableMFA             : false,
    issuesColumnsPreference: false
  },
  userUpdated: {
    name                   : false,
    email                  : false,
    jobTitle               : false,
    phone                  : false,
    enabled                : false,
    disableMFA             : false,
    issuesColumnsPreference: false
  },
  userUpdateError: {
    name                   : undefined,
    email                  : undefined,
    jobTitle               : undefined,
    phone                  : undefined,
    enabled                : undefined,
    disableMFA             : false,
    issuesColumnsPreference: false
  },
  sendingPassword: undefined,
  passwordSent   : undefined,
  deletingUser   : undefined,
  userDeleted    : undefined,
  updatingGroups : undefined,
  groupsUpdated  : undefined
}

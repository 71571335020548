import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { PAGES } from "@/constants"

/**
 * This method will be called before loading the export page.
 */
export const beforeExport = async (to, from) => {
  if (from.name !== "export" || !(PAGES.EXPORTS.includes(to.hash) || PAGES.EXPORTS.includes(from.hash))){
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.EXPORT))
    store.commit("shared/setBreadcrumbs", undefined)
    store.commit("integrations/setAddingIntegration", false)
    store.commit("integrations/setIntegrationAddError", undefined)
    store.commit("integrations/setIntegrationAdded", false)
    store.commit("integrations/setRemovingIntegration", false)
    store.commit("integrations/setIntegrationRemoved", false)

    const loadIntegrationsPromise = store.dispatch("integrations/loadIntegrations")
    const loadExportsPromise      = store.dispatch("exports/loadExports")
    store.dispatch("users/loadUsers")
    await loadIntegrationsPromise
    await loadExportsPromise
  }
}
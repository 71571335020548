/**
 * @file It contains all the vuex token module state variables.
 */
export default {
  clientName               : undefined,
  clientConfigurationId    : undefined,
  clientUserPoolId         : undefined,
  clientUserPoolClientId   : undefined,
  loggedIn                 : false,
  loggedInUser             : undefined,
  passwordResetRequested   : undefined,
  requestingPasswordReset  : undefined,
  passwordReset            : undefined,
  invalidCredentials       : undefined,
  loggingIn                : false,
  resettingPassword        : undefined,
  newPasswordRequired      : undefined,
  secretCode               : undefined,
  tokenVerified            : undefined,
  verifyingToken           : undefined,
  mfaEnabledForLoggedInUser: undefined,
  assignedUserMfaPreference: undefined,
  mfaEnabled               : undefined,
  totpRequired             : undefined,
  exceededAttempts         : undefined,
  changingPassword         : false,
  passwordChanged          : false,
  passwordExpired          : false,
  invalidTotp              : undefined,
  loggedInUserRoleTypes    : new Array(),
  verificationCode         : undefined,
  loggedInUserDisabled     : false
}

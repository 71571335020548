export default {
  setLoadingFormTemplateConfigurations: (state, loadingFormTemplateConfigurations) => {
    state.loadingFormTemplateConfigurations = loadingFormTemplateConfigurations
  },
  setFormTemplateConfigurations: (state, formTemplateConfigurations) => {
    state.formTemplateConfigurations = formTemplateConfigurations
  },
  setAddingFormTemplateConfigurations: (state, addingFormTemplateConfigurations) => {
    state.addingFormTemplateConfigurations = addingFormTemplateConfigurations
  },
  setFormTemplateConfigurationsAdded: (state, formTemplateConfigurationsAdded) => {
    state.formTemplateConfigurationsAdded = formTemplateConfigurationsAdded
  },
  updateFormTemplateConfigurations: (state, formTemplateConfigurations) => {
    for (const formTemplateConfiguration of formTemplateConfigurations) {
      const indexOfFormTemplateConfiguration = state.formTemplateConfigurations.findIndex(configuration =>
        configuration.id === formTemplateConfiguration.id
      )
      const formTemplateConfigurationInStore = state.formTemplateConfigurations[indexOfFormTemplateConfiguration]

      const formTemplateConfigurationsToBeUpdated = [{
        index: indexOfFormTemplateConfiguration,
        formTemplateConfiguration
      }]

      if (formTemplateConfigurationInStore && formTemplateConfiguration.sortingOrder) {
        for (const [index, configuration] of state.formTemplateConfigurations.entries()) {
          if (configuration.id !== formTemplateConfiguration.id &&
            configuration.formTemplateId === formTemplateConfigurationInStore.formTemplateId
          ) {
            if (configuration.sortingOrder < formTemplateConfigurationInStore.sortingOrder) {
              if (configuration.sortingOrder >= formTemplateConfiguration.sortingOrder) {
                formTemplateConfigurationsToBeUpdated.push({
                  index,
                  formTemplateConfiguration: {
                    sortingOrder: configuration.sortingOrder + 1
                  }
                })
              }
            } else if (configuration.sortingOrder > formTemplateConfigurationInStore.sortingOrder) {
              if (configuration.sortingOrder <= formTemplateConfiguration.sortingOrder) {
                formTemplateConfigurationsToBeUpdated.push({
                  index,
                  formTemplateConfiguration: {
                    sortingOrder: configuration.sortingOrder - 1
                  }
                })
              }
            }
          }
        }
      }

      for (const formTemplateConfigurationToBeUpdated of formTemplateConfigurationsToBeUpdated) {
        if (formTemplateConfigurationToBeUpdated.index >= 0) {
          state.formTemplateConfigurations.splice(formTemplateConfigurationToBeUpdated.index, 1, {
            ...state.formTemplateConfigurations[formTemplateConfigurationToBeUpdated.index],
            ...formTemplateConfigurationToBeUpdated.formTemplateConfiguration
          })
        } else {
          state.formTemplateConfigurations.push(formTemplateConfigurationToBeUpdated.formTemplateConfiguration)
        }
      }
    }
  },
  setUpdatingFormTemplateConfiguration: (state, properties) => {
    for (const property of properties) {
      state.updatingFormTemplateConfiguration[property] = true
    }
  },
  resetFormTemplateConfigurationUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.formTemplateConfigurationUpdated[property] = false
      }
    } else {
      Object.keys(state.formTemplateConfigurationUpdated).forEach(
        property => state.formTemplateConfigurationUpdated[property] = false
      )
    }
  },
  setFormTemplateConfigurationUpdated: (state, properties) => {
    for (const property of properties) {
      state.formTemplateConfigurationUpdated[property] = true
      setTimeout(() => {
        state.formTemplateConfigurationUpdated[property] = false
      }, 5000)
    }
  },
  resetUpdatingFormTemplateConfiguration: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingFormTemplateConfiguration[property] = false
      }
    } else {
      Object.keys(state.updatingFormTemplateConfiguration).forEach(
        property => state.updatingFormTemplateConfiguration[property] = false
      )
    }
  },
  setRemovingFormTemplateConfiguration: (state, removingFormTemplateConfiguration) => {
    state.removingFormTemplateConfiguration = removingFormTemplateConfiguration
  },
  setFormTemplateConfigurationRemoved: (state, formTemplateConfigurationRemoved) => {
    state.formTemplateConfigurationRemoved = formTemplateConfigurationRemoved
  },
  formTemplateConfigurationRemove: (state, formTemplateConfigurationToBeRemovedId) => {
    const index = state.formTemplateConfigurations.
      findIndex(formTemplateConfiguration =>formTemplateConfiguration.id === formTemplateConfigurationToBeRemovedId
      )
    state.formTemplateConfigurations.splice(index, 1)
  }
}
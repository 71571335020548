/**
 * @file It contains all the vuex token module state variables.
 */
export default {
  fieldsV2       : new Array(),
  loadingFieldsV2: undefined,
  addingFieldV2  : undefined,
  fieldV2Added   : undefined,
  fieldV2AddError: undefined,
  updatingField  : {
    label     : false,
    systemName: false
  },
  fieldUpdated: {
    label     : false,
    systemName: false
  },
  fieldUpdateError: {
    systemName: undefined
  },
  downloadingFieldTranslation: false,
  uploadingTranslationFile   : false,
  translationFileUploaded    : false
}
/**
 * @file It contains all the vuex messages module state variables.
 */
export default {
  loadingMessages   : undefined,
  messages          : new Array(),
  addingMessage     : false,
  messageAdded      : false,
  removingMessage   : false,
  messageRemoved    : false,
  messageAddError   : undefined,
  messageUpdateError: {
    status: false
  },
  updatingMessage: {
    status: false
  },
  messageUpdated: {
    status: false
  }
}
import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"

export const AUTOMATION_RULES = {
  headers: [{
    text : "949",
    align: "left",
    value: "fieldValues",
    width: "70%",
    class: defaultTableHeaderClasses
  },
  {
    text : "950",
    align: "left",
    value: "domain",
    class: defaultTableHeaderClasses
  }],
  itemsPerPage: 10,
  footer      : defaultTableFooterOptions
}
/**
 * @file It contains vuex channels module mutations to mutate the state variables.
 */
export default {
  setLoadingOrganisationCodes: (state, loadingOrganisationCodes) => {
    state.loadingOrganisationCodes = loadingOrganisationCodes
  },
  updateOrganisationCodes: (state, organisationCodes) => {
    for (const organisationCode of organisationCodes) {
      const index = state.organisationCodes.findIndex(stateOrganisationCode =>
        stateOrganisationCode?.id === organisationCode.id
      )
      if (index >= 0) {
        state.organisationCodes.splice(index, 1, organisationCode)
      } else {
        state.organisationCodes.push(organisationCode)
      }
    }
  },
  setOrganisationCodes: (state, organisationCodes) => {
    state.organisationCodes = organisationCodes
  },
  setAvailableOrganisationCodes: (state, availableOrganisationCodes) => {
    state.availableOrganisationCodes = availableOrganisationCodes
  },
  setLoadingAvailableOrganisationCodes: (state, loadingAvailableOrganisationCodes) => {
    state.loadingAvailableOrganisationCodes = loadingAvailableOrganisationCodes
  }
}
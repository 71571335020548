/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/integrations`

export default {

  /**
   * This action will load integrations.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load integrations.
   */
  async loadIntegrations(context, payload) {

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getIntegrationsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIntegrationsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setIntegrations", getIntegrationsResponse.data)
    } else {
      // something went wrong while getting integrations
    }

  },

  /**
   * This action will addIntegration.
   * @param {*} context is the store
   * @param {*} payload contains integrations details
   */
  async addIntegration(context, payload) {
    context.commit("setAddingIntegration", true)
    context.commit("setIntegrationAddError", undefined)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postIntegrationResponse = await httpClient.post(undefined, payload)

    if (postIntegrationResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateIntegration", [postIntegrationResponse.data])
      context.commit("setIntegrationAdded", true)
    } else if(postIntegrationResponse.status === HTTP_STATUS_CODE.FAILED_DEPENDENCY){
      context.commit("setIntegrationAddError", postIntegrationResponse.data)
    } else {
      // something went wrong while adding an integration
    }

    context.commit("setAddingIntegration", false)
  },

  /**
   * This action will remove an Integration.
   * @param {*} context is the store
   * @param {*} payload contains integrations details
   */
  async removeIntegration(context, payload) {
    context.commit("setRemovingIntegration", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const deleteIntegrationResponse = await httpClient.delete(`/${payload.id}`)

    if (deleteIntegrationResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeIntegration", { id: payload.id } )
      context.commit("setIntegrationRemoved", true)
    } else {
      // something went wrong while removing an integration
    }

    context.commit("setRemovingIntegration", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setIntegrations", new Array())
    context.commit("setAddingIntegration", false)
    context.commit("setIntegrationAdded", false)
    context.commit("setIntegrationAddError", undefined)
    context.commit("setRemovingIntegration", false)
    context.commit("setIntegrationRemoved", false)
  }
}
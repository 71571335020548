import { defaultTableHeaderClasses } from "@/constants"

export const CHANNEL_FORM_TEMPLATES = {
  headers: [
    {
      text : "827",
      value: "name",
      align: "left",
      class: defaultTableHeaderClasses
    },
    {
      text    : "828",
      value   : "fields",
      align   : "left",
      width   : "50%",
      class   : defaultTableHeaderClasses,
      sortable: false
    }
  ]
}
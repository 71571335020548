import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"
import { compareDates, compareDatesWithoutTime }  from "@/utils"

export const ISSUES = {
  headers: [{
    value   : "icon",
    align   : "left",
    class   : defaultTableHeaderClasses,
    width   : 40,
    sortable: false
  },
  {
    text     : "234",
    align    : "left",
    value    : "id",
    width    : 75,
    cellClass: "text-truncate max-width-75",
    class    : defaultTableHeaderClasses
  },
  {
    text     : "290",
    align    : "left",
    value    : "summary",
    width    : 250,
    cellClass: "text-truncate max-width-250",
    class    : defaultTableHeaderClasses
  },
  {
    text     : "236",
    align    : "left",
    value    : "domain",
    width    : 150,
    cellClass: "text-truncate max-width-150",
    class    : defaultTableHeaderClasses
  },
  {
    text     : "238",
    align    : "left",
    value    : "status",
    width    : 150,
    cellClass: "text-truncate max-width-150",
    class    : defaultTableHeaderClasses
  },
  {
    text     : "239",
    align    : "left",
    value    : "assignee",
    width    : 150,
    cellClass: "text-truncate max-width-150",
    class    : defaultTableHeaderClasses
  },
  {
    text     : "591",
    align    : "left",
    value    : "labels",
    sortable : false,
    width    : 200,
    cellClass: "text-truncate max-width-200",
    class    : defaultTableHeaderClasses
  },
  {
    text     : "235",
    align    : "left",
    value    : "lastUpdated",
    width    : 175,
    cellClass: "text-truncate max-width-175",
    sort     : compareDates,
    class    : defaultTableHeaderClasses
  },
  {
    text     : "1269",
    align    : "left",
    value    : "parentId",
    width    : 125,
    cellClass: "text-truncate max-width-125",
    sortable : true,
    class    : defaultTableHeaderClasses
  },
  {
    text     : "1240",
    align    : "left",
    value    : "resolution",
    width    : 150,
    cellClass: "text-truncate max-width-150",
    class    : defaultTableHeaderClasses
  },
  {
    text     : "1241",
    align    : "left",
    value    : "receivedOn",
    width    : 175,
    cellClass: "text-truncate max-width-175",
    sort     : compareDates,
    class    : defaultTableHeaderClasses
  },
  {
    text     : "1242",
    align    : "left",
    value    : "acknowledgedOn",
    width    : 175,
    cellClass: "text-truncate max-width-175",
    sort     : compareDates,
    class    : defaultTableHeaderClasses
  },
  {
    text     : "1243",
    align    : "left",
    value    : "createdOn",
    width    : 175,
    cellClass: "text-truncate max-width-175",
    sort     : compareDates,
    class    : defaultTableHeaderClasses
  },
  {
    text     : "659",
    align    : "left",
    value    : "dataRetainedUntil",
    width    : 175,
    cellClass: "text-truncate max-width-175",
    sort     : compareDatesWithoutTime,
    class    : defaultTableHeaderClasses
  },
  {
    text     : "1244",
    align    : "left",
    value    : "closedOn",
    width    : 175,
    cellClass: "text-truncate max-width-175",
    sort     : compareDates,
    class    : defaultTableHeaderClasses
  }],
  dateTimeColumn: (text, value) => ({
    text,
    value    : value.toString(),
    align    : "left",
    sort     : compareDates,
    width    : 175,
    cellClass: "text-truncate max-width-175",
    class    : defaultTableHeaderClasses
  }),
  dateColumn: (text, value) => ({
    text,
    value    : value.toString(),
    align    : "left",
    sort     : compareDates,
    width    : 125,
    cellClass: "text-truncate max-width-125",
    class    : defaultTableHeaderClasses
  }),
  booleanColumn: (text, value) => ({
    text,
    value: value.toString(),
    align: "left",
    class: defaultTableHeaderClasses
  }),
  textColumn: (text, value, isSortable = true) => ({
    text,
    value    : value.toString(),
    align    : "left",
    class    : defaultTableHeaderClasses,
    width    : 200,
    sortable : isSortable,
    cellClass: "text-truncate max-width-200"
  }),
  loadingText : "241",
  noDataText  : "242",
  noResultText: "243",
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText   : "244",
    itemsPerPageOptions: [50, 100]
  }
}
import { defaultTableHeaderClasses } from "@/constants"

export const FORM_TEMPLATE_ADD_FIELDS = {
  headers: [{
    text : "807",
    align: "left",
    value: "systemName",
    class: defaultTableHeaderClasses
  },
  {
    text : "808",
    align: "left",
    value: "fieldType",
    class: defaultTableHeaderClasses
  }]
}
/**
 * @file It contains all the vuex integrations module state variables.
 */
export default {
  integrations       : new Array(),
  addingIntegration  : false,
  integrationAdded   : false,
  integrationAddError: undefined,
  removingIntegration: false,
  integrationRemoved : false
}
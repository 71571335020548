/**
 * @file It contains vuex roles module getter methods to access store variables.
 */
import { getMapOfArrayOfObjects } from "@/utils"

export default {
  isLoadingRoles : state => state.loadingRoles,
  roles          : state => state.roles,
  rolesMap       : state => getMapOfArrayOfObjects(state.roles, "id"),
  policiesOfRoles: state => state.policiesOfRoles
}
/**
 * @file It contains vuex token module mutations to mutate the state variables.
 */
export default {
  setLoadingFieldsV2: (state, loadingFieldsV2) => {
    state.loadingFieldsV2 = loadingFieldsV2
  },
  setFieldsV2: (state, fieldsV2) => {
    state.fieldsV2 = fieldsV2
  },
  updateFieldsV2: (state, fieldsV2) => {
    for (const fieldV2 of fieldsV2) {
      const index = state.fieldsV2.findIndex(stateFieldV2 => stateFieldV2?.id === fieldV2.id)
      if (index >= 0) {
        state.fieldsV2.splice(index, 1, { ...state.fieldsV2[index], ...fieldV2 })
      } else {
        state.fieldsV2.push(fieldV2)
      }
    }
  },
  setAddingFieldV2: (state, addingFieldV2) => {
    state.addingFieldV2 = addingFieldV2
  },
  setFieldV2Added: (state, fieldV2Added) => {
    state.fieldV2Added = fieldV2Added
  },
  setFieldV2AddError: (state, fieldV2AddError) => {
    state.fieldV2AddError = fieldV2AddError
  },
  setUpdatingField: (state, properties) => {
    for (const property of properties) {
      state.updatingField[property] = true
    }
  },
  resetFieldUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.fieldUpdated[property] = false
      }
    } else {
      Object.keys(state.fieldUpdated).forEach(property => state.fieldUpdated[property] = false)
    }
  },
  setFieldUpdated: (state, properties) => {
    for (const property of properties) {
      state.fieldUpdated[property] = true
      setTimeout(() => {
        state.fieldUpdated[property] = false
      }, 5000)
    }
  },
  resetUpdatingField: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingField[property] = false
      }
    } else {
      Object.keys(state.updatingField).forEach(property => state.updatingField[property] = false)
    }
  },
  setFieldUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.fieldUpdateError[property] = error
    }
  },
  resetFieldUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.fieldUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.fieldUpdateError).forEach(property => state.fieldUpdateError[property] = undefined)
    }
  },
  setDownloadingFieldTranslation: (state, downloadingFieldTranslation) => {
    state.downloadingFieldTranslation = downloadingFieldTranslation
  },
  setUploadingFieldTranslation: (state, uploadingTranslationFile) => {
    state.uploadingTranslationFile = uploadingTranslationFile
  },
  setTranslationFileUploaded: (state, translationFileUploaded) => {
    state.translationFileUploaded = translationFileUploaded
  }
}
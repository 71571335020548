import { defaultTableHeaderClasses } from "@/constants"

export const ISSUE_ADD_CUSTOM_FIELDS = {
  headers: [{
    text : "895",
    align: "left",
    value: "systemName",
    class: defaultTableHeaderClasses,
    width: "50%"
  },
  {
    text : "896",
    align: "left",
    value: "type",
    class: defaultTableHeaderClasses,
    width: "50%"
  }]
}
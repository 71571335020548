/**
 * @file It contains vuex token module getter methods to access store variables.
 */
export default {
  fieldsV2                     : state => state.fieldsV2,
  isLoadingFieldsV2            : state => state.loadingFieldsV2,
  isAddingFieldV2              : state => state.addingFieldV2,
  isFieldV2Added               : state => state.fieldV2Added,
  fieldV2AddError              : state => state.fieldV2AddError,
  isUpdatingLabel              : state => state.updatingField.label,
  isLabelUpdated               : state => state.fieldUpdated.label,
  isUpdatingFieldSystemName    : state => state.updatingField.systemName,
  fieldSystemNameUpdateError   : state => state.fieldUpdateError.systemName,
  isDownloadingFieldTranslation: state => state.downloadingFieldTranslation,
  isUploadingTranslationFile   : state => state.uploadingTranslationFile,
  isTranslationFileUploaded    : state => state.translationFileUploaded
}
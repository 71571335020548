import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/automations`

export default {
  /**
   * This action will load automations.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load automations.
   */
  async loadAutomations(context, payload) {
    context.commit("setLoadingAutomations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getAutomationsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getAutomationsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateAutomations", [getAutomationsResponse.data])
      } else {
        context.commit("setAutomations", getAutomationsResponse.data)
      }
    }

    context.commit("setLoadingAutomations", false)
  },

  /**
   * This action will add a automation.
   * @param {*} context is the store.
   * @param {*} payload is the data of automation.
   */
  async addAutomation(context, payload) {
    context.commit("setAddingAutomation", true)
    context.commit("setAutomationAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postAutomationResponse = await httpClient.post(undefined, payload)

    if (postAutomationResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateAutomations", [postAutomationResponse.data])
      context.commit("setAutomationAdded", true)
    } else if (postAutomationResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setAutomationAddError", postAutomationResponse.data)
    }

    context.commit("setAddingAutomation", false)
  },

  /**
   * This action will add a automation.
   * @param {*} context is the store.
   * @param {*} payload is the data of automationRules.
   */
  async createUpdateDeleteAutomationRules(context, payload) {

    context.commit("setCreatingUpdatingDeletingAutomationRules", true)
    context.commit("setAutomationRulesCreatedUpdatedDeleted", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postAutomationRulesResponse = await httpClient.post(`/${payload.id}/rules`, payload.data)

    if (postAutomationRulesResponse.status === HTTP_STATUS_CODE.OK) {

      await context.dispatch("loadAutomations")

      context.commit("setAutomationRulesCreatedUpdatedDeleted", true)
    }

    context.commit("setCreatingUpdatingDeletingAutomationRules", false)
  },

  /**
   * This action will add an automation (if it doesn't exist) and then call action for adding automation rules.
   * @param {*} context is the store.
   * @param {*} payload is the data of automationRules.
   */
  async addAutomationAndRules(context, payload) {
    context.commit("setAddingAutomationAndRules", true)
    context.commit("setAutomationAndRulesAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const automationData = payload.automationData
    const automations    = context.getters.automations

    const existingAutomation = automations.find(automation =>
      automation.event === automationData.event &&
      automation.ruleName === automationData.ruleName &&
      automation.domainId === automationData.domainId
    )

    const automationRulesData = {
      data: payload.automationRulesData
    }
    if (existingAutomation) {
      automationRulesData.id = existingAutomation.id
      await context.dispatch("createUpdateDeleteAutomationRules", automationRulesData)
    } else {
      const postAutomationResponse = await httpClient.post(undefined, automationData)

      if (postAutomationResponse.status === HTTP_STATUS_CODE.OK) {
        context.commit("updateAutomations", [postAutomationResponse.data])

        automationRulesData.id = postAutomationResponse.data.id
        await context.dispatch("createUpdateDeleteAutomationRules", automationRulesData)
      }
    }

    if (context.getters.isAutomationRulesCreatedUpdatedDeleted) {
      context.commit("setAutomationAndRulesAdded", true)
    }
    context.commit("setAddingAutomationAndRules", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setAutomations", new Array())
    context.commit("setLoadingAutomations", false)
    context.commit("setAddingAutomation", false)
    context.commit("setAutomationAdded", false)
    context.commit("setAutomationAddError", undefined)
    context.commit("setCreatingUpdatingDeletingAutomationRules", false)
    context.commit("setAutomationRulesCreatedUpdatedDeleted", false)
    context.commit("setAddingAutomationAndRules", false)
    context.commit("setAutomationAndRulesAdded", false)
  }
}

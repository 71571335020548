import store from "@/plugins/vuex"
import { OPTION_LIST } from "@/constants/bread-crumbs/option-list"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to option list page.
 * @param {*} to route to be navigated to.
 */
export const beforeOptionList = async to => {
  await store.dispatch("optionLists/loadOptionList", +to.params.id)
  const optionLists = store.getters["optionLists/optionLists"]
  const optionList  = optionLists.find(optionList => optionList.id === +to.params.id)
  if (!optionList) {
    return "dashboard"
  }
  store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(optionList.name))
  store.commit("shared/setBreadcrumbs", OPTION_LIST(to, optionList.name))
  store.dispatch("optionListItems/loadOptionListItems", {
    optionListId: +to.params.id
  })
}
/**
 * @file This file contains validation setup using vee validate
 */
import Vue from "vue"
import { extend, ValidationProvider, ValidationObserver } from "vee-validate"
import { required, email, max, digits, min, regex, min_value as minValue } from "vee-validate/dist/rules"
import i18n from "./i18n"

extend("required", {
  ...required,
  message: field => {
    return i18n.t("449", { field: i18n.t(field.split("_")[0]) })
  }
})
extend("email", {
  ...email,
  message: field => {
    return i18n.t("447", { field: i18n.t(field) })
  }
})

extend("duplicate", {
  validate: (value, args) => {
    return args[0] !== "true"
  },
  message: field => {
    return i18n.t("446", { field: i18n.t(field) })
  }
})

extend("max", {
  ...max,
  message: (...args) => {
    return i18n.t("529", { maxAllowed: args[1].length, actualCount: args[1]._value_.length })
  }
})

extend("digits", {
  ...digits,
  message: (field, value) => {
    return i18n.t("448", { field: i18n.t(field), length: value.length })
  }
})

extend("custom", {
  validate: (value, args) => {
    if (args[0] === "false") {
      return true
    } else {
      return i18n.t(args[1])
    }
  }
})

extend("min", {
  ...min,
  message: (field, ...args) => {
    return i18n.t("607", { field: i18n.t(field), limit: args[0].length })
  }
})

extend("regex", {
  ...regex,
  message: () => {
    return
  }
})

extend("min_value", {
  ...minValue,
  message: (...args) => {
    return i18n.t("1301", { number: args[1].min })
  }
})

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

export default {
  automations                            : new Array(),
  loadingAutomations                     : false,
  addingAutomation                       : false,
  automationAdded                        : false,
  automationAddError                     : undefined,
  automationRulesSaveError               : undefined,
  creatingUpdatingDeletingAutomationRules: false,
  automationRulesCreatedUpdatedDeleted   : false,
  addingAutomationAndRules               : false,
  automationAndRulesAdded                : false
}
/**
 * @file It contains vuex kpis module mutations to mutate the state variables.
 */
export default {
  setLoadingKpis: (state, loadingKpis) => {
    state.loadingKpis = loadingKpis
  },
  setKpis: (state, kpis) => {
    for(const key of Object.keys(kpis)) {
      state[key] = kpis[key]
    }
  },
  resetKpis: state => {
    state.issuesWithUnreadMessages   = new Array()
    state.issuesWithUnansweredReport = new Array()
  }
}
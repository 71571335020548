/**
 * @file It contains vuex accesses module mutations to mutate the state variables.
 */
export default {
  setLoadingAccesses: (state, loadingAccesses) => {
    state.loadingAccesses = loadingAccesses
  },
  setAddingAccess: (state, addingAccess) => {
    state.addingAccess = addingAccess
  },
  setAccessAdded: (state, accessAdded) => {
    state.accessAdded = accessAdded
    if (accessAdded) {
      setTimeout(() => {
        state.accessAdded = false
      }, 5000)
    }
  },
  setRemovingAccess: (state, removingAccess) => {
    state.removingAccess = removingAccess
  },
  setAccessRemoved: (state, accessRemoved) => {
    state.accessRemoved = accessRemoved
    if (accessRemoved) {
      setTimeout(() => {
        state.accessRemoved = false
      }, 5000)
    }
  },
  setAccesses: (state, accesses) => {
    state.accesses = accesses
  },
  removeAccess: (state, accessId) => {
    const index = state.accesses.findIndex(access => access.id === accessId)
    state.accesses.splice(index, 1)
  },
  updateAccesses: (state, accesses) => {
    for (const access of accesses) {
      const index = state.accesses.findIndex(stateAccess => stateAccess?.id === access.id)
      if (index >= 0) {
        state.accesses.splice(index, 1, { ...state.accesses[index], ...access })
      } else {
        state.accesses.push(access)
      }
    }
  },
  setAccessAddError: (state, accessAddError) => {
    state.accessAddError = accessAddError
  }
}
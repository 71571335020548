/**
 * @file This file adds all constants to vue
 */
import Vue from "vue"
import CONSTANTS from "@/constants"
import LOCALES from "@/constants/locales"
import MODULES from "@/constants/modules"
import { DASHBOARD } from "@/constants/modules"
import USER_MENU_MODULES from "@/constants/user-menu-modules"
import SUPPORT_MENU_MODULES from "@/constants/support-menu-modules"
import DECISIONS from "@/constants/decisions"
import TABLES from "@/constants/tables"
import KANBANS from "@/constants/kanbans"

Vue.prototype.$CONSTANTS            = CONSTANTS
Vue.prototype.$LOCALES              = LOCALES
Vue.prototype.$MODULES              = MODULES
Vue.prototype.$USER_MENU_MODULES    = USER_MENU_MODULES
Vue.prototype.$SUPPORT_MENU_MODULES = SUPPORT_MENU_MODULES
Vue.prototype.$DECISIONS            = DECISIONS
Vue.prototype.$TABLES               = TABLES
Vue.prototype.$KANBANS              = KANBANS
Vue.prototype.$DASHBOARD            = DASHBOARD
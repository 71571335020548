import { defaultTableHeaderClasses } from "@/constants"

export const NOTIFICATIONS = {
  headers: [
    {
      text    : "1180",
      align   : "left",
      value   : "notificationName",
      sortable: false,
      class   : `${defaultTableHeaderClasses} col-md-10 col-sm-8`
    },
    {
      text    : "1181",
      align   : "right",
      value   : "notificationSubscribed",
      sortable: false,
      class   : `${defaultTableHeaderClasses} col-md-2 col-sm-4`
    }
  ]
}
export default {
  setLoadingAutomations: (state, loadingAutomations) => {
    state.loadingAutomations = loadingAutomations
  },

  setAddingAutomation: (state, addingAutomation) => {
    state.addingAutomation = addingAutomation
  },

  setAutomationAdded: (state, automationAdded) => {
    state.automationAdded = automationAdded
  },

  setAutomations: (state, automations) => {
    state.automations = automations
  },

  setAutomationAddError: (state, automationAddError) => {
    state.automationAddError = automationAddError
  },

  updateAutomations: (state, automations) => {
    for (const automation of automations) {
      const index = state.automations.findIndex(stateAutomation => stateAutomation?.id === automation.id)
      if (index >= 0) {
        state.automations.splice(index, 1, { ...state.automations[index], ...automation })
      } else {
        state.automations.push(automation)
      }
    }
  },

  setAutomationRulesSaveError: (state, automationRulesSaveError) => {
    state.automationRulesSaveError = automationRulesSaveError
  },
  setCreatingUpdatingDeletingAutomationRules: (state, creatingUpdatingDeletingAutomationRules) => {
    state.creatingUpdatingDeletingAutomationRules = creatingUpdatingDeletingAutomationRules
  },
  setAutomationRulesCreatedUpdatedDeleted: (state, automationRulesCreatedUpdatedDeleted) => {
    state.automationRulesCreatedUpdatedDeleted = automationRulesCreatedUpdatedDeleted
  },

  updateAutomationRules: (state, { automationId, automationRules }) => {
    const indexOfAutomation = state.automations.findIndex(stateAutomation => stateAutomation?.id === automationId)
    if (indexOfAutomation >= 0) {
      if (!state.automations[indexOfAutomation].rules) {
        const automation = {
          ...state.automations[indexOfAutomation],
          rules: []
        }
        state.automations.splice(indexOfAutomation, 1, automation)
      }

      if (automationRules.add) {
        for (const automationRule of automationRules.add) {
          state.automations[indexOfAutomation].rules.push(automationRule)
        }
      }

      if (automationRules.update) {
        for (const automationRule of automationRules.update) {
          const indexofRule = state.automations[indexOfAutomation].rules.findIndex(
            rule => rule?.id === automationRule.id
          )
          if (indexofRule >= 0) {
            state.automations[indexOfAutomation].rules.splice(
              indexofRule, 1, { ...state.automations[indexOfAutomation].rules[indexofRule], ...automationRule })
          } else {
            state.automations[indexOfAutomation].rules.push(automationRule)
          }
        }
      }

      if (automationRules.delete) {
        for (const automationRuleId of automationRules.delete) {
          const indexofRule = state.automations[indexOfAutomation].rules.findIndex(
            rule => rule?.id === automationRuleId)
          state.automations[indexOfAutomation].rules.splice(indexofRule, 1)
        }
      }
    }
  },
  setAddingAutomationAndRules: (state, addingAutomationAndRules) => {
    state.addingAutomationAndRules = addingAutomationAndRules
  },
  setAutomationAndRulesAdded: (state, automationAndRulesAdded) => {
    state.automationAndRulesAdded = automationAndRulesAdded
  }
}

export default {
  isLoadingOptionListItems        : state => state.loadingOptionListItems,
  optionListItems                 : state => state.optionListItems,
  isOptionListItemRemoved         : state => state.optionListItemRemoved,
  isRemovingOptionListItem        : state => state.removingOptionListItem,
  isAddingOptionListItem          : state => state.addingOptionListItem,
  isOptionListItemAdded           : state => state.optionListItemAdded,
  optionListItemAddError          : state => state.optionListItemAddError,
  isAddingBulkOptionListItems     : state => state.addingBulkOptionListItems,
  isBulkOptionListItemsAdded      : state => state.bulkOptionListItemsAdded,
  numberOfOptionListItemsAdded    : state => state.numberOfOptionListItemsAdded,
  numberOfDuplicateOptionListItems: state => state.numberOfDuplicateOptionListItems,
  isUpdatingSortingOrder          : state => state.updatingOptionListItem.sortingOrder,
  isSortingOrderUpdated           : state => state.optionListItemUpdated.sortingOrder,
  isUpdatingParentId              : state => state.updatingOptionListItem.parentId,
  isParentIdUpdated               : state => state.optionListItemUpdated.parentId
}
/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/roles`

export default {
  /**
   * This action will load roles.
   * @param {*} context is the store.
   */
  async loadRoles(context) {

    context.commit("setLoadingRoles", true)
    const httpClient       = GET_HTTP_CLIENT({
      baseURL
    }, context)
    const getRolesResponse = await httpClient.get()
    if (getRolesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setRoles", getRolesResponse.data)
    } else {
      // something went wrong while getting roles
    }

    context.commit("setLoadingRoles", false)
  },

  /**
   * This action load policies of role.
   * @param {*} context is the store.
   * @param {*} payload contains id of the role.
   */
  async loadRolePolicies(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getPoliciesResponse = await httpClient.get(`/${payload.id}/policies`)

    if (getPoliciesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updatePoliciesOfRole", {
        role    : payload,
        policies: getPoliciesResponse.data
      })
    } else {
      // something went wrong while loading group roles
    }
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingRoles", undefined)
    context.commit("setRoles", new Array())
    context.commit("resetPoliciesOfRoles")
  }
}
/**
 * @file It contains vuex groups module mutations to mutate the state variables.
 */
export default {
  setLoadingGroups: (state, loadingGroups) => {
    state.loadingGroups = loadingGroups
  },
  setGroups: (state, groups) => {
    state.groups = groups
  },
  updateGroups: (state, groups) => {
    for (const group of groups) {
      const index = state.groups.findIndex(stateGroup => stateGroup?.id === group.id)
      if (index >= 0) {
        state.groups.splice(index, 1, { ...state.groups[index], ...group })
      } else {
        state.groups.push(group)
      }
    }
  },
  setGroupUsers: (state, { group, users }) => {
    state.groupsUsers[group] = users
  },
  setAllGroupUsers: (state, groupsUsers) => {
    state.groupsUsers = groupsUsers
  },
  resetGroups: state => {
    state.groups = new Array()
  },
  resetGroupsUsers: state => {
    state.groupsUsers = new Object()
  },
  setAddingGroup: (state, addingGroup) => {
    state.addingGroup = addingGroup
  },
  setGroupAdded: (state, groupAdded) => {
    state.groupAdded = groupAdded
  },
  setGroupAddError: (state, groupAddError) => {
    state.groupAddError = groupAddError
  },
  setUpdatingGroup: (state, properties) => {
    for (const property of properties) {
      state.updatingGroup[property] = true
    }
  },
  resetUpdatingGroup: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingGroup[property] = false
      }
    } else {
      Object.keys(state.updatingGroup).forEach(property => state.updatingGroup[property] = false)
    }
  },
  setGroupUpdated: (state, properties) => {
    for (const property of properties) {
      state.groupUpdated[property] = true
      setTimeout(() => {
        state.groupUpdated[property] = false
      }, 5000)
    }
  },
  resetGroupUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.groupUpdated[property] = false
      }
    } else {
      Object.keys(state.groupUpdated).forEach(property => state.groupUpdated[property] = false)
    }
  },
  setGroupUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.groupUpdateError[property] = error
    }
  },
  resetGroupUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.groupUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.groupUpdateError).forEach(property => state.groupUpdateError[property] = undefined)
    }
  },
  setAddingUsers: (state, addingUsers) => {
    state.addingUsers = addingUsers
  },
  setRemovingUser: (state, removingUser) => {
    state.removingUser = removingUser
  },

  updateGroupUsers: (state, { group, users }) => {
    if (state.groupsUsers[group]) {
      const groupUsers         = new Set([
        ...state.groupsUsers[group],
        ...users
      ])
      state.groupsUsers[group] = Array.from(groupUsers)
    } else {
      state.groupsUsers = Object.assign({}, state.groupsUsers, {
        [group]: users
      })
    }
  },
  removeGroupUser: (state, { group, user }) => {
    state.groupsUsers[group] = state.groupsUsers[group].filter(userId => userId !== user)
  },
  setRemovingGroup: (state, removingGroup) => {
    state.removingGroup = removingGroup
  },
  setGroupRemoved: (state, groupRemoved) => {
    state.groupRemoved = groupRemoved
  },
  removeGroup: (state, groupId) => {
    delete state.groupsUsers[groupId]
    state.groups = state.groups.filter(group => group.id !== groupId)
  }
}
import { AUTOMATION_EVENT, AUTOMATION_RULE_NAME } from "@/constants"

export default {
  isLoadingAutomations                              : state => state.loadingAutomations,
  automations                                       : state => state.automations,
  isAutomationAdded                                 : state => state.automationAdded,
  isAddingAutomation                                : state => state.addingAutomation,
  automationAddError                                : state => state.automationAddError,
  automationRulesSaveError                          : state => state.automationRulesSaveError,
  isCreatingUpdatingDeletingAutomationRules         : state => state.creatingUpdatingDeletingAutomationRules,
  isAutomationRulesCreatedUpdatedDeleted            : state => state.automationRulesCreatedUpdatedDeleted,
  isAddingAutomationAndRules                        : state => state.addingAutomationAndRules,
  isAutomationAndRulesAdded                         : state => state.automationAndRulesAdded,
  domainChangeOnSpeakUpIssueCreationRulesForChannels: state => {
    const rulesForChannels = new Map()

    for (const automation of state.automations) {
      const isEventSpeakUpIssueCreate = automation.event === AUTOMATION_EVENT.SPEAK_UP_ISSUE_CREATE
      const isRuleNameDomainChange    = automation.ruleName === AUTOMATION_RULE_NAME.DOMAIN_CHANGE

      if (isEventSpeakUpIssueCreate && isRuleNameDomainChange && automation.rules) {
        for (const rule of automation.rules) {
          if (rule.value) {
            const isChannelKeyNotPresentInRulesForChannels = !rulesForChannels[rule.value.channelId]
            if (isChannelKeyNotPresentInRulesForChannels) {
              rulesForChannels[rule.value.channelId] = []
            }
            rulesForChannels[rule.value.channelId].push(rule)
          }
        }
      }
    }
    return rulesForChannels
  },

  domainChangeOnSpeakUpIssueCreationRulesForDomains: state => {
    const rulesForDomains = new Map()

    for (const automation of state.automations) {
      const isEventSpeakUpIssueCreate = automation.event === AUTOMATION_EVENT.SPEAK_UP_ISSUE_CREATE
      const isRuleNameDomainChange    = automation.ruleName === AUTOMATION_RULE_NAME.DOMAIN_CHANGE

      if (isEventSpeakUpIssueCreate && isRuleNameDomainChange && automation.rules) {
        for (const rule of automation.rules) {
          const isDomainKeyNotPresentInRulesForDomains = !rulesForDomains[rule.value.result.domainId]
          if (isDomainKeyNotPresentInRulesForDomains) {
            rulesForDomains[rule.value.result.domainId] = []
          }
          rulesForDomains[rule.value.result.domainId].push(rule)
        }
      }
    }
    return rulesForDomains
  },

  domainChangeOnSpeakUpIssueCreationRulesForFields: state => {
    const rulesForFields = new Map()
    for (const automation of state.automations) {
      const isEventSpeakUpIssueCreate = automation.event === AUTOMATION_EVENT.SPEAK_UP_ISSUE_CREATE
      const isRuleNameDomainChange    = automation.ruleName === AUTOMATION_RULE_NAME.DOMAIN_CHANGE

      if (isEventSpeakUpIssueCreate && isRuleNameDomainChange) {
        for (const rule of automation.rules) {
          for (const condition of rule.value.conditions) {
            const isFieldKeyNotPresentInRulesForFields = !rulesForFields[condition.fieldId]
            if (isFieldKeyNotPresentInRulesForFields) {
              rulesForFields[condition.fieldId] = []
            }
            rulesForFields[condition.fieldId].push(rule)
          }
        }
      }

    }
    return rulesForFields
  }
}
export default {
  "Issue view"                     : "setIssueViewPolicies",
  "Issue update"                   : "setIssueUpdatePolicies",
  "Issue add comment"              : "setIssueAddCommentPolicies",
  "Issue remove comment"           : "setIssueRemoveCommentPolicies",
  "Issue add documents"            : "setIssueAddDocumentsPolicies",
  "IssueDocument remove"           : "setIssueDocumentRemovePolicies",
  "Report view"                    : "setReportViewPolicies",
  "Report update"                  : "setReportUpdatePolicies",
  "Report add messages"            : "setReportAddMessagesPolicies",
  "Message update"                 : "setMessageUpdatePolicies",
  "Message remove"                 : "setMessageRemovePolicies",
  "MessageItem add translations"   : "setMessageItemAddTranslationsPolicies",
  "Translation update"             : "setTranslationUpdatePolicies",
  "Issue add form instances"       : "setIssueAddFormInstancesPolicies",
  "FormInstance update"            : "setFormInstanceUpdatePolicies",
  "FormInstance remove"            : "setFormInstanceRemovePolicies",
  "Issue update issue field values": "setIssueUpdateIssueFieldValuesPolicies",
  "Issue print"                    : "setIssuePrintPolicies",
  "Issue add link"                 : "setIssueLinkAddPolicies",
  "IssueLink remove"               : "setIssueLinkRemovePolicies",
  "Issue add task"                 : "setIssueAddTaskPolicies"

}
import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to users page.
 */
export const beforeUsers = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.USERS))
  store.commit("shared/setBreadcrumbs", undefined)
  store.dispatch("groups/loadGroups")
  await store.dispatch("users/loadUsers")
  const users = store.getters["users/users"]
  for(const user of users) {
    store.dispatch("users/loadGroups", user.id)
  }
}
/**
 * @file It contains all the vuex token module state variables.
 */
export default {
  pageFreeze     : false,
  notify         : false,
  configurations : undefined,
  clientName     : undefined,
  breadcrumbs    : undefined,
  notification   : undefined,
  unexpectedError: undefined,
  logout         : undefined,
  pageTitle      : {
    value         : undefined,
    useTranslation: false
  }
}

import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/form-instances`

export default {
  /**
   * This action will load form Instance.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load formInstances.
   */
  async loadFormInstances(context, payload) {
    context.commit("setLoadingFormInstances", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getFormInstancesResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getFormInstancesResult.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateFormInstances", getFormInstancesResult.data)
      } else {
        context.commit("setFormInstances", getFormInstancesResult.data)
      }

    } else {
      // something went wrong while getting form instances
    }

    context.commit("setLoadingFormInstances", false)
  },

  /**
   * This action will add form instance to a issue.
   * @param {*} context is the store.
   */
  async addIssueFormInstance(context, payload) {
    context.commit("setAddingIssueFormInstance", true)
    context.commit("setIssueFormInstanceAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postIssueFormInstanceResponse = await httpClient.post(undefined, payload)

    if (postIssueFormInstanceResponse.status === HTTP_STATUS_CODE.OK) {
      const formInstance = postIssueFormInstanceResponse.data
      context.dispatch("accessControl/loadLoggedInUserAccess", [{
        policies   : ["FormInstance update", "FormInstance remove"],
        resourceIds: [formInstance.id]
      }], { root: true })

      context.commit("updateFormInstances", [postIssueFormInstanceResponse.data])
      context.commit("setIssueFormInstanceAdded", true)
    }

    context.commit("setAddingIssueFormInstance", false)
  },

  /**
   * This action updates a formInstance.
   * @param {*} context is the store.
   * @param {*} payload contains formInstance id and data to be updated.
   */
  async updateFormInstance(context, payload) {
    context.commit("setUpdatingFormInstance", true)
    context.commit("setFormInstanceUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateFormInstanceResponse = await httpClient.put(`/${payload.id}`, payload.data)

    if (updateFormInstanceResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateFormInstance", payload)
      context.commit("setFormInstanceUpdated", true)
    }

    context.commit("setUpdatingFormInstance", false)
  },

  /**
   * This action removes form instance.
   * @param {*} context is the store.
   * @param {*} payload contains the id of the form instance
   */
  async removeFormInstance(context, payload) {
    context.commit("setRemovingFormInstance", true)
    context.commit("setFormInstanceRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const deleteFormInstanceResponse = await httpClient.delete(`/${payload}`)

    if (deleteFormInstanceResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("formInstanceRemove", payload)
      context.commit("setFormInstanceRemoved", true)
    }

    context.commit("setRemovingFormInstance", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingFormInstances", false)
    context.commit("setFormInstances", new Array())
    context.commit("setAddingIssueFormInstance", false)
    context.commit("setIssueFormInstanceAdded", false)
    context.commit("setUpdatingFormInstance", false)
    context.commit("setFormInstanceUpdated", false)
    context.commit("setRemovingFormInstance", false)
    context.commit("setFormInstanceRemoved", false)
  }
}
/**
 * @file It contains all the vuex reports module state variables.
 */
export default {
  loadingReports: undefined,
  reports       : new Array(),
  updatingReport: {
    status: false
  },
  reportUpdated: {
    status: false
  },
  loadingReportFormValues: undefined,
  reportFormValues       : new Array(),
  accessInformation      : new Object()
}
export const DOMAIN = (route, domain) => ([
  {
    text          : "59",
    exact         : true,
    to            : "/domains",
    useTranslation: true
  },
  {
    text          : domain.name,
    to            : `/domains/${route.params.id}`,
    useTranslation: false
  }
])
/**
 * @file It contains vuex messageItems module getter methods to access store variables.
 */
export default {
  isLoadingMessageItems        : state => state.loadingMessageItems,
  isDownloadingFile            : state => state.downloadingFile,
  messageItems                 : state => state.messageItems,
  isMessageItemAdded           : state => state.messageItemAdded,
  isAddingMessageItem          : state => state.addingMessageItem,
  isUpdatingValue              : state => state.updatingMessageItem.value,
  isValueUpdated               : state => state.messageItemUpdated.value,
  isMessageItemHumanTranslated : state => state.messageItemHumanTranslated,
  isHumanTranslatingMessageItem: state => state.humanTranslatingMessageItem
}
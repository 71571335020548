/**
 * @file It contains all the vuex translations module state variables.
 */
export default {
  loadingTranslations: undefined,
  translations       : new Array(),
  addingTranslation  : undefined,
  translationAdded   : undefined,
  translationAddError: undefined,
  updatingTranslation: {
    status: false,
    reason: false

  },
  translationUpdated: {
    status: false,
    reason: false
  },
  translationUpdateError: {
    status: false,
    reason: false
  }
}
export const EXCEPTION_STATUS = {
  OPEN    : "497",
  APPROVED: "498",
  DECLINED: "499"
}

export const SPEAKUP_ISSUE_ACKNOWLEDGEMENT = {
  date_of_receipt                          : "1006",
  date_of_first_reply_available_to_reporter: "1007"
}

export const TRANSLATION_PREFERENCES = {
  NO_TRANSLATION   : "450",
  MACHINE_PREFERRED: "451",
  MACHINE_ONLY     : "452",
  HUMAN_ONLY       : "453",
  HUMAN_ADDED      : "454"
}

export const PASSWORD_VALIDATION = {
  minimumCharactersPresent         : "458",
  minimumNumbersPresent            : "459",
  minimumUpperCaseCharactersPresent: "460",
  minimumLowerCaseCharactersPresent: "461",
  minimumSpecialCharactersPresent  : "462"
}

export const LOGS = {
  ISSUE_CREATE                  : "507",
  ISSUE_SUMMARY_CHANGE          : "508",
  ISSUE_ASSIGNEE_CHANGE         : "509",
  ISSUE_RESOLUTION_CHANGE       : "511",
  ISSUE_STATUS_CHANGE           : "514",
  ISSUE_DOMAIN_CHANGE           : "515",
  ISSUE_DESCRIPTION_CHANGE      : "1008",
  ISSUE_ACKNOWLEDGED_AT_CHANGE  : "1010",
  ISSUE_RECEIVED_AT_CHANGE      : "1009",
  ISSUE_INVITE_USER             : "516",
  ISSUE_INVITE_GROUP            : "516",
  ISSUE_REMOVE_USER             : "517",
  ISSUE_REMOVE_GROUP            : "517",
  REPORT_CLOSED                 : "518",
  REPORT_ANONYMISATION_SCHEDULED: "519",
  REPORT_ANONYMISATION_COMPLETED: "520",
  REPORT_BLOCKED                : "521",
  MESSAGE_SENT                  : {
    REPORTER: "522",
    CLIENT  : "528"
  },
  MESSAGE_SEEN                               : "523",
  TRANSLATION_CREATE                         : "524",
  TRANSLATION_REDO_REQUESTED                 : "525",
  TRANSLATION_SOURCE_LANGUAGE_CHANGE_APPROVED: "526",
  TRANSLATION_SOURCE_LANGUAGE_CHANGE_DECLINED: "527",
  USER_CREATE                                : "537",
  USER_EMAIL_CHANGE                          : "541",
  USER_DISABLE_MFA                           : "542",
  USER_DISABLED                              : "543",
  USER_ENABLED                               : "544",
  USER_DELETE                                : "545",
  DOMAIN_CREATE                              : "547",
  FORM_TEMPLATE_CREATE                       : {
    INTAKE_FORM: "753",
    ISSUE_FORM : "970"
  },
  DOMAIN_NAME_CHANGE                                   : "548",
  DOMAIN_ARCHIVED                                      : "549",
  DOMAIN_ADD_ROLE                                      : "550",
  DOMAIN_REMOVE_ROLE                                   : "551",
  GROUP_CREATE                                         : "552",
  GROUP_DELETE                                         : "553",
  GROUP_NAME_CHANGE                                    : "555",
  GROUP_ADD_USER                                       : "556",
  GROUP_REMOVE_USER                                    : "557",
  ROLE_ADD_ACCESS                                      : "558",
  ROLE_REMOVE_ACCESS                                   : "559",
  CHANNEL_OVERRIDE_ASK_ORGANISATION_CODE_FOR_WEB_CHANGE: "570",
  CHANNEL_UPDATE_TRANSLATION_CONFIGURATIONS            : "579",
  CHANNEL_CREATE                                       : "573",
  CHANNEL_NAME_CHANGE                                  : "564",
  CHANNEL_DISPLAY_NAME_CHANGE                          : "565",
  CHANNEL_LINK_CHANGE                                  : "614",
  CHANNEL_LINK_DISPLAY_NAME_CHANGE                     : "615",
  CHANNEL_ENABLE_VOICE_MESSAGE_FOR_APP                 : "567",
  CHANNEL_DISABLE_VOICE_MESSAGE_FOR_APP                : "568",
  CHANNEL_ORGANISATION_CODE_CHANGE                     : "574",
  CHANNEL_DISABLED                                     : "575",
  CHANNEL_ENABLE_FORM                                  : "576",
  CHANNEL_DISABLE_FORM                                 : "577",
  CHANNEL_ASK_ORGANISATION_CODE_FOR_WEB_CHANGE         : "569",
  CHANNEL_OVERRIDE_TRANSLATION_PREFERENCE_CHANGE       : "571",
  CHANNEL_TRANSLATION_PREFERENCE_CHANGE                : "572",
  CHANNEL_ADD_FORM_TEMPLATE                            : "836",
  FIELD_LABEL_CHANGE                                   : "1025",
  CHANNEL_REMOVE_FORM_TEMPLATE                         : "841",
  OPTION_LIST_CREATE                                   : "759",
  OPTION_LIST_NAME_CHANGE                              : "900",
  OPTION_LIST_ENABLE_TRANSLATION                       : "1231",
  OPTION_LIST_DISABLE_TRANSLATION                      : "1232",
  FIELD_SYSTEM_NAME_CHANGE                             : "909",
  CONFIGURATION_UPDATE                                 : {
    TRANSLATION                  : "582",
    ASK_ORGANISATION_CODE_FOR_WEB: "583",
    SPEAKUP_ISSUE_ACKNOWLEDGEMENT: "1005"
  },
  ISSUE_LABELS_CHANGE                                          : "605",
  CHANNEL_UPLOAD_LOGO                                          : "612",
  CHANNEL_DELETE_LOGO                                          : "613",
  ISSUE_DOCUMENT_CREATE                                        : "1001",
  ISSUE_DOCUMENT_DELETE                                        : "1002",
  SHAREPOINT_UPLOAD_SUCCESS                                    : "1011",
  SHAREPOINT_UPLOAD_FAILURE                                    : "1012",
  ALL_DATA_EXPORT_CREATE                                       : "727",
  ALL_DATA_EXPORT_DOWNLOAD                                     : "728",
  OPTION_LIST_ITEM_CREATE                                      : "747",
  OPTION_LIST_ITEM_DELETE                                      : "1027",
  FIELD_CREATE                                                 : "789",
  FORM_TEMPLATE_ADD_FIELD                                      : "819",
  FORM_TEMPLATE_NAME_CHANGE                                    : "910",
  FORM_TEMPLATE_REMOVE_FIELD                                   : "916",
  CONFIGURATION_SSO_CONFIGURE                                  : "874",
  CONFIGURATION_SSO_CONFIGURE_UPDATE                           : "878",
  CONFIGURATION_SSO_LOGIN_ENABLE                               : "889",
  FORM_TEMPLATE_FIELD_MANDATORY                                : "907",
  FORM_TEMPLATE_FIELD_OPTIONAL                                 : "908",
  OPTION_LIST_ITEM_REMOVE                                      : "918",
  DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_ADD        : "1114",
  DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_REMOVE     : "1115",
  DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_ADD   : "1116",
  DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_UPDATE: "1117",
  DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_REMOVE: "1118",
  DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_TRIGGER    : "1123",
  ISSUE_CREATE_ISSUE_FORM_INSTANCE                             : "994",
  ISSUE_UPDATE_ISSUE_FORM_INSTANCE_FIELD_VALUE                 : "995",
  ISSUE_FIELD_CREATE                                           : "998",
  ISSUE_UPDATE_ISSUE_FIELD_VALUE                               : "858",
  ISSUE_REMOVE_ISSUE_FORM_INSTANCE                             : "1042",
  ISSUE_FIELD_REMOVE                                           : "1047",
  ISSUE_CREATE_ISSUE_LINK                                      : "1168",
  ISSUE_REMOVE_ISSUE_LINK                                      : "1170",
  ISSUE_ADD_TASK                                               : "1266",
  ISSUE_DATA_RETENTION_SCHEDULED                               : "1285",
  DATA_RETENTION_RULE_UPDATE_SUMMARY                           : "1279",
  DATA_RETENTION_RULE_UPDATE_DESCRIPTION                       : "1293",
  DATA_RETENTION_RULE_UPDATE_ISSUE_DOCUMENTS                   : "1313",
  DATA_RETENTION_RULE_UPDATE_COMMENTS                          : "1297",
  DATA_RETENTION_RULE_UPDATE_MESSAGE_CONTENT                   : "1300",
  DATA_RETENTION_RULE_UPDATE_REPORT_DOCUMENTS                  : "1314",
  DATA_RETENTION_RULE_UPDATE_FIELD                             : "1312"
}

export const LOG_LEVEL = {
  6: "539",
  5: "540",
  4: "1050",
  3: "1013"
}

export const ENTITY = {
  USER               : "538",
  DOMAIN             : "546",
  GROUP              : "554",
  ROLE               : "562",
  ISSUE_TYPE         : "563",
  CHANNEL            : "566",
  CONFIGURATION      : "581",
  INTEGRATION        : "1014",
  EXPORT             : "729",
  FORM_TEMPLATE      : "752",
  OPTION_LIST        : "758",
  FIELD              : "791",
  AUTOMATION         : "1119",
  ISSUE_FIELD        : "1216",
  DATA_RETENTION_RULE: "1280"
}

export default {
  EXCEPTION_STATUS,
  TRANSLATION_PREFERENCES,
  PASSWORD_VALIDATION,
  LOGS,
  ENTITY,
  LOG_LEVEL,
  SPEAKUP_ISSUE_ACKNOWLEDGEMENT
}
/**
 * @file It contains all the vuex groups module state variables.
 */
export default {
  loadingGroups: undefined,
  groups       : new Array(),
  groupsUsers  : new Object(),
  addingUsers  : undefined,
  removingUser : undefined,
  removingGroup: undefined,
  groupRemoved : undefined,
  addingGroup  : undefined,
  groupAdded   : undefined,
  groupAddError: undefined,
  updatingGroup: {
    name       : false,
    description: false
  },
  groupUpdated: {
    name       : false,
    description: false
  },
  groupUpdateError: {
    name       : undefined,
    description: undefined
  }
}
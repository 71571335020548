import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"

export const ISSUE_FORMS = {
  headers: [{
    text : "1206",
    align: "left",
    value: "id",
    class: defaultTableHeaderClasses
  },
  {
    text : "1207",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  },
  {
    value   : "actions",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  itemsPerPage: 10,
  footer      : defaultTableFooterOptions
}
/**
 * @file It contains all the vuex channels module state variables.
 */
export default {
  loadingChannels: false,
  channels       : new Array(),
  addingChannel  : false,
  channelAdded   : false,
  channelAddError: undefined,
  updatingChannel: {
    name                             : false,
    askOrganisationCodeForWeb        : false,
    organisationCode                 : false,
    translationPreferenceId          : false,
    overrideAskOrganisationCodeForWeb: false,
    overrideTranslationPreference    : false,
    enabled                          : false,
    displayName                      : false,
    link                             : false,
    linkDisplayName                  : false
  },
  channelUpdated: {
    name                             : false,
    askOrganisationCodeForWeb        : false,
    organisationCode                 : false,
    translationPreferenceId          : false,
    overrideAskOrganisationCodeForWeb: false,
    overrideTranslationPreference    : false,
    enabled                          : false,
    displayName                      : false,
    link                             : false,
    linkDisplayName                  : false
  },
  channelUpdateError: {
    name                             : false,
    askOrganisationCodeForWeb        : false,
    organisationCode                 : false,
    translationPreferenceId          : false,
    overrideAskOrganisationCodeForWeb: false,
    overrideTranslationPreference    : false,
    displayName                      : false,
    link                             : false,
    linkDisplayName                  : false
  },
  formsOfChannels                  : new Object(),
  addingChannelForm                : false,
  removingChannelForm              : false,
  loadingForms                     : false,
  translationConfigurations        : new Object(),
  loadingTranslationConfigurations : false,
  updatingTranslationConfigurations: false,
  translationConfigurationsUpdated : false,
  addingChannelLogo                : false,
  channelLogoAdded                 : false,
  removingChannelLogo              : false,
  channelLogoRemoved               : false,
  downloadingChannelsOverview      : false,
  loadingChannelFormTemplates      : false,
  addingChannelFormTemplates       : false,
  channelFormTemplatesAdded        : false,
  formTemplatesOfChannels          : new Object(),
  removingChannelFormTemplate      : false,
  channelFormTemplateRemoved       : false
}
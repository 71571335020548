import ThemisNotificationSnackbar from "@/components/shared/notification-snackbar"
import { mapGetters, mapActions } from "vuex"
import { hasAccessToRoute } from "@/utils/access"
import SUPPORT_MENU_MODULES from "@/constants/support-menu-modules"
import { PAGES_WITH_BACKGROUND_COLOR, ACCESS_MANAGEMENT_ROUTES,  FORMS_ROUTES, SYSTEM_ROUTES } from "@/constants"

export default {
  name      : "Default",
  components: { ThemisNotificationSnackbar },
  data() {
    return {
      module         : null,
      drawer         : true,
      navigationMini : false,
      sidePanelMini  : false,
      showUserMenu   : false,
      showSupportMenu: false
    }
  },
  computed: {
    ...mapGetters({
      isPageFreezed        : "shared/isPageFreezed",
      isNotified           : "shared/isNotified",
      breadcrumbs          : "shared/breadcrumbs",
      notification         : "shared/notification",
      loggedInUser         : "auth/loggedInUser",
      loggedInUserRoleTypes: "auth/loggedInUserRoleTypes",
      pageTitle            : "shared/pageTitle"
    }),
    isMediumAndBelowScreen() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isLoggedInUserAdmin() {
      return this.loggedInUserRoleTypes.includes("global")
    },
    modules() {
      const modules               = [this.$DASHBOARD]
      const loggedInUserHasAccess = module => {
        return hasAccessToRoute(module.roleTypes)
      }
      if(this.loggedInUserRoleTypes.length) {
        for (const module of this.$MODULES) {
          if (module.items) {
            const subModules = new Array()
            for (const subModule of module.items) {
              if (loggedInUserHasAccess(subModule)) {
                subModules.push(subModule)
              }
            }
            if (subModules.length > 0) {
              modules.push({
                icon : module.icon,
                title: module.title,
                items: subModules
              })
            }
          } else if(loggedInUserHasAccess(module)) {
            modules.push(module)
          }
        }
      }

      return modules
    },
    pageTitleForDisplay() {
      if (this.pageTitle.useTranslation) {
        return this.$t(this.pageTitle.value)
      }
      return this.pageTitle.value
    },
    changeBackgroundColorForPage() {
      return PAGES_WITH_BACKGROUND_COLOR.includes(this.$route.name)
    },
    routeName() {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions({
      suppressNotification: "shared/suppressNotification"
    }),
    handleSupportRequest(module) {
      if(module.title === SUPPORT_MENU_MODULES[1].title) {
        if (this.isLoggedInUserAdmin) {
          window.open(module.action)
        } else {
          this.$router.push({ name: "support" })
        }
      } else {
        window.open(module.action)
      }
      this.showSupportMenu = false
    },
    breadcrumbForDisplay(item) {
      if (item.useTranslation) {
        return this.$t(item.text)
      }
      return item.text
    },
    handleClickOnAvatar() {
      this.showUserMenu = !this.showUserMenu
    },
    getBackgroundColorForGroupListIcon(module) {
      const routeName         = this.$route.path.split("/")[1]
      const isSubModuleActive = module.items.find(subModule =>
        subModule.action === routeName
      )
      if (this.navigationMini && !isSubModuleActive) {
        return  "grey"
      } else {
        return  "secondary"
      }
    },
    checkForActiveModule(item) {
      return (ACCESS_MANAGEMENT_ROUTES.includes(this.$route.name) && item.title === "1100") ||
      (FORMS_ROUTES.includes(this.$route.name) && item.title === "1101") ||
      (SYSTEM_ROUTES.includes(this.$route.name) && item.title === "1102")
    }
  },
  watch: {
    routeName: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.sidePanelMini = false
        }
      }
    },
    isMediumAndBelowScreen: {
      immediate: true,
      handler  : function(newValue) {
        this.navigationMini = newValue
        this.sidePanelMini  = newValue
      }
    }
  }
}

/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/translation-preferences`

export default {
  /**
   * This action will load translationPreferences.
   * @param {*} context is the store.
   */
  async loadTranslationPreferences(context) {
    context.commit("setLoadingTranslationPreferences", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getTranslationPreferencesResponse = await httpClient.get()

    if (getTranslationPreferencesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setTranslationPreferences", getTranslationPreferencesResponse.data)
    } else {
      // something went wrong while getting translationPreferences
    }

    context.commit("setLoadingTranslationPreferences", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingTranslationPreferences", undefined)
    context.commit("setTranslationPreferences", new Array())
  }
}
import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { GROUP } from "@/constants/bread-crumbs/group"

/**
 * This method will be called before navigating to group page.
 * @param {*} to route to be navigated to.
 */
export const beforeGroup = async to => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.GROUP))
  store.commit("shared/setBreadcrumbs", GROUP(to))
  await store.dispatch("groups/loadGroup", +to.params.id)
  const groups = store.getters["groups/groups"]
  if (!groups.find(group => group.id === +to.params.id)) {
    return "dashboard"
  } else {
    store.dispatch("users/loadUsers")
    store.commit("groups/resetUpdatingGroup")
    store.commit("groups/resetGroupUpdated")
    store.commit("groups/resetGroupUpdateError")
    store.commit("groups/setAddingUsers", undefined)
    store.commit("groups/setRemovingUser", undefined)
  }
}
/**
 * @file It contains vuex translationPreferences module mutations to mutate the state variables.
 */
export default {
  setLoadingTranslationPreferences: (state, loadingTranslationPreferences) => {
    state.loadingTranslationPreferences = loadingTranslationPreferences
  },
  setTranslationPreferences: (state, translationPreferences) => {
    state.translationPreferences = translationPreferences
  }
}
/**
 * @file It contains constants that are used in this application.
 */
import { CookieStorage } from "amazon-cognito-identity-js"
import { format } from "@/utils"

export const HTTP_STATUS_CODE = {
  OK                   : 200,
  OK_WITH_NO_CONTENT   : 204,
  UNAUTHORIZED         : 401,
  FORBIDDEN            : 403,
  NOT_FOUND            : 404,
  METHOD_NOT_ALLOWED   : 405,
  BAD_REQUEST          : 400,
  CONFLICT             : 409,
  FAILED_DEPENDENCY    : 424,
  INTERNAL_SERVER_ERROR: 500
}
export const ISSUE_STATUS = {
  NEW        : 1,
  IN_PROGRESS: 2,
  CLOSED     : 3,
  TO_DO      : 4,
  DONE       : 5
}
export const INTEGER = {
  ZERO: 0
}
export const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
  INFO   : "info",
  ERROR  : "error"
}
export const SPECIAL_CHARACTERS = {
  QUERY: "?"
}
export const REPORT_STATUS = {
  CLOSED: "closed",
  NEW   : "new"
}

export const MESSAGE_STATUS = {
  DRAFT: "draft",
  NEW  : "new",
  SEEN : "seen"
}

export const COOKIE_OPTIONS = {
  expires : null,
  domain  : window.location.host.split(":")[0],
  secure  : true,
  path    : "/",
  sameSite: "lax"
}

export const COGNITO_USER_POOL_DATA = (UserPoolId, ClientId) => ({
  UserPoolId: UserPoolId,
  ClientId  : ClientId,
  Storage   : new CookieStorage(COOKIE_OPTIONS)
})

export const MFA = {
  SUCCESS       : "SUCCESS",
  TOTP_PREFERRED: "SOFTWARE_TOKEN_MFA",
  TOTP_URL      : "otpauth://totp/{0}?{1}"
}

export const ERROR = {
  DUPLICATE: "duplicate"
}

export const COGNITO_AUTHENTICATION_MESSAGE = {
  EXCEEDED_ATTEMPTS    : "Password attempts exceeded",
  NEW_PASSWORD_REQUIRED: "New Password Required",
  INVALID_CREDENTIALS  : "Incorrect username or password.",
  USER_DISABLED        : "User is disabled."
}

export const EXCEPTION_STATUS = {
  OPEN    : "Open",
  APPROVED: "Approved",
  DECLINED: "Declined"
}

export const REPORT_ANONYMISATION_STATUS = {
  DONE         : "done",
  SCHEDULED    : "scheduled",
  NOT_SCHEDULED: null
}

export const TRANSLATION_PREFERENCES = {
  NO_TRANSLATION   : 1,
  MACHINE_PREFERRED: 2,
  MACHINE_ONLY     : 3,
  HUMAN_ONLY       : 4,
  HUMAN_ADDED      : 5
}

export const REPORT_SOURCE = {
  APP  : "app",
  PHONE: "phone",
  WEB  : "web"
}

export const IDP_LOGIN_URL = (domainUrl, clientUserPoolClientId, redirectUrl, verificationCode) => `${domainUrl}/authorize?identity_provider=default&response_type=code&client_id=${clientUserPoolClientId}&redirect_uri=${redirectUrl}&state=${verificationCode}&scope=email profile openid`
export const IDP_LOGOUT_URL = (domainUrl, clientUserPoolClientId, redirectUrl) => `${domainUrl}/logout?client_id=${clientUserPoolClientId}&logout_uri=${redirectUrl}`

export const SPEAKUP_ISSUE_ACKNOWLEDGEMENT = {
  DATE_OF_RECEIPT                          : "date_of_receipt",
  DATE_OF_FIRST_REPLY_AVAILABLE_TO_REPORTER: "date_of_first_reply_available_to_reporter"
}

export const CONFIGURATIONS = {
  ASK_ORGANISATION_CODE_FOR_WEB: "ASK_ORGANISATION_CODE_FOR_WEB",
  TRANSLATION                  : "TRANSLATION",
  SPEAKUP_ISSUE_ACKNOWLEDGEMENT: "SPEAKUP_ISSUE_ACKNOWLEDGEMENT",
  CLIENT_USER_POOL_ID          : "CLIENT_USER_POOL_ID",
  SSO                          : "SSO",
  DEFAULT_SSO                  : "DEFAULT_SSO",
  ID                           : "ID",
  AI_ANONYMISATION             : "AI_ANONYMISATION"
}

export const SSO_STATE = {
  CONFIGURED: "configured",
  ENABLED   : "enabled"
}

export const ISSUE_DOCUMENT_SIZE_LIMIT_IN_BYTES = 2147483648
export const TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES = 524288000

export const PAGES_WITH_BACKGROUND_COLOR = [
  "dashboard",
  "analytics",
  "users",
  "groups",
  "domains",
  "domain",
  "channels",
  "issue",
  "issues",
  "roles",
  "exceptions",
  "export",
  "logs",
  "configurations",
  "option-lists",
  "option-list",
  "reporter-intake-forms",
  "fields",
  "field",
  "reporter-intake-form",
  "reporter-intake-form-template-configuration",
  "issue-forms",
  "issue-form",
  "issue-form-field-configuration",
  "issue-fields",
  "issue-field",
  "issue-add",
  "task",
  "user-settings"
]

export const VALIDATION_ERROR = {
  FIELD: {
    NAME               : "name",
    SYSTEM_NAME        : "systemName",
    NAME_OPTION_LIST_ID: "name, optionListId"
  },
  TYPE: {
    DUPLICATE: "duplicate"
  }
}

export const FORMS = {
  ISSUE : "ISSUE_FORM",
  REPORT: "INTAKE_FORM"
}

const DOCUMENT_TYPES = {
  PDF          : "application/pdf",
  RTF          : "application/rtf",
  DOC          : "application/msword",
  XLS          : "application/vnd.ms-excel",
  PPT          : "application/vnd.ms-powerpoint",
  DOCX         : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLSX         : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPTX         : "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  GIF          : "image/gif",
  PNG          : "image/png",
  JPG          : "image/jpeg",
  JPEG         : "image/jpeg",
  MP3          : "audio/mpeg",
  MP4          : "video/mp4",
  XWAV         : "audio/x-wav",
  WAV          : "audio/wav",
  WMV          : "audio/x-ms-wmv",
  MPEG         : "video/mpeg",
  MPG          : "video/mpeg",
  MPE          : "video/mpeg",
  MOV          : "video/quicktime",
  MSG          : "application/vnd.ms-outlook",
  MSG_EXTENSION: ".msg",
  AVI          : "video/x-msvideo",
  DOT          : "application/msword",
  XML          : "text/xml",
  DOTX         : "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  CSV          : "text/csv",
  HTML         : "text/html",
  HTM          : "text/html",
  TPL          : "text/html",
  XLW          : "application/vnd.ms-excel",
  XLT          : "application/vnd.ms-excel",
  XLTX         : "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  PPS          : "application/vnd.ms-powerpoint",
  POT          : "application/vnd.ms-powerpoint",
  POTX         : "application/vnd.openxmlformats-officedocument.presentationml.template",
  BMP          : "image/bmp",
  DXF          : "application/dxf",
  TIF          : "image/tif",
  TIFF         : "image/tiff",
  ODT          : "application/vnd.oasis.opendocument.text",
  OTH          : "application/vnd.oasis.opendocument.text-web",
  ODM          : "application/vnd.oasis.opendocument.text-master",
  ODS          : "application/vnd.oasis.opendocument.spreadsheet",
  OTS          : "application/vnd.oasis.opendocument.spreadsheet-template",
  ODP          : "application/vnd.oasis.opendocument.presentation",
  ODG          : "application/vnd.oasis.opendocument.graphics",
  OTP          : "application/vnd.oasis.opendocument.presentation-template",
  RTF_TEXT     : "text/rtf",
  WMV_VIDEO    : "video/x-ms-wmv",
  AVI_VIDEO    : "video/avi",
  X_DXF        : "application/x-dxf",
  EML          : "message/rfc822"
}

export const ISSUE_DOCUMENTS_TYPES = {
  ...DOCUMENT_TYPES
}

export const UNSUPPORTED_MIME_TYPE = {
  MSG: "application/vnd.ms-outlook"
}

export const TRANSLATION_FILE_UPLOAD_TYPE = {
  XLSX: DOCUMENT_TYPES.XLSX
}

export const ROLE_ASSIGNMENT_CATEGORY = {
  USER : "user",
  GROUP: "group"
}

export const ISSUE_ACCESS_UPDATE_ROLES = [2]

export const ISSUE_ROLES = [2, 3, 4]

export const FILE_NAME = {
  GET_ANALYTICS_EXPORT: date => `speakup-analytics-${date}.xlsx`,
  ALL_DATA_EXPORT_ZIP : "all-data-export.zip",
  ISSUES_EXPORT       : "issues-export.xlsx"
}

export const DATA_EXPORT_STATUS = {
  INITIATED: "initiated",
  COMPLETED: "completed",
  FAILED   : "failed"
}

export const TRANSLATION_UPLOAD_STATUS = {
  INITIATED: "initiated",
  SUCCESS  : "success",
  FAILURE  : "failure"
}

export const OPTION_LIST_EXPORT_STATUS = {
  INITIATED: "initiated",
  COMPLETED: "completed",
  FAILED   : "failed"
}

export const ISSUE_SEARCH_STATUS = {
  INITIATED: "initiated",
  COMPLETED: "completed"
}

export const ITEM_COMPARISON = {
  FIRST_ITEM_IS_HIGHER: 1,
  FIRST_ITEM_IS_LOWER : -1
}

export const DATA_EXPORT_TYPE = {
  ALL                        : "all",
  ANALYTICS                  : "analytics",
  ISSUES_WITH_ALL_FIELDS     : "issues with all fields",
  ISSUES_WITH_SELECTED_FIELDS: "issues with selected fields"
}

export const DATA_EXPORT_POLL_INTERVAL = 20000

export const ISSUES_DATA_EXPORT_POLL_INTERVAL = 5000

export const FIELD_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL = 10000

export const ISSUE_SEARCH_POLL_INTERVAL = 1000

export const BOOLEAN = {
  TRUE : true,
  FALSE: false
}

export const STRING = {
  TRUE: "true"
}

export const ARRAY = {
  FIRST: 0
}

export const WIDGET = {
  AUTOCOMPLETE     : "autocomplete",
  TEXTAREA         : "textarea",
  RADIO_GROUP      : "radio group",
  DATE_TIME_PICKER : "date time picker",
  NUMBER_TEXT_FIELD: "number text field",
  TEXT_FIELD       : "text field",
  DATE_PICKER      : "date picker"
}

export const NULL = null

export const ISSUE_TYPES = [{
  id  : 1,
  name: "Case"
}, {
  id  : 2,
  name: "Task"
}]

export const DATA_RETENTION_RULES = {
  GENERAL: ["Summary", "Description", "Issue documents", "Comments"],
  REPORT : ["Message content", "Report documents"],
  FIELD  : "Field"
}

export const AI_ANONYMISE_ALLOWED_DATA_RETENTION_RULES = {
  GENERAL: ["Summary", "Description", "Comments"],
  REPORT : ["Message content"],
  FIELD  : {
    TYPE: ["short text", "long text"]
  }
}

export const DATA_RETENTION_RULES_TO_DISPLAY_TOOLTIP = {
  MESSAGE_CONTENT: "Message content"
}

export const DATA_RETENTION_OPTIONS = ["Keep", "Clear"]

export const TABLE_NAMES = {
  REPORTER_FORM_TEMPLATES       : "REPORTER_FORM_TEMPLATES",
  OPTION_LISTS                  : "OPTION_LISTS",
  FIELDS                        : "FIELDS",
  FORM_TEMPLATE_FIELDS          : "FORM_TEMPLATE_FIELDS",
  FORM_TEMPLATE_ADD_FIELDS      : "FORM_TEMPLATE_ADD_FIELDS",
  CHANNEL_FORM_TEMPLATES        : "CHANNEL_FORM_TEMPLATES",
  USERS                         : "USERS",
  DOMAIN_CHANNELS               : "DOMAIN_CHANNELS",
  GROUP_USERS                   : "GROUP_USERS",
  AUTOMATION_RULES              : "AUTOMATION_RULES",
  AUTOMATIONS_LEADING_TO_DOMAIN : "AUTOMATIONS_LEADING_TO_DOMAIN",
  USERS_WITH_ACCESS             : "USERS_WITH_ACCESS",
  GROUPS_WITH_ACCESS            : "GROUPS_WITH_ACCESS",
  ISSUE_FORMS                   : "ISSUE_FORMS",
  ISSUE_FORM_FIELDS             : "ISSUE_FORM_FIELDS",
  ISSUE_FORM_TEMPLATE_ADD_FIELDS: "ISSUE_FORM_TEMPLATE_ADD_FIELDS",
  ISSUE_FORM_TEMPLATES          : "ISSUE_FORM_TEMPLATES",
  ISSUE_ADD_CUSTOM_FIELDS       : "ISSUE_ADD_CUSTOM_FIELDS",
  ISSUE_FIELDS                  : "ISSUE_FIELDS"
}

export const defaultTableHeaderClasses = "subheading grey--text grey lighten-4 subtitle-2 font-weight-bold"
export const defaultTableFooterOptions = {
  itemsPerPageOptions: [10, 25, 50],
  itemsPerPageText   : "107",
  showFirstLastPage  : true,
  showCurrentPage    : true
}

export const POLICY_NAMES = {
  ISSUE_VIEW                      : "Issue view",
  REPORT_VIEW                     : "Report view",
  MESSAGE_VIEW                    : "Message view",
  MESSAGE_ITEM_VIEW               : "MessageItem view",
  MESSAGE_ITEM_MACHINE_TRANSLATE  : "MessageItem machine translate",
  MESSAGE_ITEM_REMOVE             : "MessageItem remove",
  MESSAGE_ADD                     : "Message add",
  REPORT_ADD_MESSAGES             : "Report add messages",
  MESSAGE_ITEM_ADD                : "MessageItem add",
  MESSAGE_ADD_ITEMS               : "Message add items",
  MESSAGE_ITEM_VIEW_UPLOAD_URL    : "MessageItem view upload url",
  MESSAGE_ITEM_VIEW_DOWNLOAD_URL  : "MessageItem view download url",
  MESSAGE_UPDATE                  : "Message update",
  MESSAGE_ITEM_UPDATE             : "MessageItem update",
  DOMAIN_VIEW                     : "Domain view",
  CHANNEL_VIEW                    : "Channel view",
  CHANNEL_VIEW_LOGO               : "Channel view logo",
  CHANNEL_ADD_LOGO                : "Channel add logo",
  CHANNEL_UPDATE                  : "Channel update",
  CONFIGURATION_VIEW              : "Configuration view",
  TRANSLATION_PREFERENCE_VIEW     : "TranslationPreference view",
  CONFIGURATION_UPDATE            : "Configuration update",
  USER_VIEW                       : "User view",
  USER_ADD                        : "User add",
  USER_UPDATE                     : "User update",
  GROUP_VIEW                      : "Group view",
  GROUP_ADD                       : "Group add",
  GROUP_UPDATE                    : "Group update",
  GROUP_REMOVE                    : "Group remove",
  GROUP_ADD_USERS                 : "Group add users",
  USER_ADD_GROUPS                 : "User add groups",
  GROUP_REMOVE_USERS              : "Group remove users",
  USER_REMOVE_GROUPS              : "User remove groups",
  DOMAIN_ADD                      : "Domain add",
  DOMAIN_UPDATE                   : "Domain update",
  CHANNEL_ADD                     : "Channel add",
  DOMAIN_ADD_CHANNELS             : "Domain add channels",
  POLICY_VIEW                     : "Policy view",
  ORGANISATION_CODE_VIEW          : "OrganisationCode view",
  ROLE_VIEW                       : "Role view",
  USER_ROLE_VIEW                  : "UserRole view",
  USER_ROLE_ADD                   : "UserRole add",
  USER_ADD_ROLES                  : "User add roles",
  ROLE_ADD_USERS                  : "Role add users",
  DOMAIN_ADD_USER_ROLES           : "Domain add user roles",
  GROUP_ROLE_VIEW                 : "GroupRole view",
  GROUP_ROLE_ADD                  : "GroupRole add",
  GROUP_ADD_ROLES                 : "Group add roles",
  ROLE_ADD_GROUPS                 : "Role add groups",
  DOMAIN_ADD_GROUP_ROLES          : "Domain add group roles",
  FORM_VIEW                       : "Form view",
  CHANNEL_VIEW_FORMS              : "Channel view forms",
  CHANNEL_ADD_FORMS               : "Channel add forms",
  CHANNEL_REMOVE_FORMS            : "Channel remove forms",
  TAXONOMY_VIEW                   : "Taxonomy view",
  ISSUE_TYPE_VIEW                 : "IssueType view",
  USER_VIEW_GROUPS                : "User view groups",
  ROLE_VIEW_POLICIES              : "Role view policies",
  REPORT_ADD                      : "Report add",
  CHANNEL_ADD_REPORTS             : "Channel add reports",
  REPORT_UPDATE                   : "Report update",
  REPORT_SEND_LOGIN               : "Report send login",
  ISSUE_STATUS_VIEW               : "IssueStatus view",
  ISSUE_RESOLUTION_VIEW           : "IssueResolution view",
  MESSAGE_REMOVE                  : "Message remove",
  REPORT_VIEW_FORM_FIELD_VALUES   : "Report view form field values",
  ISSUE_UPDATE                    : "Issue update",
  DOMAIN_ADD_ISSUES               : "Domain add issues",
  USER_ADD_ISSUES                 : "User add issues",
  REPORT_UPDATE_FORM_FIELD_VALUES : "Report update form field values",
  LANGUAGE_VIEW                   : "Language view",
  ACCESS_VIEW                     : "Access view",
  ACCESS_ADD                      : "Access add",
  DOMAIN_ADD_ROLES                : "Domain add roles",
  ISSUE_ADD_ROLES                 : "Issue add roles",
  TRANSLATION_UPDATE              : "Translation update",
  TRANSLATION_VIEW                : "Translation view",
  TRANSLATION_CONFIGURATION_UPDATE: "Channel update translation configuration",
  ROLE_ADD_ACCESSES               : "Role add access",
  ISSUE_ADD_ACCESSES              : "Issue add access",
  USER_SEND_PASSWORD              : "User send password",
  ACCESS_REMOVE                   : "Access remove",
  SUPPORT_ADD                     : "Support add",
  EXCEPTION_VIEW                  : "Exception view",
  EXCEPTION_UPDATE                : "Exception update",
  MESSAGE_ITEM_HUMAN_TRANSLATE    : "MessageItem human translate",
  TRANSLATION_ADD                 : "Translation add",
  MESSAGE_ITEM_ADD_TRANSLATIONS   : "MessageItem add translations",
  TRANSLATION_CONFIGURATION_VIEW  : "Channel view translation configuration",
  GROUP_VIEW_USERS                : "Group view users",
  USER_REMOVE                     : "User remove",
  LOG_VIEW                        : "Log view",
  ISSUE_VIEW_LOGS                 : "Issue view logs",
  ISSUE_ADD_COMMENT               : "Issue add comment",
  ISSUE_VIEW_COMMENTS             : "Issue view comments",
  LABEL_VIEW                      : "Label view",
  ISSUE_REMOVE_COMMENT            : "Issue remove comment",
  ISSUE_COMMENT_REMOVE            : "IssueComment remove",
  FIELD_VIEW                      : "Field view",
  FORM_VIEW_FIELDS                : "Form view fields",
  CHANNEL_REMOVE_LOGO             : "Channel remove logo"
}

export const SSO_CONFIGURATIONS = {
  IDENTIFIER                      : "identifier",
  URN_AMAZON_COGNITO_SP           : "urn:amazon:cognito:sp:",
  REGION                          : "eu-central-1",
  AUTH                            : "auth",
  AMAZONCOGNITO_SAML_2_IDPRESPONSE: "amazoncognito.com/saml2/idpresponse"
}

export const AUTOMATION_EVENT = {
  SPEAK_UP_ISSUE_CREATE: "Speak up issue create"
}
export const AUTOMATION_RULE_NAME = {
  DOMAIN_CHANGE: "Domain change"
}
export const MAX_CHARACTER_LIMIT = {
  REPORTER_FORM_TEMPLATE_NAME  : 128,
  OPTION_LIST_NAME             : 128,
  OPTION_LIST_ITEM_NAME        : 128,
  ISSUE_FORM_NAME              : 128,
  FIELD_LABEL                  : 128,
  FIELD_SYSTEM_NAME            : 56,
  ISSUE_FORM_SHORT_TEXT_FIELD  : 128,
  ISSUE_FORM_LONG_TEXT_FIELD   : 50000,
  ISSUE_FIELDS_SHORT_TEXT_FIELD: 128,
  ISSUE_DESCRIPTION            : 50000
}

export const QUERY_OPERAND = {
  OR: "OR"
}

export const FIELD_TYPES = {
  DATE: {
    value : "date",
    name  : "999",
    icon  : "mdi-calendar-outline",
    widget: WIDGET.DATE_PICKER
  },
  DATE_TIME: {
    value : "date time",
    name  : "1214",
    icon  : "mdi-calendar-clock-outline",
    widget: WIDGET.DATE_TIME_PICKER
  },
  NUMBER: {
    value : "number",
    name  : "682",
    icon  : "mdi-numeric-1-box",
    widget: WIDGET.NUMBER_TEXT_FIELD
  },
  OPTION_LIST: {
    value : "option list",
    name  : "817",
    icon  : "mdi-format-list-bulleted",
    widget: WIDGET.AUTOCOMPLETE
  },
  MULTIPLE_OPTION_LIST: {
    value : "multiple option list",
    name  : "1072",
    icon  : "mdi-format-list-bulleted",
    widget: WIDGET.AUTOCOMPLETE
  },
  LONG_TEXT: {
    value : "long text",
    name  : "962",
    icon  : "mdi-text-long",
    widget: WIDGET.TEXTAREA
  },
  SHORT_TEXT: {
    value : "short text",
    name  : "797",
    icon  : "mdi-text-short",
    widget: WIDGET.TEXT_FIELD
  },
  BOOLEAN: {
    value : "boolean",
    name  : "795",
    icon  : "mdi-circle-half-full",
    widget: WIDGET.RADIO_GROUP
  }
}

const STATUS_CLASSES = [{
  status: ["New", "To do"],
  class : "info lighten-4 primary--text"
}, {
  status: ["In progress"],
  class : "primary lighten-5 primary--text text--darken-2 text--base"
}, {
  status: ["Closed", "Done"],
  class : "success darken1 white--text"
}]

export const STATUS_CLASS_MAPPING = {
  1: STATUS_CLASSES[0].class,
  2: STATUS_CLASSES[1].class,
  3: STATUS_CLASSES[2].class,
  4: STATUS_CLASSES[0].class,
  5: STATUS_CLASSES[2].class
}

export const ALLOWED_TYPES_FOR_ISSUE_FIELDS = [
  FIELD_TYPES.OPTION_LIST.value,
  FIELD_TYPES.SHORT_TEXT.value,
  FIELD_TYPES.DATE_TIME.value,
  FIELD_TYPES.BOOLEAN.value,
  FIELD_TYPES.NUMBER.value,
  FIELD_TYPES.DATE.value,
  FIELD_TYPES.MULTIPLE_OPTION_LIST.value
]

export const GET_CHANNEL_URL = (clientName, channelName) =>
  format(process.env.VUE_APP_REPORTER_URL, clientName, channelName)

export const ACCESS_MANAGEMENT_ROUTES = ["users", "groups", "roles", "domains", "channels", "user", "group", "domain", "domain-channel", "channel", "user-add"]
export const FORMS_ROUTES = ["option-lists", "fields", "reporter-intake-forms", "issue-forms", "option-list", "field", "reporter-intake-form", "issue-form", "issue-form-field-configuration", "reporter-intake-form-template-configuration"]
export const SYSTEM_ROUTES = ["configurations", "export", "exceptions", "logs"]

export const OPTION_LIST_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL = 20000
export const OPTION_LIST_EXPORT_POLL_INTERVAL = 20000
export const DEFAULT_ISSUES_COLUMNS = ["summary", "domain", "status", "assignee", "labels", "lastUpdated"]

export const DEFAULT_DATA_RETENTION_PERIODS = [30, 182, 365, 730, 1825, 2557, 3652]

export const DATA_RETENTION_STATUS = {
  SCHEDULED    : "scheduled",
  NOT_SCHEDULED: null,
  ANONYMISED   : "anonymised"
}

export const NOTIFICATIONS = {
  NEW_REPORT                : 4,
  NEW_MESSAGE               : 5,
  ISSUE_INVITATION          : 6,
  ISSUE_INVITATION_REMOVED  : 7,
  ISSUE_ASSIGNED            : 8,
  NEW_ISSUE_CREATED         : 9,
  REDO_TRANSLATION_COMPLETED: 11,
  NEW_COMMENT               : 13,
  HUMAN_TRANSLATION_JOB_DONE: 19,
  DATA_EXPORT_CREATED       : 20
}

export const DEFAULT_USER_EMAIL_NOTIFICATIONS = {
  REPORT: [
    NOTIFICATIONS.NEW_REPORT,
    NOTIFICATIONS.NEW_MESSAGE,
    NOTIFICATIONS.REDO_TRANSLATION_COMPLETED,
    NOTIFICATIONS.HUMAN_TRANSLATION_JOB_DONE
  ],
  ISSUE: [
    NOTIFICATIONS.NEW_ISSUE_CREATED,
    NOTIFICATIONS.ISSUE_INVITATION,
    NOTIFICATIONS.ISSUE_INVITATION_REMOVED,
    NOTIFICATIONS.ISSUE_ASSIGNED,
    NOTIFICATIONS.NEW_COMMENT
  ],
  SYSTEM_WIDE: [NOTIFICATIONS.DATA_EXPORT_CREATED]
}

export const ANCHOR_LINKS = {
  WEB_AND_APP                       : "#web-and-app",
  REPORTER_INTAKE_FORM              : "#reporter-intake-form",
  TRANSLATION_AND_LANGUAGE          : "#translation-and-language",
  GENERAL                           : "#general",
  ACCESS                            : "#access",
  SECURITY                          : "#security",
  DATA_RETENTION                    : "#data-retention",
  CHANNELS                          : "#channels",
  DOMAIN_ACCESS                     : "#domain-access",
  AUTOMATIONS_LEADING_TO_THIS_DOMAIN: "#automations-leading-to-this-domain",
  PASSWORD                          : "#password",
  NOTIFICATIONS                     : "#notifications",
  SHARE_POINT_INTEGRATION           : "#share-point-integration",
  ALL_DATA_EXPORT                   : "#all-data-export"
}

export const PAGES = {
  CHANNEL       : [ANCHOR_LINKS.WEB_AND_APP, ANCHOR_LINKS.REPORTER_INTAKE_FORM, ANCHOR_LINKS.TRANSLATION_AND_LANGUAGE],
  CONFIGURATIONS: [ANCHOR_LINKS.GENERAL, ANCHOR_LINKS.ACCESS, ANCHOR_LINKS.SECURITY, ANCHOR_LINKS.DATA_RETENTION],
  USER_SETTINGS : [ANCHOR_LINKS.PASSWORD, ANCHOR_LINKS.NOTIFICATIONS],
  EXPORTS       : [ANCHOR_LINKS.SHARE_POINT_INTEGRATION, ANCHOR_LINKS.ALL_DATA_EXPORT],
  DOMAIN        : [ANCHOR_LINKS.CHANNELS, ANCHOR_LINKS.DOMAIN_ACCESS, ANCHOR_LINKS.AUTOMATIONS_LEADING_TO_THIS_DOMAIN]
}

export default {
  APPLICATION_ID          : 20002,
  SAVED_TIMEOUT           : 5000,
  NOTIFICATION_TIMEOUT    : 5000,
  MILLISECONDS_IN_A_SECOND: 1000,
  MILLISECONDS_IN_A_MINUTE: 60000,
  ISSUE_ROLES,
  MESSAGE_ITEM_TYPE       : {
    TEXT: "text",
    PDF : "application/pdf"
  },
  LANGUAGE: {
    CLIENT_DEFAULT: {
      id       : 18,
      name     : "English",
      shortName: "en-GB"
    }
  },
  ISSUE_TYPES,
  ALLOWED_TYPES_FOR_ISSUE_FIELDS,
  WIDGET,
  ISSUE_SEARCH_FILTERS: {
    SOURCE             : ["Source", "report[0].source"],
    DOMAIN             : ["Domain", "domainId"],
    ASSIGNEE           : ["Assignee", "assigneeId"],
    STATUS             : ["Status", "statusId"],
    TYPE               : ["Type", "typeId"],
    RESOLUTION         : ["Resolution", "resolutionId"],
    DATE_RANGE         : ["Date range", "createdAt"],
    CHANNEL            : ["Channel", "report[0].channelId"],
    REPORT_STATUS      : ["Report status", "report[0].status"],
    ISSUE_ANONYMISATION: ["Anonymised issue", "dataRetentionStatus"],
    LABEL              : ["Label", "labels"],
    FORM_TEMPLATE      : "report[0].formInstances[0].formInstanceFields",
    LANGUAGE           : ["Reporter language", "report[0].languageId"],
    ISSUE_FIELD        : "issueFieldValues"
  },
  ROLE_ASSIGNMENT_CATEGORY,
  HTTP_STATUS_CODE,
  ISSUE_STATUS,
  NOTIFICATION_TYPE,
  SPECIAL_CHARACTERS,
  REPORT_STATUS,
  MESSAGE_STATUS,
  TRANSLATION_PREFERENCES,
  COOKIE_OPTIONS,
  MFA,
  COGNITO_AUTHENTICATION_MESSAGE,
  ERROR,
  EXCEPTION_STATUS,
  REPORT_SOURCE,
  DATE: {
    MILLISECONDS_IN_ONE_DAY: 1000 * 60 * 60 * 24
  },
  DAYS_WITHIN_EXCEPTION_TO_BE_APPROVED: 7,
  SPEAKUP_ISSUE_ACKNOWLEDGEMENT,
  CONFIGURATIONS,
  ISSUE_DOCUMENTS_TYPES,
  REPORT_ANONYMISATION_STATUS,
  FILE_NAME,
  ITEM_COMPARISON,
  DATA_EXPORT_TYPE,
  BOOLEAN,
  NULL,
  IDP_LOGIN_URL,
  FORMS,
  FIELD_TYPES,
  DEFAULT_ISSUES_COLUMNS,
  INTEGER,
  DEFAULT_DATA_RETENTION_PERIODS,
  DATA_RETENTION_STATUS,
  ANCHOR_LINKS,
  PAGES
}

import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"

export const FIELDS = {
  headers: [{
    text : "743",
    align: "left",
    value: "id",
    class: defaultTableHeaderClasses
  },
  {
    text : "744",
    align: "left",
    value: "systemName",
    class: defaultTableHeaderClasses
  },
  {
    text : "745",
    align: "left",
    value: "fieldType",
    class: defaultTableHeaderClasses
  },
  {
    align: "left",
    value: "actions",
    class: defaultTableHeaderClasses
  }],
  itemsPerPage: 10,
  footer      : defaultTableFooterOptions
}
export const DOMAIN_CHANNEL = route => ([
  {
    text          : "59",
    exact         : true,
    to            : "/domains",
    useTranslation: true
  },
  {
    text          : route.params.domainId,
    exact         : true,
    to            : `/domains/${route.params.domainId}`,
    useTranslation: false
  },
  {
    text          : "77",
    to            : `/domains/${route.params.domainId}`,
    useTranslation: true
  },
  {
    text          : route.params.id,
    to            : `/domains/${route.params.domainId}/channels/${route.params.id}`,
    useTranslation: false
  }
])
import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"

export const DOMAINS =  {
  headers: [{
    text : "60",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  }, {
    text : "61",
    align: "left",
    value: "archived",
    class: defaultTableHeaderClasses
  }],
  loadingText : "62",
  noDataText  : "63",
  noResultText: "64",
  itemsPerPage: 10,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "65"
  }
}
/**
 * @file It contains vuex translations module mutations to mutate the state variables.
 */
export default {
  setLoadingTranslations: (state, loadingTranslations) => {
    state.loadingTranslations = loadingTranslations
  },
  setAddingTranslation: (state, addingTranslation) => {
    state.addingTranslation = addingTranslation
  },
  setTranslationAdded: (state, translationAdded) => {
    state.translationAdded = translationAdded
  },
  setTranslations: (state, translations) => {
    state.translations = translations
  },
  setTranslationAddError: (state, translationAddError) => {
    state.translationAddError = translationAddError
  },
  updateTranslations: (state, translations) => {
    for (const translation of translations) {
      const index = state.translations.findIndex(stateTranslation => stateTranslation?.id === translation.id)
      if (index >= 0) {
        state.translations.splice(index, 1, { ...state.translations[index], ...translation })
      } else {
        state.translations.push(translation)
      }
    }
  },
  setUpdatingTranslation: (state, properties) => {
    for (const property of properties) {
      state.updatingTranslation[property] = true
    }
  },
  resetUpdatingTranslation: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingTranslation[property] = false
      }
    } else {
      Object.keys(state.updatingTranslation).forEach(property => state.updatingTranslation[property] = false)
    }
  },
  setTranslationUpdated: (state, properties) => {
    for (const property of properties) {
      state.translationUpdated[property] = true
      setTimeout(() => {
        state.translationUpdated[property] = false
      }, 5000)
    }
  },
  resetTranslationUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.translationUpdated[property] = false
      }
    } else {
      Object.keys(state.translationUpdated).forEach(property => state.translationUpdated[property] = false)
    }
  },
  setTranslationUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.translationUpdateError[property] = error
    }
  },
  resetTranslationUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.translationUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.translationUpdateError).forEach(property => state.translationUpdateError[property] = undefined)
    }
  }
}
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import store from "@/plugins/vuex"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/issue-links`

export default {
  /**
   * This action will load issue links.
   * @param {*} context it is the store
   * @param {*} payload it is the filter criteria
   */
  async loadIssueLinks(context, payload) {
    context.commit("setLoadingIssueLinks", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getIssueLinksResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIssueLinksResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateIssueLinks", getIssueLinksResponse.data)
      } else {
        context.commit("setIssueLinks", getIssueLinksResponse.data)
      }
    }

    context.commit("setLoadingIssueLinks", false)
  },

  /**
   * This action adds links to a issue.
   * @param {*} context is the store.
   */
  async addIssueLinks(context, payload) {
    context.commit("setAddingIssueLinks", true)
    context.commit("setIssueLinksAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const addIssueLinksResponse = await httpClient.post(undefined, payload)

    if (addIssueLinksResponse.status === HTTP_STATUS_CODE.OK) {
      const idsOfIssueLinks = addIssueLinksResponse.data.map(addIssueLink => addIssueLink.id)
      if (idsOfIssueLinks.length) {
        store.dispatch("accessControl/loadLoggedInUserAccess", [{
          policies   : ["IssueLink remove"],
          resourceIds: idsOfIssueLinks
        }]).then(() => {
          context.commit("updateIssueLinks", addIssueLinksResponse.data)
        })
      }
      context.commit("setIssueLinksAdded", true)
    }

    context.commit("setAddingIssueLinks", false)
  },

  /**
   * This action removes a issue link.
   * @param {*} context is the store.
   * @param {*} payload contains id of the access.
   */
  async removeIssueLink(context, linkedIssue) {
    context.commit("setIssueLinkRemoved", false)
    context.commit("setRemovingIssueLink", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const deleteIssueLinkResponse = await httpClient.delete(`/${linkedIssue.id}`)

    if (deleteIssueLinkResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setIssueLinkRemoved", true)
      context.commit("removeIssueLink", linkedIssue)
    }

    context.commit("setRemovingIssueLink", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setIssueLinks", new Array())
    context.commit("setLoadingIssueLinks", undefined)
    context.commit("setAddingIssueLinks", false)
    context.commit("setIssueLinksAdded", false)
    context.commit("setRemovingIssueLink", undefined)
    context.commit("setIssueLinkRemoved", undefined)
  }
}
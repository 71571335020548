/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/accesses`

export default {
  /**
   * This action will load accesses.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load accesses.
   */
  async loadAccesses(context, payload) {
    context.commit("setLoadingAccesses", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getAccessesResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getAccessesResponse.status === HTTP_STATUS_CODE.OK && getAccessesResponse.data.length) {
      if (payload) {
        context.commit("updateAccesses", getAccessesResponse.data)
      } else {
        context.commit("setAccesses", getAccessesResponse.data)
      }
    } else {
      // something went wrong while getting accesses
    }

    context.commit("setLoadingAccesses", false)
  },

  /**
   * This action will add a access.
   * @param {*} context is the store.
   */
  async addAccess(context, payload) {
    context.commit("setAddingAccess", true)
    context.commit("setAccessAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postAccessResponse = await httpClient.post(undefined, payload)

    if (postAccessResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateAccesses", [postAccessResponse.data])
      context.commit("setAccessAdded", true)
    } else if (postAccessResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setAccessAddError", postAccessResponse.data)
    } else {
      // something went wrong while adding a access
    }

    context.commit("setAddingAccess", false)
  },

  /**
   * This action removes a particular access.
   * @param {*} context is the store.
   * @param {*} payload contains id of the access.
   */
  async removeAccess(context, payload) {
    context.commit("setRemovingAccess", true)
    context.commit("setAccessRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const deleteAccessResponse = await httpClient.delete(`/${payload}`)

    if (deleteAccessResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setAccessRemoved", true)
      context.commit("removeAccess", payload)
    } else {
      // something went wrong while updating an access
    }

    context.commit("setRemovingAccess", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setAccesses", new Array())
    context.commit("setLoadingAccesses", undefined)
    context.commit("setAddingAccess", undefined)
    context.commit("setRemovingAccess", undefined)
    context.commit("setAccessRemoved", undefined)
    context.commit("setAccessAdded", undefined)
  }
}
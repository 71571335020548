import store from "@/plugins/vuex"

/**
 * This method will be called before navigating to login page.
 * @param {*} to route to be navigated to.
 */
export const beforeResetPassword = async to => {
  if (!to.query.code || !to.query.user) {
    return "login"
  }
  store.commit("auth/setResettingPassword", false)
  store.commit("auth/setPasswordReset", false)
}

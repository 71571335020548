/**
 * @file It contains vuex group roles module getter methods to access store variables.
 */
export default {
  isLoadingAccesses: state => state.loadingAccesses,
  accesses         : state => state.accesses,
  isAccessAdded    : state => state.accessAdded,
  isAddingAccess   : state => state.addingAccess,
  isAccessRemoved  : state => state.accessRemoved,
  isRemovingAccess : state => state.removingAccess,
  accessAddError   : state => state.accessAddError
}
import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeFields = async (to, from) => {

  let loadFieldsPromise
  if (!["fields", "field"].includes(from.name)) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.FIELDS))
    store.commit("shared/setBreadcrumbs", undefined)
    loadFieldsPromise = store.dispatch("fields/loadFieldsV2")
    store.dispatch("optionLists/loadOptionLists")
    store.dispatch("dataRetentionRules/loadDataRetentionRules")
    store.dispatch("configurations/loadConfigurations")
  }

  if (loadFieldsPromise && to.name === "field") {
    await loadFieldsPromise
  }

  if (to.name === "field") {
    const fieldsV2 = store.getters["fields/fieldsV2"]
    if (!fieldsV2.find(field => field.id === +to.params.id)) {
      return "fields"
    }
  }
}

export default {
  setLoadingEmailSubscriptions: (state, loadingEmailSubscriptions) => {
    state.loadingEmailSubscriptions = loadingEmailSubscriptions
  },
  setUpdatingEmailSubscription: (state, updatingEmailSubscription) => {
    state.updatingEmailSubscription = updatingEmailSubscription
  },
  setEmailSubscriptionUpdated: (state, emailSubscriptionUpdated) => {
    state.emailSubscriptionUpdated = emailSubscriptionUpdated
  },
  setEmailSubscriptions: (state, emailSubscriptions) => {
    state.emailSubscriptions = emailSubscriptions
  },
  updateEmailSubscriptions: (state, emailSubscriptions) => {
    for (const emailSubscription of emailSubscriptions) {
      const index = state.emailSubscriptions.findIndex(stateEmailSubscription =>
        stateEmailSubscription?.id === emailSubscription.id)
      if (index >= 0) {
        state.emailSubscriptions.splice(index, 1, { ...state.emailSubscriptions[index], ...emailSubscription })
      } else {
        state.emailSubscriptions.push(emailSubscription)
      }
    }
  }
}
export default {
  setLoadingDataRetentionPeriods: (state, loadingDataRetentionPeriods) => {
    state.loadingDataRetentionPeriods = loadingDataRetentionPeriods
  },
  setDataRetentionPeriods: (state, dataRetentionPeriods) => {
    state.dataRetentionPeriods = dataRetentionPeriods
  },
  setAddingDataRetentionPeriod: (state, addingDataRetentionPeriod) => {
    state.addingDataRetentionPeriod = addingDataRetentionPeriod
  },
  setDataRetentionPeriodAdded: (state, dataRetentionPeriodAdded) => {
    state.dataRetentionPeriodAdded = dataRetentionPeriodAdded
  },
  updateDataRetentionPeriods: (state, dataRetentionPeriods) => {
    for (const dataRetentionPeriod of dataRetentionPeriods) {
      const index = state.dataRetentionPeriods.findIndex(stateDataRetentionPeriod =>
        stateDataRetentionPeriod.id === dataRetentionPeriod.id)
      if (index >= 0) {
        state.dataRetentionPeriods.splice(index, 1, { ...state.dataRetentionPeriods[index], ...dataRetentionPeriod })
      } else {
        state.dataRetentionPeriods.push(dataRetentionPeriod)
      }
    }
  },
  setDataRetentionPeriodAddError: (state, dataRetentionPeriodAddError) => {
    state.dataRetentionPeriodAddError = dataRetentionPeriodAddError
  },
  setRemovingDataRetentionPeriod: (state, removingDataRetentionPeriod) => {
    state.removingDataRetentionPeriod = removingDataRetentionPeriod
  },
  setDataRetentionPeriodRemoved: (state, dataRetentionPeriodRemoved) => {
    state.dataRetentionPeriodRemoved = dataRetentionPeriodRemoved
  },
  removeDataRetentionPeriod: (state, dataRetentionPeriod) => {
    const index = state.dataRetentionPeriods.findIndex(stateDataRetentionPeriod =>
      stateDataRetentionPeriod.id === dataRetentionPeriod.id)
    if (index >= 0) {
      state.dataRetentionPeriods.splice(index, 1)
    }
  }
}
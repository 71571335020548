/**
 * @file It contains vuex roles module mutations to mutate the state variables.
 */
export default {
  setLoadingRoles: (state, loadingRoles) => {
    state.loadingRoles = loadingRoles
  },
  setRoles: (state, roles) => {
    state.roles = roles
  },
  updatePoliciesOfRole: (state, { role, policies }) => {
    state.policiesOfRoles = Object.assign({}, state.policiesOfRoles, {
      [role.id]: policies
    })
  },
  resetPoliciesOfRoles: state => {
    state.policiesOfRoles = new Object()
  }
}
/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/exceptions`

export default {
  /**
   * This action will load exceptions.
   * @param {*} context is the store.
   */
  async loadExceptions(context) {
    context.commit("setLoadingExceptions", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getExceptionsResponse = await httpClient.get()

    if (getExceptionsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setExceptions", getExceptionsResponse.data)
    } else {
      // something went wrong while getting exceptions
    }

    context.commit("setLoadingExceptions", false)
  },

  /**
   * This action updates exceptions.
   * @param {*} context is the store.
   * @param {*} exception contains details of specific exception.
   */
  async updateException(context, exception) {
    const id = exception.id
    delete exception.id
    const propertiesToBeUpdated = Object.keys(exception)
    for (const property of propertiesToBeUpdated) {
      if (exception[property] === undefined) {
        exception[property] = null
      }
    }
    context.commit("setUpdatingException", propertiesToBeUpdated)
    context.commit("resetExceptionUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateExceptionResponse = await httpClient.put(`/${id}`, exception)

    if (updateExceptionResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateExceptions", [{
        id,
        ...exception
      }])
      context.commit("setExceptionUpdated", propertiesToBeUpdated)
    } else {
      // something went wrong while updating an exception
    }

    context.commit("resetUpdatingException", propertiesToBeUpdated)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingExceptions", undefined)
    context.commit("setExceptions", new Array())
    context.commit("resetUpdatingException")
    context.commit("resetExceptionUpdated")
  }
}
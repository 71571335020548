/**
 * @file It contains the script for blank layout.
 */
import ThemisNotificationSnackbar from "@/components/shared/notification-snackbar"
import { mapGetters, mapActions } from "vuex"

export default {
  name      : "Blank",
  components: { ThemisNotificationSnackbar },
  computed  : {
    ...mapGetters({
      isPageFreezed: "shared/isPageFreezed",
      isNotified   : "shared/isNotified",
      notification : "shared/notification"
    })
  },
  methods: {
    ...mapActions({
      suppressNotification: "shared/suppressNotification"
    }),
    /**
     * this method handles all the close events.
     * first argument is the caller of this method.
     */
    handleClose() {
      const caller = arguments[1]
      if (caller === "themis_notification_snackbar") {
        this.suppressNotification()
      }
    }
  }
}
/**
 * @file It contains vuex domains module getter methods to access store variables.
 */
export default {
  isLoadingDomains : state => state.loadingDomains,
  domains          : state => state.domains,
  isDomainAdded    : state => state.domainAdded,
  isAddingDomain   : state => state.addingDomain,
  isUpdatingName   : state => state.updatingDomain.name,
  isNameUpdated    : state => state.domainUpdated.name,
  isArchivingDomain: state => state.updatingDomain.archived,
  isDomainArchived : state => state.domainUpdated.archived,
  archivingError   : state => state.domainUpdateError.archived,
  nameUpdateError  : state => state.domainUpdateError.name,
  domainAddError   : state => state.domainAddError
}
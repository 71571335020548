export default {
  setLoadingIssueFields: (state, loadingIssueFields) => {
    state.loadingIssueFields = loadingIssueFields
  },
  setIssueFields: (state, issueFields) => {
    state.issueFields = issueFields
  },
  setAddingIssueFields: (state, addingIssueFields) => {
    state.addingIssueFields = addingIssueFields
  },
  setIssueFieldsAdded: (state, issueFieldsAdded) => {
    state.issueFieldsAdded = issueFieldsAdded
  },
  updateIssueFields: (state, issueFields) => {
    for (const issueField of issueFields) {
      const indexOfIssueField = state.issueFields.findIndex(stateIssueField =>
        stateIssueField.id === issueField.id
      )
      const issueFieldInStore = state.issueFields[indexOfIssueField]

      const issueFieldsToBeUpdated = [{
        index: indexOfIssueField,
        issueField
      }]

      if (issueFieldInStore && issueField.sortingOrder) {
        for (const [index, stateIssueField] of state.issueFields.entries()) {
          if (stateIssueField.id !== issueField.id) {
            if (stateIssueField.sortingOrder < issueFieldInStore.sortingOrder) {
              if (stateIssueField.sortingOrder >= issueField.sortingOrder) {
                issueFieldsToBeUpdated.push({
                  index,
                  issueField: {
                    sortingOrder: stateIssueField.sortingOrder + 1
                  }
                })
              }
            } else if (stateIssueField.sortingOrder > issueFieldInStore.sortingOrder) {
              if (stateIssueField.sortingOrder <= issueField.sortingOrder) {
                issueFieldsToBeUpdated.push({
                  index,
                  issueField: {
                    sortingOrder: stateIssueField.sortingOrder - 1
                  }
                })
              }
            }
          }
        }
      }

      for (const issueFieldToBeUpdated of issueFieldsToBeUpdated) {
        if (issueFieldToBeUpdated.index >= 0) {
          state.issueFields.splice(issueFieldToBeUpdated.index, 1, {
            ...state.issueFields[issueFieldToBeUpdated.index],
            ...issueFieldToBeUpdated.issueField
          })
        } else {
          state.issueFields.push(issueFieldToBeUpdated.issueField)
        }
      }
    }
  },
  setUpdatingIssueField: (state, properties) => {
    for (const property of properties) {
      state.updatingIssueField[property] = true
    }
  },
  setIssueFieldUpdated: (state, properties) => {
    for (const property of properties) {
      state.issueFieldUpdated[property] = true
      setTimeout(() => {
        state.issueFieldUpdated[property] = false
      }, 5000)
    }
  },
  resetIssueFieldUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.issueFieldUpdated[property] = false
      }
    } else {
      Object.keys(state.issueFieldUpdated).forEach(
        property => state.issueFieldUpdated[property] = false
      )
    }
  },
  resetUpdatingIssueField: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingIssueField[property] = false
      }
    } else {
      Object.keys(state.updatingIssueField).forEach(
        property => state.updatingIssueField[property] = false
      )
    }
  },
  setRemovingIssueField: (state, removingIssueField) => {
    state.removingIssueField = removingIssueField
  },
  setIssueFieldRemoved: (state, issueFieldRemoved) => {
    state.issueFieldRemoved = issueFieldRemoved
  },
  issueFieldRemove: (state, issueFieldToBeRemovedId) => {
    const index = state.issueFields.
      findIndex(issueField =>issueField.id === issueFieldToBeRemovedId
      )
    state.issueFields.splice(index, 1)
  }
}
import axios from "axios"
import jsMD5 from "js-md5"

/**
 * This method return a list of unique strings from any type of variable.
 * This method will be used to populate autocomplete search fields.
 * @param {*} data that will be input for generation of unique strings.
 * @param {*} excludedKeys object keys to be excluded while constructing unique strings.
 */
export const getListOfStrings = (data, excludedKeys) => {
  const results = new Array()
  if (data !== undefined) {
    if (Array.isArray(data)) {
      for (const item of data) {
        results.push(...getListOfStrings(item, excludedKeys))
      }
    } else if (typeof data === "object") {
      if (data) {
        for (const entry of Object.entries(data)) {
          if (!excludedKeys || !excludedKeys.includes(entry[0])) {
            results.push(...getListOfStrings(entry[1], excludedKeys))
          }
        }
      }
    } else {
      results.push(data.toString())
    }
  }
  return Array.from(new Set(results))
}

/**
 * This method replaces all the placeholders in a string
 * @param data contains the value in which placeholders to be replaced
 * @param args contains list of values for placeholders as an arguments
 */
export const format = (data, ...args) => {
  return data.replace(/((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g, (m, str, index) => {
    if (str) {
      return str.replace(/(?:{{)|(?:}})/g, x => x[0])
    } else {
      if (index < args.length) {
        return args[index]
      }
    }
  })
}

/**
 * This method returns elements in first array which are not present in second array of objects
 * @param {*} firstArray array of objects from which needs to be filtered.
 * @param {*} secondArray array of objects that shouldn't be returned.
 * @param {*} key key based on which difference will be found.
 */
export const getObjectsOnlyInFirstArray = (firstArray, secondArray, key) => {
  const valueOfKeysInSecondArray = secondArray.map(item => item[key])
  return firstArray.filter(item => {
    return !valueOfKeysInSecondArray.includes(item[key])
  })
}

/**
 * This method negates boolean.
 * @param {*} value value to be negated.
 */
export const negateBoolean = value => {
  if (value === undefined || value === null) {
    return value
  } else {
    return !value
  }
}

/**
 * This method will return two character initials for the passed string.
 * @param {*} value to converted to initials.
 */
export const getInitials = value => {
  if (value) {
    const nameParts = value.split(" ")
    let initials
    if (nameParts.length === 1) {
      initials = nameParts[0].charAt(0, 1).toUpperCase()
    } else if (nameParts.length > 1) {
      initials = nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    }
    return initials
  }
}

/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
*/
export const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === "object"

  return objects.reduce((accumulator, currentValue) => {
    Object.keys(currentValue).forEach(key => {
      const previousVal = accumulator[key]
      const currentVal  = currentValue[key]

      if (Array.isArray(previousVal) && Array.isArray(currentVal)) {
        let tempObject = {}
        previousVal.concat(...currentVal).map((json, index) => {
          tempObject = (index === 0) ? json : mergeDeep(tempObject, json)
        })
        accumulator[key] = [tempObject]
      } else if (isObject(previousVal) && isObject(currentVal)) {
        accumulator[key] = mergeDeep(previousVal, currentVal)
      } else {
        accumulator[key] = currentVal
      }
    })

    return accumulator
  }, {})
}

/**
 * This method will exports json data to csv file.
 * @param {*} filename name for the csv export file.
 * @param {*} arrayOfJson json data to be converted to csv.
 */

export const convertToCSV = (filename, arrayOfJson) => {
  const replacer = (key, value) => value === null || value === undefined ? "" : value.toString() // specify how you want to handle null values here
  if (arrayOfJson?.length) {
    const header = Object.keys(arrayOfJson[0])
    let csv      = arrayOfJson.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(","))
    csv.unshift(header.join(","))
    csv = csv.join("\r\n")
    // Create link and download
    var link = document.createElement("a")
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv))
    link.setAttribute("download", filename)
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click(); document.body.removeChild(link)
  }
}

/**
 * This method will download a file from a url.
 * @param {*} url url of file to be downloaded.
 * @param {*} downloadName name of file after download.
 */
export const downloadFile = async (url, md5, downloadName) => {
  const result = await axios.get(url, {
    responseType: "arraybuffer"
  })
  if (!md5 || jsMD5(result.data) === md5) {
    const url   = window.URL.createObjectURL(new Blob([result.data]))
    const link  = document.createElement("a")
    link.href   = url
    link.target = "_blank"
    if (downloadName) {
      link.download = downloadName
    }
    link.click()
  }
}

/**
 * This method converts camel case to snake case
 * @param {*} value value to be converted.
 */
export const camelToSnake = value => {
  var result = value.replace(/([A-Z])/g, " $1")
  return result.split(" ").join("_").toLowerCase()
}

export const compareDates = (date1, date2) => {
  return new Date(date1).getTime() - new Date(date2).getTime()
}

export const compareDatesWithoutTime = (date1, date2) => {
  if (!date1 && !date2) {
    return 0
  } else if (!date1) {
    return 1
  } else if (!date2) {
    return -1
  }
  const date1ValueWithTime = new Date(date1)
  const date2ValueWithTime = new Date(date2)
  return new Date(date1ValueWithTime).getTime() - new Date(date2ValueWithTime).getTime()
}

export const compareArray = (array1, array2) => {
  if (array1 || array2) {
    if (array1?.length === array2?.length) {
      for (const item of array1) {
        if (!array2.includes(item)) {
          return false
        }
      }
    } else {
      return false
    }
  }
  return true
}

/**
 * This method will generate md5 checksum for a file.
 * @param {*} file contains content/details of file.
 */
export const generateMD5ForFile = file  => {
  const reader    = new FileReader()
  const md5Result = new Promise(resolve => {
    reader.onload  = (function(event) {
      resolve(jsMD5(event.target.result))
    })
    reader.onerror = function(event) {
      resolve(event)
    }
    reader.readAsArrayBuffer(file)
  })

  return md5Result
}

export const getMapOfArrayOfObjects = (arrayOfObjects, keyProperty) => {
  const result = new Object()
  for (const item of arrayOfObjects) {
    result[item[keyProperty]] = item
  }
  return result
}

export const mergeArrayOfObjects = (existingItems, currentItems, searchingProperty = "id") => {
  for (const currentItem of currentItems) {
    const index = existingItems.findIndex(existingItem =>
      existingItem?.[searchingProperty] === currentItem[searchingProperty]
    )
    if (index >= 0) {
      existingItems.splice(index, 1, { ...existingItems[index], ...currentItem })
    } else {
      existingItems.push(currentItem)
    }
  }
}

export const getItemsInOriginalOrder = (original, current) => {
  const result = []
  for (const item of original) {
    if (current.includes(item)) {
      result.push(item)
    }
  }
  for (const item of current) {
    if (!original.includes(item)) {
      result.push(item)
    }
  }
  return result
}

export const convertDaysToDuration = days => {
  let countOfMonthsOrYears
  let localeValue
  if (days < 365) {
    countOfMonthsOrYears = Math.round(days / 30.4375 * 10) / 10
    localeValue          = "1283"
  } else {
    countOfMonthsOrYears = Math.round(days / 365.25 * 10) / 10
    localeValue          = "1284"
  }
  return { localeValue, count: countOfMonthsOrYears }
}
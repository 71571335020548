export default {
  loggedInUserPolicies              : new Object(),
  issueRemoveCommentPolicies        : new Array(),
  issueAddCommentPolicies           : new Array(),
  issueUpdatePolicies               : new Array(),
  issueViewPolicies                 : new Array(),
  issueAddDocumentsPolicies         : new Array(),
  issueDocumentRemovePolicies       : new Array(),
  reportViewPolicies                : new Array(),
  reportUpdatePolicies              : new Array(),
  reportAddMessagesPolicies         : new Array(),
  messageUpdatePolicies             : new Array(),
  messageRemovePolicies             : new Array(),
  messageItemAddTranslationsPolicies: new Array(),
  translationUpdatePolicies         : new Array(),
  issueAddFormInstancesPolicies     : new Array(),
  formInstanceUpdatePolicies        : new Array(),
  formInstanceRemovePolicies        : new Array(),
  issueUpdateIssueFieldValuesPolices: new Array(),
  issuePrintPolicies                : new Array(),
  issueLinkAddPolicies              : new Array(),
  issueLinkRemovePolicies           : new Array(),
  issueAddTaskPolicies              : new Array()
}
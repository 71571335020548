import { CONFIGURATIONS, SSO_STATE, STRING } from "@/constants"

export default {
  isLoadingConfigurations         : state => state.loadingConfigurations,
  configurations                  : state => state.configurations,
  isUpdatingValue                 : state => state.updatingConfiguration.value,
  isValueUpdated                  : state => state.configurationUpdated.value,
  isLoadingDefaultSsoConfiguration: state => state.loadingDefaultSsoConfiguration,
  isDefaultSsoConfigurationSaved  : state => state.defaultSsoConfigurationSaved,
  isSavingDefaultSsoConfiguration : state => state.savingDefaultSsoConfiguration,
  defaultSsoConfiguration         : state => state.defaultSsoConfiguration,
  defaultSsoConfigurationError    : state => state.defaultSsoConfigurationError,
  isEnablingDefaultSsoLogin       : state => state.enablingDefaultSsoLogin,
  isDefaultSsoLoginEnabled        : state => state.defaultSsoLoginEnabled,
  isSsoAllowed                    : state => {
    const ssoConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.SSO
    )
    return ssoConfiguration?.value === STRING.TRUE
  },
  isDefaultSsoConfigured: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.DEFAULT_SSO
  )?.value === SSO_STATE.CONFIGURED,
  isDefaultSsoEnabled: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.DEFAULT_SSO
  )?.value === SSO_STATE.ENABLED,
  clientUserPoolId: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.CLIENT_USER_POOL_ID
  )?.value,
  clientConfigurationId: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.ID
  )?.value,
  isAiAnonymisationEnabled: state => {
    const aiAnonymisationConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.AI_ANONYMISATION
    )
    return aiAnonymisationConfiguration?.value === STRING.TRUE
  }
}
import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { PAGES } from "@/constants"

/**
* This method will be called before navigating to Configurations page.
*/
export const beforeConfigurations = async (to, from) => {
  if ( from.name !== "configurations" || !(PAGES.CONFIGURATIONS.includes(to.hash) || PAGES.CONFIGURATIONS.includes(from.hash))) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CONFIGURATIONS))
    store.commit("shared/setBreadcrumbs", undefined)
    store.commit("configurations/resetUpdatingConfiguration")
    store.commit("configurations/resetConfigurationUpdated")
    store.commit("configurations/resetConfigurationUpdateError")
    store.dispatch("configurations/loadConfigurations")
    store.dispatch("translationPreferences/loadTranslationPreferences")
    store.dispatch("groups/loadGroups")
    store.dispatch("users/loadUsers")

    if (store.getters["configurations/isDefaultSsoConfigured"] || store.getters["configurations/isDefaultSsoEnabled"] ) {
      store.dispatch("configurations/loadDefaultSsoConfiguration")
    }

    await store.dispatch("roles/loadRoles")
    const globalRoleIds = []
    for (const role of store.getters["roles/roles"]) {
      if (role.global) {
        globalRoleIds.push(role.id)
      }
    }
    if (globalRoleIds.length) {
      store.dispatch("accesses/loadAccesses", {
        roleId: globalRoleIds.toString()
      })
    }
    store.dispatch("dataRetentionRules/loadDataRetentionRules")
    store.dispatch("dataRetentionPeriods/loadDataRetentionPeriods")
    await store.dispatch("fields/loadFieldsV2")
  }
}
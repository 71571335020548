import { defaultTableHeaderClasses } from "@/constants"

export const DATA_RETENTION_RULES = {
  headers: headerText => {
    return [
      {
        text    : headerText,
        align   : "left",
        value   : "ruleName",
        sortable: false,
        class   : `${defaultTableHeaderClasses} col-md-10 col-sm-8`
      },
      {
        align   : "right",
        value   : "ruleOptions",
        sortable: false,
        class   : `${defaultTableHeaderClasses} col-md-5 col-sm-4`
      }
    ]
  }
}
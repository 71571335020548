export const USER = route => ([
  {
    text          : "122",
    exact         : true,
    to            : "/users",
    useTranslation: true
  },
  {
    text          : route.params.id,
    to            : `/users/${route.params.id}`,
    useTranslation: false
  }
])
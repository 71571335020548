import { defaultTableHeaderClasses } from "@/constants"

export const ISSUE_FORM_TEMPLATES = {
  headers: [
    {
      text    : "988",
      value   : "name",
      align   : "left",
      class   : defaultTableHeaderClasses,
      sortable: false
    },
    {
      text    : "989",
      value   : "fields",
      align   : "left",
      width   : "50%",
      class   : defaultTableHeaderClasses,
      sortable: false
    }
  ]
}
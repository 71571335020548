import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/data-retention-periods`

export default {

  /**
   * This action loads data retention periods.
   * @param {*} context is the store.
   */
  async loadDataRetentionPeriods(context, payload) {
    context.commit("setLoadingDataRetentionPeriods", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getDataRetentionPeriodsResponse = await httpClient.get(undefined, payload)

    if (getDataRetentionPeriodsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateDataRetentionPeriods", [getDataRetentionPeriodsResponse.data])
      } else {
        context.commit("setDataRetentionPeriods", getDataRetentionPeriodsResponse.data)
      }
    }
    context.commit("setLoadingDataRetentionPeriods", false)
  },

  /**
   * This action adds a data retention period.
   * @param {*} context is the store.
   * @param {*} payload contains data retention period to add.
   */
  async addDataRetentionPeriod(context, payload) {
    context.commit("setAddingDataRetentionPeriod", true)
    context.commit("setDataRetentionPeriodAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const addDataRetentionPeriodResponse = await httpClient.post(undefined, payload)

    if (addDataRetentionPeriodResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateDataRetentionPeriods", [addDataRetentionPeriodResponse.data])
      context.commit("setDataRetentionPeriodAdded", true)
    } else if (addDataRetentionPeriodResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setDataRetentionPeriodAddError", addDataRetentionPeriodResponse.data)
    }
    context.commit("setAddingDataRetentionPeriod", false)
  },

  /**
   * This action removes a data retention period.
   * @param {*} context is the store.
   * @param {*} payload contains data retention period id.
   */
  async removeDataRetentionPeriod(context, payload) {
    context.commit("setRemovingDataRetentionPeriod", true)
    context.commit("setDataRetentionPeriodRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const removeRetentionPeriodResponse = await httpClient.delete(`/${payload.id}`)

    if (removeRetentionPeriodResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeDataRetentionPeriod", payload)
      context.commit("setDataRetentionPeriodRemoved", true)
    }
    context.commit("setRemovingDataRetentionPeriod", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setDataRetentionPeriods", new Array())
    context.commit("setLoadingDataRetentionPeriods", undefined)
    context.commit("setAddingDataRetentionPeriod", undefined)
    context.commit("setDataRetentionPeriodAdded", undefined)
    context.commit("setDataRetentionPeriodAddError", undefined)
  }
}
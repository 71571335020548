/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/translations`

export default {
  /**
   * This action will load translations.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load translations.
   */
  async loadTranslations(context, payload) {
    context.commit("setLoadingTranslations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getTranslationsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getTranslationsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateTranslations", getTranslationsResponse.data)
      } else {
        context.commit("setTranslations", getTranslationsResponse.data)
      }
    } else {
      // something went wrong while getting translations
    }

    context.commit("setLoadingTranslations", false)
  },

  /**
   * This action will add a translation.
   * @param {*} context is the store.
   */
  async addTranslation(context, payload) {
    context.commit("setAddingTranslation", true)
    context.commit("setTranslationAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postTranslationResponse = await httpClient.post(undefined, payload)

    if (postTranslationResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateTranslations", [postTranslationResponse.data])
      context.commit("setTranslationAdded", true)
    } else if (postTranslationResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setTranslationAddError", postTranslationResponse.data)
    } else {
      // something went wrong while adding a translation
    }

    context.commit("setAddingTranslation", false)
  },

  /**
   * This action updates summary of an translation.
   * @param {*} context is the store.
   * @param {*} translation id and summary of an translation.
   */
  async updateTranslation(context, translation) {
    const id = translation.id
    delete translation.id
    const propertiesToBeUpdated = Object.keys(translation)
    for (const property of propertiesToBeUpdated) {
      if (translation[property] === undefined) {
        translation[property] = null
      }
    }
    context.commit("setUpdatingTranslation", propertiesToBeUpdated)
    context.commit("resetTranslationUpdated", propertiesToBeUpdated)
    context.commit("resetTranslationUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateTranslationResponse = await httpClient.put(`/${id}`, translation)

    if (updateTranslationResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateTranslations", [{
        id,
        ...translation
      }])
      context.commit("setTranslationUpdated", propertiesToBeUpdated)
    } else if (updateTranslationResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setTranslationUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateTranslationResponse.data
      })
    } else {
      // something went wrong while updating an translation
    }

    context.commit("resetUpdatingTranslation", propertiesToBeUpdated)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setTranslations", new Array())
    context.commit("setLoadingTranslations", undefined)
    context.commit("setAddingTranslation", undefined)
    context.commit("setTranslationAdded", undefined)
    context.commit("setTranslationAddError", undefined)
    context.commit("resetUpdatingTranslation")
    context.commit("resetTranslationUpdated")
    context.commit("resetTranslationUpdateError")
  }
}

export default {
  setLoadingOptionLists: (state, loadingOptionLists) => {
    state.loadingOptionLists = loadingOptionLists
  },
  setOptionLists: (state, optionLists) => {
    state.optionLists = optionLists
  },
  setAddingOptionList: (state, addingOptionList) => {
    state.addingOptionList = addingOptionList
  },
  setOptionListAdded: (state, optionListAdded) => {
    state.optionListAdded = optionListAdded
  },
  setOptionListAddError: (state, optionListAddError) => {
    state.optionListAddError = optionListAddError
  },
  setUpdatingOptionList: (state, properties) => {
    for (const property of properties) {
      state.updatingOptionList[property] = true
    }
  },
  resetUpdatingOptionList: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingOptionList[property] = false
      }
    } else {
      Object.keys(state.updatingOptionList).forEach(property => state.updatingOptionList[property] = false)
    }
  },
  setOptionListUpdated: (state, properties) => {
    for (const property of properties) {
      state.optionListUpdated[property] = true
      setTimeout(() => {
        state.optionListUpdated[property] = false
      }, 5000)
    }
  },
  resetOptionListUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.optionListUpdated[property] = false
      }
    } else {
      Object.keys(state.optionListUpdated).forEach(property => state.optionListUpdated[property] = false)
    }
  },
  setOptionListUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.optionListUpdateError[property] = error
    }
  },
  resetOptionListUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.optionListUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.optionListUpdateError).forEach(property => state.optionListUpdateError[property] = undefined)
    }
  },
  updateOptionLists: (state, optionLists) => {
    for (const optionList of optionLists) {
      const index = state.optionLists.findIndex(stateOptionList => stateOptionList?.id === optionList.id)
      if (index >= 0) {
        state.optionLists.splice(index, 1, { ...state.optionLists[index], ...optionList })
      } else {
        state.optionLists.push(optionList)
      }
    }
  },
  updateOptionListsExport: (state, optionLists) => {
    for (const optionList of optionLists) {
      const index = state.optionListsExport.findIndex(stateOptionList =>
        stateOptionList?.optionListId === optionList.optionListId)
      if (index >= 0) {
        state.optionListsExport.splice(index, 1, { ...state.optionListsExport[index], ...optionList })
      } else {
        state.optionListsExport.push(optionList)
      }
    }
  },
  setAddingOptionListExport: (state, addingOptionListExport) => {
    state.addingOptionListExport = addingOptionListExport
  },
  setOptionListsExport: (state, optionListsExport) => {
    state.optionListsExport = optionListsExport
  },
  setUploadingOptionListItemTranslation: (state, uploadingOptionListItemTranslation) => {
    state.uploadingOptionListItemTranslation = uploadingOptionListItemTranslation
  },
  setOptionListItemTranslationUploaded: (state, optionListItemTranslationUploaded) => {
    state.optionListItemTranslationUploaded = optionListItemTranslationUploaded
  },
  resetOptionLists: state => {
    state.optionLists = new Array()
  }
}
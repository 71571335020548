/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import store from "@/plugins/vuex"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/users`

export default {
  /**
   * This action will load users.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load users.
   */
  async loadUsers(context, payload) {
    context.commit("setLoadingUsers", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getUsersResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getUsersResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateUsers", getUsersResponse.data)
      } else {
        context.commit("setUsers", getUsersResponse.data)
      }
    } else {
      // something went wrong while getting users
    }

    context.commit("setLoadingUsers", false)
  },

  /**
   * This action will add a user.
   * @param {*} context is the store.
   */
  async addUser(context, payload) {
    context.commit("setAddingUser", true)
    let groupIds
    if (payload.groupIds) {
      groupIds = payload.groupIds
      delete payload.groupIds
    }

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postUserResponse = await httpClient.post(undefined, payload)

    if (postUserResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateUsers", [postUserResponse.data])
      if (groupIds) {
        await context.dispatch("addGroups", {
          id: postUserResponse.data.id,
          groupIds
        })
      }
      context.commit("setUserAdded", true)
    } else if (postUserResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setUserAddError", postUserResponse.data)
    } else {
      // something went wrong while adding a user
    }

    context.commit("setAddingUser", false)
  },

  /**
   * This action will load a particular user.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the user to be loaded
   */
  async loadUser(context, payload) {
    context.commit("setLoadingUsers", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getUserResponse = await httpClient.get(`/${payload}`)

    if (getUserResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateUsers", [getUserResponse.data])
    } else {
      // something went wrong while getting an user
    }

    context.commit("setLoadingUsers", false)
  },

  /**
   * This action updates summary of an user.
   * @param {*} context is the store.
   * @param {*} user id and summary of an user.
   */
  async updateUser(context, user) {
    const id = user.id
    delete user.id
    const propertiesToBeUpdated = Object.keys(user)
    for (const property of propertiesToBeUpdated) {
      if (user[property] === undefined) {
        user[property] = null
      }
    }
    context.commit("setUpdatingUser", propertiesToBeUpdated)
    context.commit("resetUserUpdated", propertiesToBeUpdated)
    context.commit("resetUserUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateUserResponse = await httpClient.put(`/${id}`, user)

    if (updateUserResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateUsers", [{
        id,
        ...user
      }])
      context.commit("setUserUpdated", propertiesToBeUpdated)
    } else if (updateUserResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setUserUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateUserResponse.data
      })
    } else {
      // something went wrong while updating an user
    }

    context.commit("resetUpdatingUser", propertiesToBeUpdated)
  },

  /**
   * This action load groups of user.
   * @param {*} context is the store.
   * @param {*} payload contains id of the user.
   */
  async loadGroups(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getGroupsResponse = await httpClient.get(`/${payload}/groups`)

    if (getGroupsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateGroupsOfUser", {
        user  : payload,
        groups: getGroupsResponse.data
      })
    } else {
      // something went wrong while loading group users
    }
  },

  /**
   * This action will resend password for selected user.
   * @param {*} context is the store.
   * @param {*} id is the user id.
   */
  async sendPassword(context, id) {
    context.commit("setSendingPassword", true)

    const httpClient        = GET_HTTP_CLIENT({
      baseURL
    }, context)
    const postUsersResponse = await httpClient.post(`/${id}/send-password`)

    if (postUsersResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setPasswordSent", true)
    } else {
      // unexpected error while sending password to user.
      context.dispatch("shared/notify", {
        type: "error",
        text: "432"
      }, { root: true })
    }

    context.commit("setSendingPassword", false)
  },

  /**
   * This action adds users to a particular group.
   * @param {*} context is the store.
   * @param {*} param1 contains id of the group and array of user ids.
   */
  async addGroups(context, { id, groupIds }) {
    context.commit("setAddingGroups", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const addGroupsResponse = await httpClient.post(`/${id}/groups`, groupIds)

    if (addGroupsResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateGroupsOfUser", {
        user  : id,
        groups: groupIds
      })
    } else {
      // something went wrong while adding users to a group
    }

    context.commit("setAddingGroups", false)
  },

  /**
   * This action deletes user.
   * @param {*} context is the store.
   * @param {*} payload id of an user.
   */
  async deleteUser(context, payload) {
    context.commit("setDeletingUser", true)
    context.commit("setUserDeleted", false)
    const users = context.getters.users

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const deleteUserResponse = await httpClient.delete(`/${payload}`)
    if (deleteUserResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      const updatedUsers = users.filter(user => user.id !== payload)
      context.commit("setUsers", updatedUsers)
      context.commit("setUserDeleted", true)

      const accesses        = context.rootGetters["accesses/accesses"]
      const updatedAccesses = accesses.filter(access => access.userId !==  payload)
      store.commit("accesses/setAccesses", updatedAccesses)

      const groupsUsers        = context.rootGetters["groups/groupsUsers"]
      const updatedGroupsUsers = Object.keys(groupsUsers).reduce((groupOfUsers, groupId) => {
        return {
          [groupId]: groupsUsers[groupId].filter(userId => userId !== payload),
          ...groupOfUsers
        }
      }, {})
      store.commit("groups/setAllGroupUsers", updatedGroupsUsers)

      const groupsOfUsers = context.rootGetters["users/groupsOfUsers"]
      delete groupsOfUsers[payload]
      store.commit("users/setGroupsOfUsers", groupsOfUsers)

    } else {
      // something went wrong while adding an user
      context.dispatch("shared/notify", {
        type: "error",
        text: "432"
      }, { root: true })
    }
    context.commit("setDeletingUser", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setUsers", new Array())
    context.commit("setLoadingUsers", undefined)
    context.commit("setAddingUser", undefined)
    context.commit("setUserAdded", undefined)
    context.commit("setAddingGroups", false)
    context.commit("resetGroupsOfUsers")
    context.commit("resetUpdatingUser")
    context.commit("resetUserUpdated")
    context.commit("resetUserUpdateError")
    context.commit("setSendingPassword", undefined)
    context.commit("setPasswordSent", undefined)
    context.commit("setDeletingUser", false)
    context.commit("setUserDeleted", false)
  }
}

/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/groups`

export default {
  /**
   * This action will load groups.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load groups.
   */
  async loadGroups(context, payload) {
    context.commit("setLoadingGroups", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getGroupsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getGroupsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateGroups", [getGroupsResponse.data])
      } else {
        context.commit("setGroups", getGroupsResponse.data)
      }
    } else {
      // something went wrong while getting groups
    }

    context.commit("setLoadingGroups", false)
  },

  /**
   * This action will load groups.
   * @param {*} context is the store.
   * @param {*} payload it is id of the group to be loaded
   */
  async loadGroup(context, payload) {
    context.commit("setLoadingGroups", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getGroupResponse = await httpClient.get(`/${payload}`)

    if (getGroupResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateGroups", [getGroupResponse.data])
    } else {
      // something went wrong while getting an group
    }

    context.commit("setLoadingGroups", false)
  },

  /**
   * This action will add a new group.
   * @param {*} context is the store
   * @param {*} payload contains group details
   */
  async addGroup(context, payload) {
    context.commit("setAddingGroup", true)
    context.commit("setGroupAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postGroupResponse = await httpClient.post(undefined, payload)

    if (postGroupResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateGroups", [postGroupResponse.data])
      context.commit("setGroupAdded", true)
    } else if (postGroupResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setGroupAddError", postGroupResponse.data)
    } else {
      // something went wrong while adding a group
    }

    context.commit("setAddingGroup", false)
  },

  /**
   * This action removes a particular group.
   * @param {*} context is the store.
   * @param {*} payload contains id of the group.
   */
  async removeGroup(context, payload) {
    context.commit("setRemovingGroup", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const deleteGroupResponse = await httpClient.delete(`/${payload}`)

    if (deleteGroupResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setGroupRemoved", true)
    } else {
      // something went wrong while updating an group
    }

    context.commit("setRemovingGroup", false)
  },

  /**
   * This action updates summary of an group.
   * @param {*} context is the store.
   * @param {*} group id and summary of an group.
   */
  async updateGroup(context, group) {
    const id = group.id
    delete group.id
    const propertiesToBeUpdated = Object.keys(group)
    for (const property of propertiesToBeUpdated) {
      if (group[property] === undefined) {
        group[property] = null
      }
    }
    context.commit("setUpdatingGroup", propertiesToBeUpdated)
    context.commit("resetGroupUpdated", propertiesToBeUpdated)
    context.commit("resetGroupUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateGroupResponse = await httpClient.put(`/${id}`, group)

    if (updateGroupResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateGroups", [{
        id,
        ...group
      }])
      context.commit("setGroupUpdated", propertiesToBeUpdated)
    } else if (updateGroupResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setGroupUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateGroupResponse.data
      })
    } else {
      // something went wrong while updating an group
    }

    context.commit("resetUpdatingGroup", propertiesToBeUpdated)
  },
  /**
   * This action adds users to a particular group.
   * @param {*} context is the store.
   * @param {*} param1 contains id of the group and array of user ids.
   */
  async addUsers(context, { id, users }) {
    context.commit("setAddingUsers", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const addUsersResponse = await httpClient.post(`/${id}/users`, {
      userIds: users
    })

    if (addUsersResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateGroupUsers", {
        group: id,
        users
      })
    } else {
      // something went wrong while adding users to a group
    }

    context.commit("setAddingUsers", false)
  },

  /**
   * This action load group users.
   * @param {*} context is the store.
   * @param {*} payload contains id of the group.
   */
  async loadUsers(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const groupUsersResponse = await httpClient.get(`/${payload}/users`)

    if (groupUsersResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateGroupUsers", {
        group: payload,
        users: groupUsersResponse.data
      })
    } else {
      // something went wrong while loading group users
    }
  },

  /**
   * This action removes a particular user from a group.
   * @param {*} context is the store.
   * @param {*} param1 contains id of the group and id of an user.
   */
  async removeUser(context, { id, user }) {
    context.commit("setRemovingUser", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const removeUserResponse = await httpClient.delete(`/${id}/users/${user}`)

    if (removeUserResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeGroupUser", {
        group: id,
        user
      })
    } else {
      // something went wrong while adding users to a group
    }

    context.commit("setRemovingUser", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setGroups", new Array())
    context.commit("setLoadingGroups", undefined)
    context.commit("setAddingGroup", undefined)
    context.commit("setGroupAdded", undefined)
    context.commit("setGroupAddError", undefined)
    context.commit("resetUpdatingGroup")
    context.commit("resetGroupUpdated")
    context.commit("resetGroupUpdateError")
    context.commit("setAddingUsers", undefined)
    context.commit("setRemovingUser", undefined)
    context.commit("setRemovingGroup", undefined)
    context.commit("setGroupRemoved", undefined)
    context.commit("resetGroups")
    context.commit("resetGroupsUsers")
  }
}
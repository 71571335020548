import store from "@/plugins/vuex"
import { BOOLEAN } from "@/constants"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to reporter intake forms page.
 */
export const beforeReporterIntakeForms = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.REPORTER_INTAKE_FORMS))
  store.commit("shared/setBreadcrumbs", undefined)
  store.dispatch("formTemplates/loadFormTemplates", {
    reportForm: BOOLEAN.TRUE
  })
}
import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { PAGES } from "@/constants"

export const beforeUserSettings = async (to, from) => {
  if (from.name !== "user-settings" || !(PAGES.USER_SETTINGS.includes(to.hash) || PAGES.USER_SETTINGS.includes(from.hash))) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.USER_SETTINGS))
    const emailSubscriptionsPromise = store.dispatch("emailSubscriptions/loadEmailSubscriptions")
    store.commit("shared/setBreadcrumbs", undefined)

    const isDefaultSsoEnabled = await store.getters["configurations/isDefaultSsoEnabled"]

    if(!isDefaultSsoEnabled) {
      store.commit("auth/setVerifyingToken", false)
      store.commit("auth/setTokenVerified", undefined)
      store.dispatch("auth/loadUserData")
    }
    await emailSubscriptionsPromise
  }
}
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, TRANSLATION_UPLOAD_STATUS, OPTION_LIST_EXPORT_STATUS, OPTION_LIST_EXPORT_POLL_INTERVAL } from "@/constants"
import { generateMD5ForFile, downloadFile } from "@/utils"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/option-lists`

export default {

  /**
    * This action will load option lists.
    * @param {*} context is the store.
    * @param {*} payload is the filter to optionLists.
    */
  async loadOptionLists(context, payload) {
    context.commit("setLoadingOptionLists", true)
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getOptionListsResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getOptionListsResult.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateOptionLists", [getOptionListsResult.data])
      } else {
        context.commit("setOptionLists", getOptionListsResult.data)
      }
    }

    context.commit("setLoadingOptionLists", false)
  },
  /**
   * This action will add new optionList.
   * @param {*} context is the store.
   * @param {*} payload is the name to add to the optionList
   */
  async addOptionList(context, payload) {
    context.commit("setAddingOptionList", true)
    context.commit("setOptionListAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postOptionListResponse = await httpClient.post(undefined, payload)

    if (postOptionListResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateOptionLists", [postOptionListResponse.data])
      context.commit("setOptionListAdded", true)
    } else if (postOptionListResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setOptionListAddError", postOptionListResponse.data)
    }

    context.commit("setAddingOptionList", false)
  },

  /**
   * This action will load option list.
   * @param {*} context is the store.
   * @param {*} payload it is id of the option list to be loaded
   */
  async loadOptionList(context, payload) {
    if (payload?.uploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED) {
      delete payload.uploadStatus
      payload = payload.id
      context.commit("setLoadingOptionLists", false)
    } else {
      context.commit("setLoadingOptionLists", true)
    }

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getOptionListResponse = await httpClient.get(`/${payload}`)

    if (getOptionListResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateOptionLists", [getOptionListResponse.data])
    }

    context.commit("setLoadingOptionLists", false)
  },

  /**
   * This action updates summary of a channel.
   * @param {*} context is the store.
   * @param {*} optionList id and summary of a channel.
   */
  async updateOptionList(context, optionList) {
    const id = optionList.id
    delete optionList.id

    const propertiesToBeUpdated = Object.keys(optionList)
    for (const property of propertiesToBeUpdated) {
      if (optionList[property] === undefined) {
        optionList[property] = null
      }
    }
    context.commit("setUpdatingOptionList", propertiesToBeUpdated)
    context.commit("resetOptionListUpdated", propertiesToBeUpdated)
    context.commit("resetOptionListUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateOptionListResponse = await httpClient.put(`/${id}`, optionList)

    if (updateOptionListResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateOptionLists", [{
        id,
        ...optionList
      }])
      context.commit("setOptionListUpdated", propertiesToBeUpdated)
    } else if (updateOptionListResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setOptionListUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateOptionListResponse.data
      })
    }
    context.commit("resetUpdatingOptionList", propertiesToBeUpdated)
  },

  /**
   * This action loads the details to export a particular option list translation document.
   * @param {*} context is the store.
   * @param {*} payload contains id of the option list.
   */
  async loadOptionListExport(context, payload) {
    const id         = payload.id
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getOptionListExportResponse = await httpClient.get(`/${id}/export`)
    if (getOptionListExportResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateOptionListsExport", [{ ...getOptionListExportResponse.data, optionListId: id }])
    }
  },

  /**
   * This action initiates the export of a particular option list translation document.
   * @param {*} context is the store.
   * @param {*} payload contains id and name of the option list.
   */
  async addOptionListExport(context, payload) {
    context.commit("setAddingOptionListExport", true)
    const id         = payload.id
    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const postOptionListExportResponse = await httpClient.post(`/${id}/export`)
    if (postOptionListExportResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateOptionListsExport", [{ ...postOptionListExportResponse.data, optionListId: id }])
      pollingOptionListExport(context, payload)
    }
    context.commit("setAddingOptionListExport", false)
  },

  /**
   * This action upload option list item translation file.
   * @param {*} context is the store.
   * @param {*} payload contains id of the option list and the file to be uploaded.
   */
  async uploadOptionListItemTranslationFile(context, payload) {
    context.commit("setUploadingOptionListItemTranslation", true)
    context.commit("setOptionListItemTranslationUploaded", false)

    const { optionList, file } = payload
    const md5                  = await generateMD5ForFile(file)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const uploadUrlResponse = await httpClient.post(`/${optionList.id}/translations-upload-url`, { md5 })
    if (uploadUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(uploadUrlResponse.data.fields).forEach(key =>
        form.append(key, uploadUrlResponse.data.fields[key]))
      form.append("file", file)

      const headers = {
        "Content-MD5": Buffer.from(md5, "hex").toString("base64")
      }

      const response = await fetch(uploadUrlResponse.data.url, {
        headers,
        method: "POST",
        body  : form
      })
      if (response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        context.commit("updateOptionLists", [{
          ...optionList,
          ...{ "translationUploadStatus": TRANSLATION_UPLOAD_STATUS.INITIATED }
        }])

        await httpClient.put(`/${optionList.id}`, {
          translationUploadStatus: TRANSLATION_UPLOAD_STATUS.INITIATED
        })
      }
    }
    context.commit("setOptionListItemTranslationUploaded", false)
    context.commit("setUploadingOptionListItemTranslation", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingOptionLists", undefined)
    context.commit("setOptionLists", new Array())
    context.commit("resetOptionLists")
    context.commit("setAddingOptionList", undefined)
    context.commit("setOptionListAdded", undefined)
    context.commit("setOptionListAddError", undefined)
    context.commit("resetUpdatingOptionList")
    context.commit("resetOptionListUpdated")
    context.commit("resetOptionListUpdateError")
    context.commit("setOptionListItemTranslationUploaded", undefined)
    context.commit("setUploadingOptionListItemTranslation", false)
    context.commit("setAddingOptionListExport", false)
    context.commit("setOptionListsExport", new Array())
  }
}

const pollingOptionListExport = (context, payload) => {
  const { id, name }        = payload
  const exportStatusPolling = setInterval(async () => {
    const isLoggedIn = context.rootGetters["auth/isLoggedIn"]
    if (isLoggedIn) {
      await context.dispatch("loadOptionListExport", { id })
      const optionListExport = context.getters.optionListsExport?.find(optionListExport =>
        optionListExport.optionListId === id)
      if (optionListExport.status === OPTION_LIST_EXPORT_STATUS.COMPLETED) {
        await downloadFile(optionListExport.url, optionListExport.md5, `${name}.xlsx`)
        clearInterval(exportStatusPolling)
      } else if (optionListExport.status === OPTION_LIST_EXPORT_STATUS.FAILED) {
        clearInterval(exportStatusPolling)
      }
    } else {
      clearInterval(exportStatusPolling)
    }
  }, OPTION_LIST_EXPORT_POLL_INTERVAL)
}

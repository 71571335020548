export default {
  setLoadingOptionListItems: (state, loadingOptionListItems) => {
    state.loadingOptionListItems = loadingOptionListItems
  },
  setOptionListItems: (state, optionListItems) => {
    state.optionListItems = optionListItems
  },
  updateOptionListItems: (state, optionListItems) => {
    for (const optionListItem of optionListItems) {
      const indexOfOptionListItem = state.optionListItems.findIndex(item =>
        item.id === optionListItem.id
      )

      const optionListItemsToBeUpdated = [{
        index: indexOfOptionListItem,
        optionListItem
      }]

      for (const optionListItemToBeUpdated of optionListItemsToBeUpdated) {
        if (optionListItemToBeUpdated.index >= 0) {
          state.optionListItems.splice(optionListItemToBeUpdated.index, 1, {
            ...state.optionListItems[optionListItemToBeUpdated.index],
            ...optionListItemToBeUpdated.optionListItem
          })
        } else {
          state.optionListItems.push(optionListItemToBeUpdated.optionListItem)
        }
      }
    }
  },
  setUpdatingOptionListItem: (state, properties) => {
    for (const property of properties) {
      state.updatingOptionListItem[property] = true
    }
  },
  resetOptionListItemUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.optionListItemUpdated[property] = false
      }
    } else {
      Object.keys(state.optionListItemUpdated).forEach(
        property => state.optionListItemUpdated[property] = false
      )
    }
  },
  setOptionListItemUpdated: (state, properties) => {
    for (const property of properties) {
      state.optionListItemUpdated[property] = true
      setTimeout(() => {
        state.optionListItemUpdated[property] = false
      }, 5000)
    }
  },
  resetUpdatingOptionListItem: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingOptionListItem[property] = false
      }
    } else {
      Object.keys(state.updatingOptionListItem).forEach(
        property => state.updatingOptionListItem[property] = false
      )
    }
  },
  setRemovingOptionListItem: (state, removingOptionListItem) => {
    state.removingOptionListItem = removingOptionListItem
  },
  setOptionListItemRemoved: (state, optionListItemRemoved) => {
    state.optionListItemRemoved = optionListItemRemoved
  },
  resetOptionListItems: state => {
    state.optionListItems = new Array()
  },
  setAddingOptionListItem: (state, addingOptionListItem) => {
    state.addingOptionListItem = addingOptionListItem
  },
  setOptionListItemAdded: (state, optionListItemAdded) => {
    state.optionListItemAdded = optionListItemAdded
  },
  setOptionListItemAddError: (state, optionListItemAddError) => {
    state.optionListItemAddError = optionListItemAddError
  },
  setAddingBulkOptionListItems: (state, addingBulkOptionListItems) => {
    state.addingBulkOptionListItems = addingBulkOptionListItems
  },
  setBulkOptionListItemsAdded: (state, bulkOptionListItemsAdded) => {
    state.bulkOptionListItemsAdded = bulkOptionListItemsAdded
  },
  setNumberOfOptionListItemsAdded: (state, numberOfOptionListItemsAdded) => {
    state.numberOfOptionListItemsAdded = numberOfOptionListItemsAdded
  },
  setNumberOfDuplicateOptionListItems: (state, numberOfDuplicateOptionListItems) => {
    state.numberOfDuplicateOptionListItems = numberOfDuplicateOptionListItems
  }
}
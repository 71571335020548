/**
 * @file It contains vuex users module mutations to mutate the state variables.
 */
export default {
  setLoadingUsers: (state, loadingUsers) => {
    state.loadingUsers = loadingUsers
  },
  setAddingUser: (state, addingUser) => {
    state.addingUser = addingUser
  },
  setUserAdded: (state, userAdded) => {
    state.userAdded = userAdded
  },
  setUsers: (state, users) => {
    state.users = new Array()
    for (const user of users) {
      state.users.push({ ...user,
        disabledButNotDeleted: isDisabledButNotDeleted(user) })
    }
  },
  updateUsers: (state, users) => {
    for (const user of users) {
      const index = state.users.findIndex(stateUser => stateUser?.id === user.id)
      if (index >= 0) {
        const updatedUser                 = { ...state.users[index], ...user }
        updatedUser.disabledButNotDeleted = isDisabledButNotDeleted(updatedUser)
        state.users.splice(index, 1, updatedUser)
      } else {
        state.users.push({
          ...user,
          disabledButNotDeleted: isDisabledButNotDeleted(user)
        })
      }
    }
  },
  setUpdatingUser: (state, properties) => {
    for (const property of properties) {
      state.updatingUser[property] = true
    }
  },
  resetUpdatingUser: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingUser[property] = false
      }
    } else {
      Object.keys(state.updatingUser).forEach(property => state.updatingUser[property] = false)
    }
  },
  setUserUpdated: (state, properties) => {
    for (const property of properties) {
      state.userUpdated[property] = true
      setTimeout(() => {
        state.userUpdated[property] = false
      }, 5000)
    }
  },
  resetUserUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.userUpdated[property] = false
      }
    } else {
      Object.keys(state.userUpdated).forEach(property => state.userUpdated[property] = false)
    }
  },
  setUserAddError: (state, error) => {
    state.userAddError = error
  },
  setUserUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.userUpdateError[property] = error
    }
  },
  resetUserUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.userUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.userUpdateError).forEach(property => state.userUpdateError[property] = undefined)
    }
  },
  updateGroupsOfUser: (state, { user, groups }) => {
    state.groupsOfUsers = Object.assign({}, state.groupsOfUsers, {
      [user]: groups
    })
  },
  setGroupsOfUsers: (state, groupsOfUsers) => {
    state.groupsOfUsers = groupsOfUsers
  },
  resetGroupsOfUsers: state => {
    state.groupsOfUsers = new Object()
  },
  setAddingGroups: (state, addingGroups) => {
    state.addingGroups = addingGroups
  },
  setSendingPassword: (state, sendingPassword) => {
    state.sendingPassword = sendingPassword
  },
  setPasswordSent: (state, passwordSent) => {
    state.passwordSent = passwordSent
    if (passwordSent) {
      setTimeout(() => {
        state.passwordSent = false
      }, 5000)
    }
  },
  setDeletingUser: (state, deletingUser) => {
    state.deletingUser = deletingUser
  },
  setUserDeleted: (state, userDeleted) => {
    state.userDeleted = userDeleted
  }
}

const isDisabledButNotDeleted = user => !user.enabled && !user.deletedAt
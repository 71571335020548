export const CHANNEL = route => ([
  {
    text          : "77",
    exact         : true,
    to            : "/channels",
    useTranslation: true
  },
  {
    text          : route.params.id,
    to            : `/channels/${route.params.id}`,
    useTranslation: false
  }
])
export default {
  loadingOptionListItems          : undefined,
  optionListItems                 : new Array(),
  removingOptionListItem          : undefined,
  optionListItemRemoved           : false,
  addingOptionListItem            : undefined,
  optionListItemAdded             : undefined,
  optionListItemAddError          : undefined,
  addingBulkOptionListItems       : undefined,
  bulkOptionListItemsAdded        : undefined,
  numberOfOptionListItemsAdded    : undefined,
  numberOfDuplicateOptionListItems: undefined,
  updatingOptionListItem          : {
    sortingOrder: false,
    parentId    : false
  },
  optionListItemUpdated: {
    sortingOrder: false,
    parentId    : false
  }
}
export default {
  loadingFormTemplates: undefined,
  formTemplates       : new Array(),
  addingFormTemplate  : undefined,
  formTemplateAdded   : undefined,
  formTemplateAddError: undefined,
  updatingFormTemplate: {
    name: false
  },
  formTemplateUpdated: {
    name: false
  },
  formTemplateUpdateError: {
    name: undefined
  }
}
/**
 * @file It contains vuex issue statuses module mutations to mutate the state variables.
 */
export default {
  setLoadingIssueStatuses: (state, loadingIssueStatuses) => {
    state.loadingIssueStatuses = loadingIssueStatuses
  },
  setIssueStatuses: (state, issueStatuses) => {
    state.issueStatuses = issueStatuses
  }
}
import Vue from "vue"

Vue.filter("initials", function(value) {
  if(value) {
    const nameParts = value.trim().split(" ")
    let initials
    if(nameParts.length === 1){
      initials = nameParts[0].charAt(0, 1).toUpperCase()
    } else if (nameParts.length > 1) {
      initials = nameParts[0].charAt(0).toUpperCase() +
      nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    }
    return initials
  }
})

Vue.filter("required", function(value) {
  if(value) {
    return `${value}*`
  }
})

Vue.filter("colon", function(value) {
  if(value) {
    return `${value}: `
  }
})

Vue.filter("question", function(value) {
  if(value) {
    return `${value}?`
  }
})
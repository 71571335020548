/**
 * @file It contains vuex exceptions module mutations to mutate the state variables.
 */
export default {
  setLoadingExceptions: (state, loadingExceptions) => {
    state.loadingExceptions = loadingExceptions
  },
  setExceptions: (state, exceptions) => {
    state.exceptions = exceptions
  },
  updateExceptions: (state, exceptions) => {
    for (const exception of exceptions) {
      const index = state.exceptions.findIndex(stateException =>
        stateException?.id === exception.id)
      if (index >= 0) {
        state.exceptions.splice(index, 1, { ...state.exceptions[index], ...exception })
      } else {
        state.exceptions.push(exception)
      }
    }
  },
  setUpdatingException: (state, properties) => {
    for (const property of properties) {
      state.updatingException[property] = true
    }
  },
  resetUpdatingException: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingException[property] = false
      }
    } else {
      Object.keys(state.updatingException).forEach(property => state.updatingException[property] = false)
    }
  },
  setExceptionUpdated: (state, properties) => {
    for (const property of properties) {
      state.exceptionUpdated[property] = true
      setTimeout(() => {
        state.exceptionUpdated[property] = false
      }, 5000)
    }
  },
  resetExceptionUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.exceptionUpdated[property] = false
      }
    } else {
      Object.keys(state.exceptionUpdated).forEach(property => state.exceptionUpdated[property] = false)
    }
  }
}
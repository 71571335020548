import { GET_HTTP_CLIENT } from "@/api"
import {
  HTTP_STATUS_CODE,
  TRANSLATION_UPLOAD_STATUS,
  DATA_RETENTION_OPTIONS,
  DATA_RETENTION_RULES
} from "@/constants"
import { downloadFile, generateMD5ForFile } from "@/utils"
import CONSTANTS from "@/constants"

const baseURL = (version = "v2") => `${process.env.VUE_APP_THEMIS_API_BASE_URL}/${version}/fields`

export default {
  /**
   * This action will load fields v2.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load fields.
   */
  async loadFieldsV2(context, payload) {
    if (payload?.uploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED) {
      delete payload.uploadStatus
      context.commit("setLoadingFieldsV2", false)
    } else {
      context.commit("setLoadingFieldsV2", true)
    }

    const httpClient = GET_HTTP_CLIENT({
      baseURL: baseURL()
    }, context)

    const getFieldsV2Response = await httpClient.get(undefined, {
      params: payload
    })

    if (getFieldsV2Response.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateFieldsV2", getFieldsV2Response.data)
      } else {
        context.commit("setFieldsV2", getFieldsV2Response.data)
      }
    }

    context.commit("setLoadingFieldsV2", false)
  },

  /**
   * This action will add a new fieldV2.
   * @param {*} context is the store
   * @param {*} payload contains fieldV2 details
   */
  async addFieldV2(context, payload) {
    context.commit("setAddingFieldV2", true)
    context.commit("setFieldV2Added", false)

    const fieldPayload = { ...payload }
    delete fieldPayload.dataRetentionValue

    const httpClient = GET_HTTP_CLIENT({
      baseURL: baseURL()
    }, context)

    const postFieldV2Response = await httpClient.post(undefined, fieldPayload)

    if (postFieldV2Response.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFieldsV2", [postFieldV2Response.data])
      const loadDataRetentionRulesPromise = context.dispatch("dataRetentionRules/loadDataRetentionRules",  undefined, { root: true })

      if(!([CONSTANTS.NULL, DATA_RETENTION_OPTIONS[0]].includes(payload.dataRetentionValue))) {
        await loadDataRetentionRulesPromise
        const dataRetentionRules           = context.rootGetters["dataRetentionRules/dataRetentionRules"]
        const dataRetentionRuleToBeUpdated = dataRetentionRules.find(dataRetentionRule =>
          dataRetentionRule.fieldId === postFieldV2Response.data.id &&
          dataRetentionRule.key === DATA_RETENTION_RULES.FIELD
        )

        if (dataRetentionRuleToBeUpdated) {
          await context.dispatch("dataRetentionRules/updateDataRetentionRule", {
            id: dataRetentionRuleToBeUpdated.id, value: payload.dataRetentionValue
          }, { root: true })
        }
      }

      context.commit("setFieldV2Added", true)
    } else if (postFieldV2Response.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setFieldV2AddError", postFieldV2Response.data)
    }

    context.commit("setAddingFieldV2", false)
  },

  /**
   * This action updates the field.
   * @param {*} context is the store.
   * @param {*} field id and name of a field.
   */
  async updateField(context, field) {
    const id = field.id
    delete field.id
    const propertiesToBeUpdated = Object.keys(field)
    for (const property of propertiesToBeUpdated) {
      if (field[property] === undefined) {
        field[property] = null
      }
    }
    context.commit("setUpdatingField", propertiesToBeUpdated)
    context.commit("resetFieldUpdated", propertiesToBeUpdated)
    context.commit("resetFieldUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: baseURL()
    }, context)

    const updateFieldResponse = await httpClient.put(`/${id}`, field)

    if (updateFieldResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateFieldsV2", [{
        id,
        ...field
      }])
      context.commit("setFieldUpdated", propertiesToBeUpdated)
    } else if (updateFieldResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setFieldUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateFieldResponse.data
      })
    }

    context.commit("resetUpdatingField", propertiesToBeUpdated)
  },

  /**
   * This action downloads file associated with field translations.
   * @param {*} context is the store.
   * @param {*} payload contains id and name of the field.
   */
  async downloadFieldTranslationFile(context, payload) {
    context.commit("setDownloadingFieldTranslation", true)

    const { id, name } = payload
    const httpClient   = GET_HTTP_CLIENT({
      baseURL: baseURL("v1")
    }, context)

    const getTranslationDownloadURLResponse = await httpClient.get(`/${id}/translations-download-url`)
    if (getTranslationDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      const { url, md5 } = getTranslationDownloadURLResponse.data
      await downloadFile(url, md5, `${name}.xlsx`)
    }

    context.commit("setDownloadingFieldTranslation", false)
  },

  /**
   * This action uploads translation file to S3 bucket.
   * @param {*} context is the store.
   * @param {*} payload contains field and file to be uploaded.
   */
  async uploadFieldTranslationFile(context, payload) {
    context.commit("setUploadingFieldTranslation", true)
    context.commit("setTranslationFileUploaded", false)

    const { field, file } = payload
    const httpClient      = GET_HTTP_CLIENT({
      baseURL: baseURL("v1")
    }, context)

    const httpClientV2 = GET_HTTP_CLIENT({
      baseURL: baseURL("v2")
    }, context)

    const md5 = await generateMD5ForFile(file)

    const uploadTranslationFileUrlResponse = await httpClient.post(`/${field.id}/translations-upload-url`, {
      md5
    })

    if(uploadTranslationFileUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(uploadTranslationFileUrlResponse.data.fields).forEach(key =>
        form.append(key, uploadTranslationFileUrlResponse.data.fields[key]))
      form.append("file", file)

      const headers = {
        "Content-MD5": Buffer.from(md5, "hex").toString("base64")
      }

      const uploadFileToS3Response = await fetch(uploadTranslationFileUrlResponse.data.url, {
        headers,
        method: "POST",
        body  : form
      })

      if(uploadFileToS3Response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        context.commit("updateFieldsV2", [{
          ...field,
          ...{ "translationUploadStatus": TRANSLATION_UPLOAD_STATUS.INITIATED }
        }])

        await httpClientV2.put(`/${field.id}`, {
          translationUploadStatus: TRANSLATION_UPLOAD_STATUS.INITIATED
        })
      }
    }

    context.commit("setUploadingFieldTranslation", false)
    context.commit("setTranslationFileUploaded", true)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingFieldsV2", undefined)
    context.commit("setFieldsV2", new Array())
    context.commit("setAddingFieldV2", undefined)
    context.commit("setFieldV2AddError", undefined)
    context.commit("setFieldV2Added", undefined)
    context.commit("resetUpdatingField")
    context.commit("resetFieldUpdated")
    context.commit("resetFieldUpdateError")
    context.commit("setDownloadingFieldTranslation", false)
    context.commit("setUploadingFieldTranslation", false)
  }
}
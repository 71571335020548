
/**
 * @file It contains all the vuex group roles module state variables.
 */
export default {
  loadingAccesses: undefined,
  accesses       : new Array(),
  addingAccess   : undefined,
  accessAdded    : undefined,
  removingAccess : undefined,
  accessRemoved  : undefined,
  accessAddError : undefined
}
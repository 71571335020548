import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { USER_ADD } from "@/constants/bread-crumbs/user-add"

/**
 * This method will be called before navigating to user add page.
 */
export const beforeUserAdd = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.USER_ADD))
  store.commit("shared/setBreadcrumbs", USER_ADD)
  store.commit("users/setAddingUser", undefined)
  store.commit("users/setUserAdded", undefined)
  store.commit("users/setUserAddError", undefined)
  store.dispatch("groups/loadGroups")
}
export default {
  setLoadingIssueDocuments: (state, loadingIssueDocuments) => {
    state.loadingIssueDocuments = loadingIssueDocuments
  },
  setAddingIssueDocument: (state, addingIssueDocument) => {
    state.addingIssueDocument = addingIssueDocument
  },
  setRemovingIssueDocument: (state, removingIssueDocument) => {
    state.removingIssueDocument = removingIssueDocument
  },
  setIssueDocumentRemoved: (state, issueDocumentRemoved) => {
    state.issueDocumentRemoved = issueDocumentRemoved
  },
  setIssueDocuments: (state, issueDocuments) => {
    state.issueDocuments = issueDocuments
  },
  setAccessInformation: (state, { policy, policyAccessInformation }) => {
    const policyAccessInformationResourceMap = new Object()
    for (const resource of policyAccessInformation) {
      policyAccessInformationResourceMap[resource.id] = resource
    }

    if (state.accessInformation[policy]) {
      state.accessInformation[policy] = Object.assign(
        {},
        state.accessInformation[policy],
        policyAccessInformationResourceMap
      )
    } else {
      state.accessInformation = Object.assign(
        {},
        state.accessInformation, {
          [policy]: policyAccessInformationResourceMap
        }
      )
    }
  },
  resetAccessInformation: state => {
    state.accessInformation = new Object()
  },
  updateIssueDocuments: (state, issueDocuments) => {
    for (const issueDocument of issueDocuments) {
      const index = state.issueDocuments.findIndex(stateMessageItem => stateMessageItem?.id === issueDocument.id)
      if (index >= 0) {
        state.issueDocuments.splice(index, 1, { ...state.issueDocuments[index], ...issueDocument })
      } else {
        state.issueDocuments.push(issueDocument)
      }
    }
  },
  removeIssueDocument: (state, documentToBeRemoved) => {
    const indexOfDocumentToBeRemoved = state.issueDocuments.findIndex(issueDocument =>
      issueDocument.id === documentToBeRemoved.id
    )
    state.issueDocuments.splice(indexOfDocumentToBeRemoved, 1)
  }
}
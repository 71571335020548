export default {
  isLoadingOptionLists                : state => state.loadingOptionLists,
  optionLists                         : state => state.optionLists,
  isAddingOptionList                  : state => state.addingOptionList,
  isOptionListAdded                   : state => state.optionListAdded,
  optionListAddError                  : state => state.optionListAddError,
  isUpdatingOptionListName            : state => state.updatingOptionList.name,
  isOptionListNameUpdated             : state => state.optionListUpdated.name,
  optionListNameUpdateError           : state => state.optionListUpdateError.name,
  isUpdatingMachineTranslateOptionList: state => state.updatingOptionList.machineTranslateItems,
  isMachineTranslateOptionListUpdated : state => state.optionListUpdated.machineTranslateItems,
  isUploadingOptionListItemTranslation: state => state.uploadingOptionListItemTranslation,
  optionListsExport                   : state => state.optionListsExport,
  isAddingOptionListExport            : state => state.addingOptionListExport
}

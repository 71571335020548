export const ISSUE_FORM = (route, name) => ([
  {
    text          : "1204",
    exact         : true,
    to            : "/issue-forms",
    useTranslation: true
  },
  {
    text          : name,
    exact         : true,
    to            : `/issue-forms/${route.params.id}`,
    useTranslation: false
  }
])
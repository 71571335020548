/**
 * @file It contains vuex groups module mutations to mutate the state variables.
 */
export default {
  setAddingIntegration: (state, addingIntegration) => {
    state.addingIntegration = addingIntegration
  },
  setIntegrationAdded: (state, integrationAdded) => {
    state.integrationAdded = integrationAdded
  },
  setIntegrationAddError: (state, error) => {
    state.integrationAddError = error
  },
  setIntegrations: (state, integrations) => {
    state.integrations = integrations
  },
  updateIntegration: (state, integrations) => {
    for (const integration of integrations) {
      const index = state.integrations.findIndex(stateIntegrations => stateIntegrations?.id === integration.id)
      if (index >= 0) {
        state.integrations.splice(index, 1, { ...state.integrations[index], ...integration })
      } else {
        state.integrations.push(integration)
      }
    }
  },
  setRemovingIntegration: (state, removingIntegration) => {
    state.removingIntegration = removingIntegration
  },
  setIntegrationRemoved: (state, integrationRemoved) => {
    state.integrationRemoved = integrationRemoved
  },
  removeIntegration: (state, id) => {
    const index = state.integrations.findIndex(stateIntegrations => stateIntegrations?.id === id)
    state.integrations.splice(index, 1)
  }
}
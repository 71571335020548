/**
 * @file It contains vuex domains module mutations to mutate the state variables.
 */
export default {
  setLoadingDomains: (state, loadingDomains) => {
    state.loadingDomains = loadingDomains
  },
  setAddingDomain: (state, addingDomain) => {
    state.addingDomain = addingDomain
  },
  setDomainAdded: (state, domainAdded) => {
    state.domainAdded = domainAdded
  },
  setDomains: (state, domains) => {
    state.domains = domains
  },
  setDomainAddError: (state, domainAddError) => {
    state.domainAddError = domainAddError
  },
  updateDomains: (state, domains) => {
    for (const domain of domains) {
      const index = state.domains.findIndex(stateDomain => stateDomain?.id === domain.id)
      if (index >= 0) {
        state.domains.splice(index, 1, { ...state.domains[index], ...domain })
      } else {
        state.domains.push(domain)
      }
    }
  },
  setUpdatingDomain: (state, properties) => {
    for (const property of properties) {
      state.updatingDomain[property] = true
    }
  },
  resetUpdatingDomain: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingDomain[property] = false
      }
    } else {
      Object.keys(state.updatingDomain).forEach(property => state.updatingDomain[property] = false)
    }
  },
  setDomainUpdated: (state, properties) => {
    for (const property of properties) {
      state.domainUpdated[property] = true
      setTimeout(() => {
        state.domainUpdated[property] = false
      }, 5000)
    }
  },
  resetDomainUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.domainUpdated[property] = false
      }
    } else {
      Object.keys(state.domainUpdated).forEach(property => state.domainUpdated[property] = false)
    }
  },
  setDomainUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.domainUpdateError[property] = error
    }
  },
  resetDomainUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.domainUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.domainUpdateError).forEach(property => state.domainUpdateError[property] = undefined)
    }
  }
}
import store from "@/plugins/vuex"
import { BOOLEAN } from "@/constants"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to issue forms page.
 */
export const beforeIssueForms = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUE_FORMS))
  store.commit("shared/setBreadcrumbs", undefined)
  store.dispatch("formTemplates/loadFormTemplates", {
    reportForm: BOOLEAN.FALSE
  })
}
export default {
  setLoadingIssueFieldValues: (state, loadingIssueFieldValues) => {
    state.loadingIssueFieldValues = loadingIssueFieldValues
  },
  setIssueFieldValues: (state, issueFieldValues) => {
    state.issueFieldValues =  issueFieldValues
  },
  updateIssueFieldValues: (state, issueFieldValues) => {
    state.issueFieldValues = state.issueFieldValues.filter(stateIssueFieldValue =>
      !issueFieldValues.find(issueFieldValue =>
        issueFieldValue.issueFieldId === stateIssueFieldValue.issueFieldId &&
        stateIssueFieldValue.issueId === issueFieldValue.issueId
      )
    )
    state.issueFieldValues.push(...issueFieldValues)
  },
  setUpdatingIssueFieldValue: (state, updatingIssueFieldValue) => {
    state.updatingIssueFieldValue = updatingIssueFieldValue
  },
  setIssueFieldValueUpdated: (state, issueFieldValueUpdated) => {
    state.issueFieldValueUpdated = issueFieldValueUpdated
  }
}
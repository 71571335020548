/**
 * @file It contains all the vuex exceptions module state variables.
 */
export default {
  loadingExceptions: undefined,
  exceptions       : new Array(),
  updatingException: {
    status: false
  },
  exceptionUpdated: {
    status: false
  }
}
export const GROUP = route => ([
  {
    text          : "136",
    exact         : true,
    to            : "/groups",
    useTranslation: true
  },
  {
    text          : route.params.id,
    to            : `/groups/${route.params.id}`,
    useTranslation: false
  }
])
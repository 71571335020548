/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/reports`

export default {
  /**
   * This action will load a particular report.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadReports(context, payload) {
    context.commit("setLoadingReports", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getReportsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getReportsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateReports", getReportsResponse.data)
      } else {
        context.commit("setReports", getReportsResponse.data)
      }
    } else {
      // something went wrong while getting reports
    }

    context.commit("setLoadingReports", false)
  },

  /**
   * This action updates summary of an report.
   * @param {*} context is the store.
   * @param {*} payload id and summary of an report.
   */
  async updateReport(context, report) {
    const id = report.id
    delete report.id
    const propertiesToBeUpdated = Object.keys(report)
    for (const property of propertiesToBeUpdated) {
      if (report[property] === undefined) {
        report[property] = null
      }
    }
    context.commit("setUpdatingReport", propertiesToBeUpdated)
    context.commit("resetReportUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const updateReportResponse = await httpClient.put(`/${id}`, report)

    if (updateReportResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateReports", [{
        id,
        ...report
      }])
      context.commit("setReportUpdated", propertiesToBeUpdated)
    } else {
      // something went wrong while updating an report
    }

    context.commit("resetUpdatingReport", propertiesToBeUpdated)
  },

  /**
   * This action will load a particular reportIntakeValue.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadReportFormValues(context, { reportId, formId }) {
    context.commit("setLoadingReportFormValues", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getReportFormValuesResponse = await httpClient.get(`/${reportId}/forms/${formId}/values`)

    if (getReportFormValuesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateReportFormValues", getReportFormValuesResponse.data)
    } else {
      // something went wrong while getting reportFormValues
    }

    context.commit("setLoadingReportFormValues", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setReports", new Array())
    context.commit("setLoadingReports", undefined)
    context.commit("resetUpdatingReport")
    context.commit("resetReportUpdated")
    context.commit("setReportFormValues", new Array())
    context.commit("setLoadingReportFormValues", undefined)
  }
}
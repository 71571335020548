/**
 * @file It contains vuex users module getter methods to access store variables.
 */
export default {
  isLoadingUsers                     : state => state.loadingUsers,
  users                              : state => state.users.filter(user => !user.deletedAt),
  usersIncludingDeletedUsers         : state => state.users,
  groupsOfUsers                      : state => state.groupsOfUsers,
  isAddingGroups                     : state => state.addingGroups,
  isUserAdded                        : state => state.userAdded,
  isAddingUser                       : state => state.addingUser,
  isUpdatingName                     : state => state.updatingUser.name,
  isNameUpdated                      : state => state.userUpdated.name,
  isUpdatingEmail                    : state => state.updatingUser.email,
  isEmailUpdated                     : state => state.userUpdated.email,
  isUpdatingJobTitle                 : state => state.updatingUser.jobTitle,
  isJobTitleUpdated                  : state => state.userUpdated.jobTitle,
  isUpdatingPhone                    : state => state.updatingUser.phone,
  isPhoneUpdated                     : state => state.userUpdated.phone,
  isUpdatingEnabled                  : state => state.updatingUser.enabled,
  isUpdatingIssuesColumnsPreference  : state => state.updatingUser.issuesColumnsPreference,
  isIssuesColumnsPreferenceUpdated   : state => state.userUpdated.issuesColumnsPreference,
  isEnabledUpdated                   : state => state.userUpdated.enabled,
  userAddError                       : state => state.userAddError,
  emailUpdateError                   : state => state.userUpdateError.email,
  isSendingPassword                  : state => state.sendingPassword,
  isPasswordSent                     : state => state.passwordSent,
  isDeletingUser                     : state => state.deletingUser,
  isUserDeleted                      : state => state.userDeleted,
  isDisablingMFA                     : state => state.updatingUser.disableMFA,
  isMFADisabled                      : state => state.userUpdated.disableMFA,
  isUpdatingGroups                   : state => state.updatingGroups,
  isGroupsUpdated                    : state => state.groupsUpdated,
  loggedInUserIssuesColumnsPreference: (state, getters, rootState) => {
    const loggedInUser = state.users.find(user =>
      user.id === rootState.auth.loggedInUser.id
    )
    if (loggedInUser) {
      return loggedInUser.issuesColumnsPreference
    }
    return []
  },
  loggedInUserIssuesRowsPerPagePreference: (state, getters, rootState) => {
    const loggedInUser = state.users.find(user =>
      user.id === rootState.auth.loggedInUser.id
    )
    if (loggedInUser) {
      return loggedInUser.issuesRowsPerPagePreference
    }
    return 50
  },
  loggedInUserIssuesFiltersPreference: (state, getters, rootState) => {
    const loggedInUser = state.users.find(user =>
      user.id === rootState.auth.loggedInUser.id
    )
    if (loggedInUser) {
      return loggedInUser.issuesFiltersPreference
    }
    return null
  }
}
export default {
  loadingOptionLists                : undefined,
  optionLists                       : new Array(),
  addingOptionList                  : undefined,
  optionListAdded                   : undefined,
  optionListAddError                : undefined,
  optionListsExport                 : new Array(),
  addingOptionListExport            : false,
  uploadingOptionListItemTranslation: false,
  optionListItemTranslationUploaded : false,
  updatingOptionList                : {
    name                 : false,
    machineTranslateItems: false
  },
  optionListUpdated: {
    name                 : false,
    machineTranslateItems: false
  },
  optionListUpdateError: {
    name: undefined
  }
}

export const REPORTER_INTAKE_FORM = (route, name) => ([
  {
    text          : "736",
    exact         : true,
    to            : "/reporter-intake-forms",
    useTranslation: true
  },
  {
    text          : name,
    exact         : true,
    to            : `/reporter-intake-forms/${route.params.id}`,
    useTranslation: false
  }
])
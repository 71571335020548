import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to option lists page.
 */
export const beforeOptionLists = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.OPTION_LISTS))
  store.commit("shared/setBreadcrumbs", undefined)
  store.dispatch("optionLists/loadOptionLists")
  store.dispatch("optionListItems/loadOptionListItems")
}
import { defaultTableHeaderClasses } from "@/constants"

export const FORM_TEMPLATE_FIELDS = {
  headers: [{
    value: "sortingOrder",
    class: defaultTableHeaderClasses,
    width: "50"
  },
  {
    text : "807",
    align: "left",
    value: "systemName",
    class: defaultTableHeaderClasses
  },
  {
    text : "808",
    align: "left",
    value: "fieldType",
    class: defaultTableHeaderClasses
  },
  {
    align: "left",
    value: "actions",
    class: defaultTableHeaderClasses
  }]
}
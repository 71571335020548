import { defaultTableHeaderClasses } from "@/constants"

export const ISSUE_FORM_TEMPLATE_ADD_FIELDS = {
  headers: [{
    text : "985",
    align: "left",
    value: "systemName",
    class: defaultTableHeaderClasses
  },
  {
    text : "986",
    align: "left",
    value: "fieldType",
    class: defaultTableHeaderClasses
  }]
}
export default {
  setLoadingFormTemplates: (state, loadingFormTemplates) => {
    state.loadingFormTemplates = loadingFormTemplates
  },
  setFormTemplates: (state, formTemplates) => {
    state.formTemplates = formTemplates
  },
  setAddingFormTemplate: (state, addingFormTemplate) => {
    state.addingFormTemplate = addingFormTemplate
  },
  setFormTemplateAdded: (state, formTemplateAdded) => {
    state.formTemplateAdded = formTemplateAdded
  },
  setFormTemplateAddError: (state, formTemplateAddError) => {
    state.formTemplateAddError = formTemplateAddError
  },
  updateFormTemplates: (state, formTemplates) => {
    for (const formTemplate of formTemplates) {
      const index = state.formTemplates.findIndex(stateFormTemplate => stateFormTemplate?.id === formTemplate.id)
      if (index >= 0) {
        state.formTemplates.splice(index, 1, { ...state.formTemplates[index], ...formTemplate })
      } else {
        state.formTemplates.push(formTemplate)
      }
    }
  },
  setUpdatingFormTemplate: (state, properties) => {
    for (const property of properties) {
      state.updatingFormTemplate[property] = true
    }
  },
  resetUpdatingFormTemplate: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingFormTemplate[property] = false
      }
    } else {
      Object.keys(state.updatingFormTemplate).forEach(property => state.updatingFormTemplate[property] = false)
    }
  },
  setFormTemplateUpdated: (state, properties) => {
    for (const property of properties) {
      state.formTemplateUpdated[property] = true
      setTimeout(() => {
        state.formTemplateUpdated[property] = false
      }, 5000)
    }
  },
  resetFormTemplateUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.formTemplateUpdated[property] = false
      }
    } else {
      Object.keys(state.formTemplateUpdated).forEach(property => state.formTemplateUpdated[property] = false)
    }
  },
  setFormTemplateUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.formTemplateUpdateError[property] = error
    }
  },
  resetFormTemplateUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.formTemplateUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.formTemplateUpdateError).forEach(property =>
        state.formTemplateUpdateError[property] = undefined)
    }
  }

}
/**
 * @file It contains vuex messages module mutations to mutate the state variables.
 */
export default {
  setLoadingMessages: (state, messages) => {
    state.loadingMessages = messages
  },
  setAddingMessage: (state, addingMessage) => {
    state.addingMessage = addingMessage
  },
  setMessageAdded: (state, messageAdded) => {
    state.messageAdded = messageAdded
  },
  setRemovingMessage: (state, removingMessage) => {
    state.removingMessage = removingMessage
  },
  setMessageRemoved: (state, messageRemoved) => {
    state.messageRemoved = messageRemoved
  },
  removeMessages: (state, messages) => {
    for (const message of messages) {
      const index = state.messages.findIndex(stateMessage => stateMessage?.id === message.id)
      if (index >= 0) {
        state.messages.splice(index, 1)
      }
    }
  },
  setMessages: (state, messages) => {
    state.messages = messages
  },
  updateMessages: (state, messages) => {
    for (const message of messages) {
      const index = state.messages.findIndex(stateMessage => stateMessage?.id === message.id)
      if (index >= 0) {
        state.messages.splice(index, 1, { ...state.messages[index], ...message })
      } else {
        state.messages.push(message)
      }
    }
  },
  setUpdatingMessage: (state, properties) => {
    for (const property of properties) {
      state.updatingMessage[property] = true
    }
  },
  resetUpdatingMessage: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingMessage[property] = false
      }
    } else {
      Object.keys(state.updatingMessage).forEach(property => state.updatingMessage[property] = false)
    }
  },
  setMessageUpdated: (state, properties) => {
    for (const property of properties) {
      state.messageUpdated[property] = true
      setTimeout(() => {
        state.messageUpdated[property] = false
      }, 5000)
    }
  },
  resetMessageUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.messageUpdated[property] = false
      }
    } else {
      Object.keys(state.messageUpdated).forEach(property => state.messageUpdated[property] = false)
    }
  },
  setMessageUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.messageUpdateError[property] = error
    }
  },
  resetMessageUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.messageUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.messageUpdateError).forEach(property => state.messageUpdateError[property] = undefined)
    }
  },
  setMessageAddError: (state, messageAddError) => {
    state.messageAddError = messageAddError
  }
}
/**
 * @file It contains vuex groups module getter methods to access store variables.
 */
export default {
  isLoadingGroups       : state => state.loadingGroups,
  groups                : state => state.groups,
  groupsUsers           : state => state.groupsUsers,
  isAddingGroup         : state => state.addingGroup,
  isGroupAdded          : state => state.groupAdded,
  isRemovingGroup       : state => state.removingGroup,
  isGroupRemoved        : state => state.groupRemoved,
  groupAddError         : state => state.groupAddError,
  isUpdatingName        : state => state.updatingGroup.name,
  isNameUpdated         : state => state.groupUpdated.name,
  nameUpdateError       : state => state.groupUpdateError.name,
  isUpdatingDescription : state => state.updatingGroup.description,
  isDescriptionUpdated  : state => state.groupUpdated.description,
  descriptionUpdateError: state => state.groupUpdateError.description,
  isAddingUsers         : state => state.addingUsers,
  isRemovingUser        : state => state.removingUser
}
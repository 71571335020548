import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"

export const DOMAIN_ACCESSES =  {
  headers: [{
    text : "205",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  },
  {
    text : "206",
    align: "left",
    value: "roles",
    class: defaultTableHeaderClasses
  }],
  loadingText : "207",
  noDataText  : "208",
  noResultText: "209",
  itemsPerPage: 10,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "210"
  }
}
import { COGNITO_USER_POOL_DATA, MFA, COOKIE_OPTIONS, COGNITO_AUTHENTICATION_MESSAGE } from "@/constants"
import { CognitoUserPool, CognitoUser, CookieStorage, AuthenticationDetails } from "amazon-cognito-identity-js"

export const getCurrentUser = (userPoolId, userPoolClientId) => {
  const cognitoUserPool = new CognitoUserPool(COGNITO_USER_POOL_DATA(userPoolId, userPoolClientId))
  return cognitoUserPool.getCurrentUser()
}

export const getSession = async (userPoolId, userPoolClientId) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)
  if (user) {
    const getSessionPromise = new Promise(resolve => {
      user.getSession((error, session) => {
        if (error) {
          resolve(false)
        } else {
          resolve(session)
        }
      })
    })
    return await getSessionPromise
  }
  return false
}

export const authenticateUser = async (userPoolId, userPoolClientId, email, password, newPassword, totp) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password
  })
  const poolData              = new CognitoUserPool(COGNITO_USER_POOL_DATA(userPoolId, userPoolClientId))
  const user                  = new CognitoUser({
    Username: email,
    Pool    : poolData,
    Storage : new CookieStorage(COOKIE_OPTIONS)
  })

  const authenticateUserPromise = new Promise(resolve => {
    user.authenticateUser(authenticationDetails, {
      onSuccess          : result => resolve(result),
      newPasswordRequired: async () => {
        if (newPassword) {
          resolve(await completeNewPasswordChallenge(user, newPassword))
        } else {
          resolve(COGNITO_AUTHENTICATION_MESSAGE.NEW_PASSWORD_REQUIRED)
        }
      },
      totpRequired: async result => {
        if (totp) {
          resolve(await sendMFACode(user, totp))
        } else {
          resolve(result)
        }
      },
      onFailure: result => resolve(result)
    })
  })
  return await authenticateUserPromise
}

export const sendMFACode = async (user, code) => {
  if (user) {
    const sendMFACodePromise = new Promise(resolve => {
      user.sendMFACode(code, {
        onSuccess: result => resolve(result),
        onFailure: () => resolve(false)
      }, MFA.TOTP_PREFERRED)
    })

    return await sendMFACodePromise
  }
  return false
}

export const completeNewPasswordChallenge = async (user, password) => {
  if (user) {
    const completeNewPasswordChallengePromise = new Promise(resolve => {
      user.completeNewPasswordChallenge(password, {
        email: user.email
      }, {
        onSuccess: result => resolve(result),
        onFailure: () => resolve(false)
      })
    })

    return await completeNewPasswordChallengePromise
  }
  return false
}

export const forgotPassword = async (userPoolId, userPoolClientId, email) => {
  const userPool = new CognitoUserPool(COGNITO_USER_POOL_DATA(userPoolId, userPoolClientId))

  const user = new CognitoUser({
    Username: email,
    Pool    : userPool,
    Storage : new CookieStorage(COOKIE_OPTIONS)
  })

  if (user) {
    const forgotPasswordPromise = new Promise(resolve => {
      user.forgotPassword({
        onSuccess: result => resolve(result),
        onFailure: () => resolve(false)
      })
    })

    const result = await forgotPasswordPromise
    user.signOut()
    return result

  }
  return false
}

export const confirmPassword = async (userPoolId, userPoolClientId, username, code, password) => {
  const userPool = new CognitoUserPool(COGNITO_USER_POOL_DATA(userPoolId, userPoolClientId))

  const user = new CognitoUser({
    Username: username,
    Pool    : userPool,
    Storage : new CookieStorage(COOKIE_OPTIONS)
  })

  if (user) {
    const confirmPasswordPasswordPromise = new Promise(resolve => {
      user.confirmPassword(code, password, {
        onSuccess: () => resolve(true),
        onFailure: () => resolve(false)
      })
    })

    return await confirmPasswordPasswordPromise
  }
  return false
}

export const getUserData = async (userPoolId, userPoolClientId) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))

  if (user) {
    const getUserDataPromise = new Promise(resolve => {
      user.getUserData((error, result) => {
        if (error) {
          resolve(false)
        } else {
          resolve(result)
        }
      })
    })

    return await getUserDataPromise
  }
  return false
}

export const setUserMfaPreference = async (userPoolId, userPoolClientId, preference) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))

  if (user) {
    const setUserMfaPreferencePromise = new Promise(resolve => {
      user.setUserMfaPreference(undefined, {
        PreferredMfa: preference,
        Enabled     : preference
      }, (error, result) => {
        if (error) {
          resolve(false)
        } else {
          resolve(result)
        }
      })
    })

    return await setUserMfaPreferencePromise
  }
  return false
}

export const changePassword = async (userPoolId, userPoolClientId, currentPassword, newPassword) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))

  if (user) {
    const changePasswordPromise = new Promise(resolve => {
      user.changePassword(currentPassword, newPassword, (error, result) => {
        if (error) {
          resolve(error)
        } else {
          resolve(result)
        }
      })
    })

    return await changePasswordPromise
  }
  return false
}

export const verifySoftwareToken = async (userPoolId, userPoolClientId, totpCode) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))

  if (user) {
    const verifySoftwareTokenPromise = new Promise(resolve => {
      user.verifySoftwareToken(totpCode, undefined, {
        onSuccess: result => resolve(result),
        onFailure: () => resolve(false)
      })
    })

    return await verifySoftwareTokenPromise
  }
  return false
}

export const associateSoftwareToken = async (userPoolId, userPoolClientId) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))

  if (user) {
    const associateSoftwareTokenPromise = new Promise(resolve => {
      user.associateSoftwareToken({
        associateSecretCode: result => resolve(result),
        onFailure          : () => resolve(false)
      })
    })

    return await associateSoftwareTokenPromise
  }
  return false
}

export const logout = async (userPoolId, userPoolClientId) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  if (user) {
    const session = await getSession(userPoolId, userPoolClientId)
    if (session) {
      user.setSignInUserSession(session)
      const globalSignOutPromise = new Promise(resolve => {
        user.globalSignOut({
          onSuccess: result => resolve(result),
          onFailure: () => resolve(false)
        })
      })
      await globalSignOutPromise
    } else {
      user.signOut()
    }
  }
}

export default {
  authenticateUser,
  completeNewPasswordChallenge,
  sendMFACode,
  getSession,
  getCurrentUser,
  forgotPassword,
  confirmPassword,
  getUserData,
  logout,
  setUserMfaPreference,
  associateSoftwareToken,
  verifySoftwareToken,
  changePassword
}
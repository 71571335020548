export const ISSUE_ACCESSES = route => ([
  {
    text          : "246",
    exact         : true,
    to            : "/issues",
    useTranslation: true
  },
  {
    text          : route.params.id,
    exact         : true,
    to            : `/issues/${route.params.id}`,
    useTranslation: false
  },
  {
    text          : "287",
    to            : `/issues/${route.params.id}/accesses`,
    useTranslation: true
  }
])
/**
 * @file It contains vuex channels module getter methods to access store variables.
 */
export default {
  isLoadingChannels                  : state => state.loadingChannels,
  channels                           : state => state.channels,
  isChannelAdded                     : state => state.channelAdded,
  isAddingChannel                    : state => state.addingChannel,
  isUpdatingName                     : state => state.updatingChannel.name,
  isNameUpdated                      : state => state.channelUpdated.name,
  isUpdatingDisplayName              : state => state.updatingChannel.displayName,
  isDisplayNameUpdated               : state => state.channelUpdated.displayName,
  isUpdatingLink                     : state => state.updatingChannel.link,
  isLinkUpdated                      : state => state.channelUpdated.link,
  isUpdatingLinkDisplayName          : state => state.updatingChannel.linkDisplayName,
  isLinkDisplayNameUpdated           : state => state.channelUpdated.linkDisplayName,
  isUpdatingTranslationPreference    : state => state.updatingChannel.translationPreferenceId,
  isTranslationPreferenceUpdated     : state => state.channelUpdated.translationPreferenceId,
  isUpdatingOrganisationCode         : state => state.updatingChannel.organisationCode,
  isOrganisationCodeUpdated          : state => state.channelUpdated.organisationCode,
  channelAddError                    : state => state.channelAddError,
  nameUpdateError                    : state => state.channelUpdateError.name,
  displayNameUpdateError             : state => state.channelUpdateError.displayName,
  formsOfChannels                    : state => state.formsOfChannels,
  isAddingChannelForm                : state => state.addingChannelForm,
  isRemovingChannelForm              : state => state.removingChannelForm,
  isLoadingForms                     : state => state.loadingForms,
  isUpdatingEnabled                  : state => state.updatingChannel.enabled,
  isEnabledUpdated                   : state => state.channelUpdated.enabled,
  translationConfigurations          : state => state.translationConfigurations,
  isLoadingTranslationConfigurations : state => state.loadingTranslationConfigurations,
  isUpdatingTranslationConfigurations: state => state.updatingTranslationConfigurations,
  isTranslationConfigurationsUpdated : state => state.translationConfigurationsUpdated,
  isChannelLogoAdded                 : state => state.channelLogoAdded,
  isAddingChannelLogo                : state => state.addingChannelLogo,
  isChannelLogoRemoved               : state => state.channelLogoRemoved,
  isRemovingChannelLogo              : state => state.removingChannelLogo,
  isDownloadingChannelsOverview      : state => state.downloadingChannelsOverview,
  formTemplatesOfChannels            : state => state.formTemplatesOfChannels,
  isAddingChannelFormTemplates       : state => state.addingChannelFormTemplates,
  channelFormTemplatesAdded          : state => state.channelFormTemplatesAdded,
  isRemovingChannelFormTemplate      : state => state.removingChannelFormTemplate,
  isChannelFormTemplateRemoved       : state => state.channelFormTemplateRemoved
}
/**
 * @file It contains vuex translations module getter methods to access store variables.
 */
export default {
  isLoadingTranslations: state => state.loadingTranslations,
  translations         : state => state.translations,
  isTranslationAdded   : state => state.translationAdded,
  isAddingTranslation  : state => state.addingTranslation,
  isUpdatingStatus     : state => state.updatingTranslation.status,
  isStatusUpdated      : state => state.translationUpdated.status,
  isReasonUpdated      : state => state.updatingTranslation.reason,
  isUpdatingReason     : state => state.translationUpdated.reason,
  translationAddError  : state => state.translationAddError
}
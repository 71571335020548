export default {
  loadingIssueFields: undefined,
  issueFields       : new Array(),
  addingIssueFields : false,
  issueFieldsAdded  : false,
  updatingIssueField: {
    sortingOrder: false
  },
  issueFieldUpdated: {
    sortingOrder: false
  },
  removingIssueField: false,
  issueFieldRemoved : false
}
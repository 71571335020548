/**
 * @file It contains all the vuex issues module state variables.
 */
export default {
  loadingIssues: undefined,
  issues       : new Array(),
  updatingIssue: {
    summary          : false,
    description      : false,
    statusId         : false,
    domainId         : false,
    assigneeId       : false,
    labels           : false,
    receivedAt       : false,
    acknowledgedAt   : false,
    dataRetainedUntil: false
  },
  issueUpdated: {
    summary          : false,
    description      : false,
    statusId         : false,
    domainId         : false,
    assigneeId       : false,
    labels           : false,
    receivedAt       : false,
    acknowledgedAt   : false,
    dataRetainedUntil: false
  },
  logsOfIssues     : new Object(),
  commentsOfIssues : new Object(),
  loadingComments  : false,
  loadingLogs      : false,
  addingComment    : undefined,
  commentAdded     : undefined,
  removingComment  : undefined,
  commentRemoved   : undefined,
  accessInformation: new Object(),
  issueAdded       : undefined,
  addingIssue      : undefined
}
export const TASK = route => {
  return [
    {
      text          : "246",
      exact         : true,
      to            : "/issues",
      useTranslation: true
    },
    {
      text          : route.params.issueId,
      exact         : true,
      to            : `/issues/${route.params.issueId}`,
      useTranslation: false
    },
    {
      text          : route.params.id,
      to            : `/issues/${route.params.issueId}/${route.params.id}`,
      useTranslation: false
    }
  ]
}
import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to groups page..
 * @param {*} to route to be navigated to.
 * @param {*} from route from where navigation was triggered.
 */
export const beforeGroups = async (to, from) => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.GROUPS))
  store.commit("shared/setBreadcrumbs", undefined)
  store.commit("groups/resetGroupsUsers")
  if (to.query.dialog === "GroupAdd") {
    store.commit("groups/setGroupAdded", undefined)
    store.commit("groups/setAddingGroup", undefined)
    store.commit("groups/setGroupAddError", undefined)
  }
  if (to.query.dialog !== "GroupAdd" && from.query.dialog !== "GroupAdd") {
    await store.dispatch("groups/loadGroups")
    const groups = store.getters["groups/groups"]
    for (const group of groups) {
      store.dispatch("groups/loadUsers", group.id)
    }
  }
}
/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"

const baseURL = `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/logs`

export default {
  /**
   * This action will load logs.
   * @param {*} context is the store.
   */
  async loadLogs(context) {
    context.commit("setLoadingLogs", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL
    }, context)

    const getLogsResult = await httpClient.get()

    if (getLogsResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("setLogs", getLogsResult.data)
    } else {
      // something went wrong while getting logs
    }

    context.commit("setLoadingLogs", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingLogs", undefined)
    context.commit("setLogs", new Array())
  }
}
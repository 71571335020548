/**
 * @file It contains vuex supports module mutations to mutate the state variables.
 */
export default {
  setRequestingSupport: (state, requestingSupport) => {
    state.requestingSupport = requestingSupport
  },
  setSupportRequested: (state, supportRequested) => {
    state.supportRequested = supportRequested
  }
}
export default {
  setLoadingDataRetentionRules: (state, loadingDataRetentionRules) => {
    state.loadingDataRetentionRules = loadingDataRetentionRules
  },
  setUpdatingDataRetentionRule: (state, updatingDataRetentionRule) => {
    state.updatingDataRetentionRule = updatingDataRetentionRule
  },
  setDataRetentionRuleUpdated: (state, dataRetentionRuleUpdated) => {
    state.dataRetentionRuleUpdated = dataRetentionRuleUpdated
  },
  setDataRetentionRules: (state, dataRetentionRules) => {
    state.dataRetentionRules = dataRetentionRules
  },
  updateDataRetentionRules: (state, dataRetentionRules) => {
    for (const dataRetentionRule of dataRetentionRules) {
      const index = state.dataRetentionRules.findIndex(stateDataRetentionRule =>
        stateDataRetentionRule?.id === dataRetentionRule.id)
      if (index >= 0) {
        state.dataRetentionRules.splice(index, 1, { ...state.dataRetentionRules[index], ...dataRetentionRule })
      } else {
        state.dataRetentionRules.push(dataRetentionRule)
      }
    }
  }
}
export const OPTION_LIST = (route, name) => ([
  {
    text          : "730",
    exact         : true,
    to            : "/option-lists",
    useTranslation: true
  },
  {
    text          : name,
    to            : `/option-lists/${route.params.id}`,
    useTranslation: false
  }
]
)
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import POLICY_MUTATION_MAP from "@/constants/policy-mutation-map"

export default {
  async loadLoggedInUserAccess(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL: `${process.env.VUE_APP_THEMIS_API_BASE_URL}/v1/can-i`
    }, context)

    if (payload) {
      const postCanIResponse = await httpClient.post(undefined, payload)
      if (postCanIResponse.status === HTTP_STATUS_CODE.OK) {
        for (const access of postCanIResponse.data) {
          for (const [key, value] of Object.entries(access)) {
            context.commit(POLICY_MUTATION_MAP[key], value)
          }
        }
      }
    } else {
      const postCanIResponse = await httpClient.post(undefined, [])
      if (postCanIResponse.status === HTTP_STATUS_CODE.OK) {
        context.commit("setLoggedInUserPolicies", postCanIResponse.data)
      }
    }
  },

  reset(context) {
    context.commit("setLoggedInUserPolicies", new Object())
    context.commit("resetAllPolicies")
  }
}
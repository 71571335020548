/**
 * @file It contains vuex logs module mutations to mutate the state variables.
 */
export default {
  setLoadingLogs: (state, loadingLogs) => {
    state.loadingLogs = loadingLogs
  },
  setLogs: (state, logs) => {
    state.logs = logs
  }
}
import { defaultTableHeaderClasses, defaultTableFooterOptions } from "@/constants"

export const OPTION_LISTS = {
  headers: [{
    text : "733",
    align: "left",
    value: "id",
    class: defaultTableHeaderClasses
  },
  {
    text : "734",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  },
  {
    text : "735",
    align: "left",
    value: "optionListItems",
    width: "400",
    class: defaultTableHeaderClasses
  },
  {
    value   : "actions",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  itemsPerPage: 10,
  footer      : defaultTableFooterOptions
}
import store from "@/plugins/vuex"

/**
 * This method will be called before navigating to login page.
 */
export const beforeLogin = async (to, from) => {

  if (from.name === "logout"){
    await store.dispatch("configurations/loadConfigurations")
  }

  const isDefaultSsoConfigured = store.getters["configurations/isDefaultSsoConfigured"]
  const isDefaultSsoEnabled    = store.getters["configurations/isDefaultSsoEnabled"]
  const isRedirectFromIDP      = to.query.state

  if (store.getters["auth/isLoggedIn"]) {
    if (isRedirectFromIDP && isDefaultSsoConfigured && !isDefaultSsoEnabled) {
      return {
        name : "test-sso",
        query: to.query

      }
    } else {
      return {
        name: "dashboard"
      }
    }
  } else if (isRedirectFromIDP && to.query.code && store.getters["auth/verificationCode"]) {
    store.dispatch("auth/continueLoginUsingIDP", {
      code            : to.query.code,
      verificationCode: to.query.state
    })
  } else {
    store.dispatch("auth/reset")
  }
  store.commit("auth/setTotpRequired", false)
  store.commit("auth/setInvalidTotp", false)
  store.commit("auth/setNewPasswordRequired", false)
  store.commit("auth/setLoggingIn", false)
  store.commit("auth/setLoggedIn", false)
}